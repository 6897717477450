import React from "react";
import { User } from "../../../../../../../models/Entities";
import { UserAvatar } from "../../../../../../shared/MergeAvatars";
import { Text } from "@merge-api/merge-javascript-shared";

type NameEmailCellProps = {
  user: User;
};

const NameEmailCell = ({ user }: NameEmailCellProps) => {
  return (
    <td>
      <div className="flex flex-row items-center">
        <UserAvatar user={user} className={!user.is_active ? "opacity-50" : ""} />
        <div className={`flex flex-col ml-4 ${user.is_active ? "" : "text-gray-60"}`}>
          <Text variant="h6">{user.name}</Text>
          <Text>{user.email}</Text>
        </div>
      </div>
    </td>
  );
};

export default NameEmailCell;
