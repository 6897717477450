import React from "react";
import { Badge } from "@merge-api/merge-javascript-shared";
import { Box, Globe, User } from "lucide-react";
import { LinkedAccount } from "../../models/Entities";
import useAppContext from "../context/useAppContext";
import { BaseColor } from "@merge-api/merge-javascript-shared/dist/designSystem/types";

interface FieldMappingTargetBadgeProps {
  type?: string;
  linkedAccount?: LinkedAccount;
}

const FieldMappingTargetBadge = ({ type, linkedAccount }: FieldMappingTargetBadgeProps) => {
  const { user } = useAppContext();

  let color;
  let text;
  let icon;

  switch (type) {
    case "org-wide-target-field":
      color = "orange";
      text = user.organization.name + "-wide target field";
      icon = <Globe className="text-orange-70" size={12} />;
      break;
    case "common-model-override-field":
      color = "blue";
      text = "Common Model override field";
      icon = <Box className="text-blue-40" size={12} />;
      break;
    case "linked-account-specific-target-field":
      color = "yellow";
      text =
        (linkedAccount?.end_user.organization_name &&
        linkedAccount?.end_user.organization_name.length <= 15
          ? linkedAccount?.end_user.organization_name
          : "Linked Account") + "-specific target field";
      icon = <User className="text-yellow-80" size={12} />;
      break;
    default:
      color = "yellow";
      text = "Linked Account-specific target field";
      icon = <User className="text-yellow-80" size={12} />;
      break;
  }

  return (
    <Badge color={color as BaseColor} className="flex items-center gap-x-1 truncate" size="sm">
      {icon}
      <div className="font-normal truncate">{text}</div>
    </Badge>
  );
};

export default FieldMappingTargetBadge;
