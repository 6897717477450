import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { WebhookReceiver } from "../../../../../models/Entities";
import { getCapitalizedTimeFromNowShort } from "../../../../shared/utils/SharedComponentUtils";
import WebhookReceiverDetails from "./WebhookReceiverDetails";
import { Badge, Text, Tooltip } from "@merge-api/merge-javascript-shared";

export interface Props {
  /**
   * This is the content that shows in this row
   */
  receiver: WebhookReceiver;

  /**
   * Which integration this is for
   */
  integrationName: string;

  /**
   * The linked account id for the receiver parent
   */
  linkedAccountId: string;

  /**
   * Whether or not the integration's webhooks are configured to use a handshake process. Determines display of handshake UX.
   */
  integrationUsesWebhookHandshake: boolean;

  /**
   * Whether or not the integration's webhooks uses app level secret key.
   */
  integrationUsesAppLevelSecretKey: boolean;

  /**
   * True if the org has enabled auto webhook setup and the integration uses it.
   */
  isUsingAutoWebhookSetup?: boolean;

  /**
   * True if organization level signature key settings are used for webhook receiver signature key.
   */
  isUsingOrgLevelSignatureKey: boolean;

  /**
   * Max width of table
   */

  width: number;
}

const OneLineTooltip = styled(Tooltip)`
  & .tooltip-inner {
    max-width: 100%;
  }
`;

/**
 * Shows a single row for the WebhookEventsTable, where
 * each row shows one receiver itself.
 */
const WebhookReceiverRow = ({
  receiver,
  integrationName,
  linkedAccountId,
  integrationUsesWebhookHandshake,
  integrationUsesAppLevelSecretKey,
  isUsingAutoWebhookSetup,
  isUsingOrgLevelSignatureKey,
  width,
}: Props) => {
  const { event, is_active, last_received_at, is_awaiting_handshake } = receiver;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const detailsElementRef = useRef<Pick<HTMLInputElement, "focus">>(null);

  // Resets focused state when closing the row
  useEffect(() => {
    if (!isExpanded) {
      setIsFocused(false);
    }
  }, [isExpanded]);

  const statusBadge =
    is_awaiting_handshake && integrationUsesWebhookHandshake ? (
      <Badge color="red">Awaiting handshake</Badge>
    ) : is_active ? (
      <Badge color="teal">Enabled</Badge>
    ) : (
      <Tooltip title="Add signature key to enable listener">
        <Badge color="gray">Disabled</Badge>
      </Tooltip>
    );

  const timestampContents = last_received_at ? (
    <Text>{getCapitalizedTimeFromNowShort(last_received_at)}</Text>
  ) : (
    <Text className="text-gray-60">—</Text>
  );

  return (
    <>
      <tr
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        className="hover:cursor-pointer active:cursor-pointer focus:cursor-pointer"
      >
        <td>
          <Text>{event}</Text>
        </td>
        <td>{statusBadge}</td>
        <td>{timestampContents}</td>
      </tr>
      {isExpanded && (
        <td colSpan={6} className="">
          <WebhookReceiverDetails
            integrationName={integrationName}
            receiver={receiver}
            isInitiallyFocused={isFocused}
            width={width}
            linkedAccountId={linkedAccountId}
            integrationUsesWebhookHandshake={integrationUsesWebhookHandshake}
            integrationUsesAppLevelSecretKey={integrationUsesAppLevelSecretKey}
            isUsingAutoWebhookSetup={isUsingAutoWebhookSetup}
            isUsingOrgLevelSignatureKey={isUsingOrgLevelSignatureKey}
            ref={detailsElementRef}
          />
        </td>
      )}
    </>
  );
};

export default WebhookReceiverRow;
