import React from "react";
import { navigateToLinkedAccountDetailSelectiveSyncPathForId } from "../../../../../../../router/RouterUtils";
import ConfigurationWidgetCard from "./ConfigurationWidgetCard";
import { useHistory } from "react-router-dom";
import { Text, Badge, Link } from "@merge-api/merge-javascript-shared";

type SelectiveSyncWidgetCardProps = {
  selectiveSyncFiltersCount: number | undefined;
  linkedAccountId: string;
};

const SelectiveSyncWidgetCard = ({
  selectiveSyncFiltersCount,
  linkedAccountId,
}: SelectiveSyncWidgetCardProps) => {
  const history = useHistory();

  return (
    <ConfigurationWidgetCard
      cardTitle={
        <div className="flex flex-row items-center gap-2">
          <Text variant="h6">Selective Sync</Text>
          <Badge color="gray">{selectiveSyncFiltersCount}</Badge>
        </div>
      }
      badgeChildren={
        <Link
          variant="h6"
          className="text-blue-40 px-0"
          onClick={() =>
            navigateToLinkedAccountDetailSelectiveSyncPathForId(history, linkedAccountId)
          }
        >
          Edit
        </Link>
      }
    />
  );
};

export default SelectiveSyncWidgetCard;
