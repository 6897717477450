import React from "react";

type Props = Pick<React.ComponentProps<"div">, "className" | "onClick"> & {
  isRotatedDown: boolean;
  isVisible?: boolean;
};

const RotatingChevronRight = ({ isRotatedDown, isVisible = true, className, onClick }: Props) => (
  <div
    className={`fe fe-chevron-right text-gray-60 ${className || ""}`}
    onClick={onClick}
    style={{
      transform: isRotatedDown ? "rotate(90deg)" : "rotate(0deg)",
      transition: "transform 0.3s",
      visibility: isVisible ? "visible" : "hidden",
    }}
  />
);

export default RotatingChevronRight;
