import React from "react";
import { abbreviationForAPICategory, colorForAPICategory } from "../../../../../../../../services";
import { X } from "lucide-react";
import { APICategory, Badge } from "@merge-api/merge-javascript-shared";
import { CategoryMap } from "../../../hooks/useWebhookOptions";
import startCase from "lodash-es/startCase";

interface Props {
  commonModelName: string;
  onRemove?: () => void;
  modelToCategoryMap: CategoryMap;
}
const SyncCommonModelCard = ({ commonModelName, onRemove, modelToCategoryMap }: Props) => {
  return (
    <div className="px-2 py-1.5 flex flex-row items-start cursor-default justify-between border border-gray-10 rounded-lg w-fit bg-white">
      <div className="flex flex-row justify-center items-center">
        <div className="text-gray-60 font-semibold text-base whitespace-nowrap">
          {startCase(commonModelName)}
        </div>
        <Badge
          className="ml-1.5 mr-2"
          size="sm"
          color={colorForAPICategory(modelToCategoryMap[commonModelName] as APICategory)}
        >
          {abbreviationForAPICategory(modelToCategoryMap[commonModelName] as APICategory)}
        </Badge>
        <X
          onClick={onRemove}
          className="cursor-pointer hover:text-gray-60 transition-colors duration-300"
          size={12}
        />
      </div>
    </div>
  );
};

export default SyncCommonModelCard;
