import React from "react";
import FieldMappingModalTitle from "../FieldMappingModalTitle";
import { ArrowLeft } from "lucide-react";
import FieldMappingsMergeToIntegrationHeader from "../../components/FieldMappingsMergeToIntegrationHeader";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import { filterFieldMappingOptions } from "../../../../../configuration/field-mappings/utils/FieldMappingUtils";
import { Typeahead } from "@merge-api/merge-javascript-shared";
import AdvancedFieldMappingForm from "../field-mappings/advanced-field-mappings/AdvancedFieldMappingsForm";
import useRemoteFieldsDropdown from "../field-mappings/hooks/useRemoteFieldsDropdown";
import { FieldMappingCreationAndEditDict } from "../../../../../../../models/Entities";
import FieldMappingDropdownChild from "../../../../../../shared/FieldMappingDropdownChild";
import FieldMappingTargetBadge from "../../../../../../shared/FieldMappingTargetBadge";
import FieldMappingCard from "../../components/FieldMappingCard";
import FieldMappingsGridContainer from "../../components/FieldMappingsGridContainer";
import FieldMappingCardRemoteFieldsSubtitle from "../../components/FieldMappingCardRemoteFieldsSubtitle";
import FieldMappingRemoteFieldsBadge from "../../components/FieldMappingRemoteFieldsBadge";

const MapOverrideToField = () => {
  const {
    linkedAccount,
    commonModelSelected,
    onModalClose,
    preSelectedRemoteField,
    availableFieldMappings,
    selectedRemoteField,
    setSelectedRemoteField,
    isAdvancedMappingEnabled,
    setJmesPath,
    setIsJmesPathValid,
    setShowAdvancedMappingOption,
    showAdvancedMappingOption,
    jmesPath,
    isCreating,
    isJmesPathValid,
    fieldToOverride,
    createCommonModelOverride,
  } = useRemoteFieldsDropdown();

  return (
    <>
      <FieldMappingModalTitle
        title={`Override an existing Common Model mapping for ${linkedAccount?.organization.name}`}
        onClose={onModalClose}
      />
      <div className="px-5 mt-5">
        <div className="text-base">
          Select a third-party remote field to override this Merge field’s default mapping.
        </div>
        <div className="mt-2">
          You will no longer be able to POST data to this field. This change will be reflected after
          the next sync which will be a full resync of data for {linkedAccount?.organization.name}.
        </div>
        <div className="flex flex-col mt-6 gap-y-4">
          <FieldMappingsMergeToIntegrationHeader
            integrationName={linkedAccount?.integration.name!}
            integrationSquareImage={linkedAccount?.integration.square_image}
            isSingular
          />
          <FieldMappingsGridContainer>
            <>
              <FieldMappingCard
                displayName={fieldToOverride?.name}
                description={fieldToOverride?.description}
                badge={<FieldMappingTargetBadge type="common-model-override-field" />}
              />
              <div className="flex p-4 pt-3">
                <ArrowLeft size={20} />
              </div>
              <div>
                {preSelectedRemoteField ? (
                  <FieldMappingCard
                    displayName={preSelectedRemoteField.display_name}
                    description={
                      <FieldMappingCardRemoteFieldsSubtitle
                        remoteEndpointMethod={preSelectedRemoteField.api_endpoint_method}
                        remoteEndpointPath={preSelectedRemoteField.api_endpoint_path}
                      />
                    }
                    badge={<FieldMappingRemoteFieldsBadge />}
                    type={preSelectedRemoteField.type}
                  />
                ) : (
                  <Typeahead
                    options={
                      availableFieldMappings
                        ? filterFieldMappingOptions(
                            commonModelSelected!,
                            availableFieldMappings!,
                            fieldToOverride?.type || "string",
                            fieldToOverride?.format == "date-time",
                          )
                        : []
                    }
                    onChange={(_, selectedRemoteField: any) => {
                      if (selectedRemoteField) {
                        setSelectedRemoteField(selectedRemoteField);
                        // reset jmes path on any remote field selection switch
                        setJmesPath(null);
                        setIsJmesPathValid(false);
                        if (
                          ((selectedRemoteField?.type.includes("list") &&
                            selectedRemoteField.value?.length !== 0) ||
                            selectedRemoteField.type == "dict") &&
                          isAdvancedMappingEnabled
                        ) {
                          setTimeout(() => {
                            setShowAdvancedMappingOption(true);
                          }, 400);
                        } else {
                          setShowAdvancedMappingOption(false);
                        }
                      } else {
                        setSelectedRemoteField(null);
                        setShowAdvancedMappingOption(false);

                        // if advanced mapping tool is opened and closed and another remote field is selceted
                        setJmesPath(null);
                        setIsJmesPathValid(false);
                      }
                    }}
                    placeholder={
                      availableFieldMappings == undefined ? "Loading fields..." : "Search fields..."
                    }
                    getOptionLabel={(option: any) => {
                      return option?.display_name ?? "";
                    }}
                    renderOption={(fieldMapping: FieldMappingCreationAndEditDict) => {
                      return <FieldMappingDropdownChild option={fieldMapping} />;
                    }}
                    loadingText="Loading fields..."
                    loading={availableFieldMappings == undefined}
                  />
                )}
              </div>
            </>
          </FieldMappingsGridContainer>
        </div>
      </div>
      <div
        className={`${
          showAdvancedMappingOption && selectedRemoteField ? `mt-4 px-6` : "h-0 opacity-0"
        } transition-all ease-in-out duration-400`}
      >
        {showAdvancedMappingOption && selectedRemoteField && (
          <AdvancedFieldMappingForm
            setIsJmesPathValid={setIsJmesPathValid}
            onJmesPathChange={(jmesPath: string | null) => {
              setJmesPath(jmesPath);
            }}
            jsonData={selectedRemoteField.value}
          />
        )}
      </div>
      <div className="pr-6 !mt-6">
        <CancelAndNextFooter
          isLoading={isCreating}
          onCancel={onModalClose}
          buttonText="Save"
          onNext={() => {
            if (selectedRemoteField) {
              createCommonModelOverride();
            }
          }}
          isNextDisabled={selectedRemoteField == null || (jmesPath !== null && !isJmesPathValid)}
        />
      </div>
    </>
  );
};

export default MapOverrideToField;
