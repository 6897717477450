import React, { useState } from "react";
import ConfigurationSettingsCard from "../helpers/ConfigurationSettingsCard";
import { Form } from "react-bootstrap";
import { PreexistingProductionLinkedAccountPreference } from "../../../../../models/Entities";
import useCustomizationContext from "../context/useCustomizationContext";
import { MergeLinkCustomizationStatus } from "../context/CustomizationContext";

const ConfigurationDuplicateDetectionCard = () => {
  const linkToHelp = (
    <a
      href="https://help.merge.dev/en/articles/6463873-duplicate-production-linked-accounts"
      className="flex items-center text-sm font-medium"
      target="_blank"
      rel="noreferrer"
    >
      Learn more about duplicates <i className="fe fe-chevron-right ml-1" />
    </a>
  );
  const { organizationCustomization, setOrganizationCustomization } = useCustomizationContext();
  const preexistingProductionLinkedAccountPreference =
    organizationCustomization?.preexisting_production_linked_account_preference ??
    PreexistingProductionLinkedAccountPreference.CREATE_AS_NEW_END_USER;

  const updateOrganizationCustomization = (
    newPreference: PreexistingProductionLinkedAccountPreference,
  ) => {
    // either create new org customization value or update existing one
    if (organizationCustomization != undefined) {
      const newOrgCustomization = { ...organizationCustomization };
      newOrgCustomization.preexisting_production_linked_account_preference = newPreference;
      setOrganizationCustomization(newOrgCustomization);
    } else {
      const currentOrganizationCustomization = {
        status: "PUBLISHED" as MergeLinkCustomizationStatus,
        preexisting_production_linked_account_preference: newPreference,
      };
      setOrganizationCustomization(currentOrganizationCustomization);
    }
  };

  return (
    <ConfigurationSettingsCard
      title="Duplicate Linked Account detection"
      rightHandContent={linkToHelp}
    >
      <>
        Define what Merge should do when your user attempts to integrate using credentials (e.g. API
        Key, OAuth credentials, etc.) that match an existing production Linked Account
        <Form style={{ marginTop: "20px" }}>
          <Form.Check
            className="mb-3"
            type="radio"
            id="create-new-linked-account"
            label={
              <div className="text-body">
                Create a new Linked Account with the previously used credentials
              </div>
            }
            onChange={() =>
              updateOrganizationCustomization(
                PreexistingProductionLinkedAccountPreference.CREATE_AS_NEW_END_USER,
              )
            }
            checked={
              preexistingProductionLinkedAccountPreference ===
              PreexistingProductionLinkedAccountPreference.CREATE_AS_NEW_END_USER
            }
          />
          <Form.Check
            className="mb-3"
            type="radio"
            id="use-preexisting"
            label={
              <div className="text-body">
                Show a success message in Link to the end user but do not create a new Linked
                Account with the previously used credentials
                <p className="mb-0 text-gray-60">
                  Note: This option should only be used after you’ve defined logic to associate
                  multiple of your users with the same account token in your Merge Link
                  implementation.
                </p>
              </div>
            }
            onChange={() =>
              updateOrganizationCustomization(
                PreexistingProductionLinkedAccountPreference.USE_PREEXISTING_END_USER,
              )
            }
            checked={
              preexistingProductionLinkedAccountPreference ===
              PreexistingProductionLinkedAccountPreference.USE_PREEXISTING_END_USER
            }
          />
          <Form.Check
            className="mb-3"
            type="radio"
            id="block-duplicates"
            label={
              <div className="text-body">
                Show an error message in Link to the end user and do not create a new Linked Account
                with the previously used credentials
              </div>
            }
            onChange={() =>
              updateOrganizationCustomization(PreexistingProductionLinkedAccountPreference.BLOCK)
            }
            checked={
              preexistingProductionLinkedAccountPreference ===
              PreexistingProductionLinkedAccountPreference.BLOCK
            }
          />
        </Form>
      </>
    </ConfigurationSettingsCard>
  );
};

export default ConfigurationDuplicateDetectionCard;
