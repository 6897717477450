import React from "react";
import { format } from "date-fns";
import clsx from "clsx";
import ContentLoader from "react-content-loader";
import { Trash, ChevronRight, Users } from "lucide-react";
import { useHistory } from "react-router-dom";
import { AuthType, LinkedAccount, PauseReason } from "../../../models/Entities";
import { displayNameForAPICategoryIntegration } from "../../../models/Helpers";
import { navigateToLinkedAccountDetailPage } from "../../../router/RouterUtils";
import { spectrum } from "../../../styles/theme";
import IntegrationNameAndLogo from "../configuration/integrations/IntegrationNameAndLogo";
import EmptyStateWrapper from "../../shared/EmptyStateWrapper";
import MergeTable from "../../shared/MergeTable";
import { Button, Badge, Tooltip, Text, ButtonVariant } from "@merge-api/merge-javascript-shared";
import LinkedAccountStatusBadge from "./shared/LinkedAccountStatusBadge";

type LinkedAccountTableProps = {
  forceResync: (linkedAccount: LinkedAccount) => void;
  isLoading: boolean;
  linkedAccounts?: LinkedAccount[];
  isTestAccounts: boolean;
  setLinkedAccountToDelete: (linkedAccount: LinkedAccount) => void;
};

function LinkedAccountTable(props: LinkedAccountTableProps) {
  const { isLoading, linkedAccounts, setLinkedAccountToDelete } = props;

  const history = useHistory();

  const header = (
    <>
      <th scope="col">Date linked</th>
      <th scope="col">Organization</th>
      <th scope="col">Integration</th>
      <th scope="col">Category</th>
      <th scope="col">Status</th>
      <td scope="col"></td>
    </>
  );

  const linkedAccountDate = (linkedAccount: LinkedAccount) => {
    const linkDate = linkedAccount.completed_at;

    return (
      <Text
        className={clsx({
          "text-gray-60": linkedAccount.pause_reason === PauseReason.PLAN_LIMIT_REACHED,
        })}
      >
        {linkDate ? format(new Date(linkDate), "MMM dd, yyyy") : "-"}
      </Text>
    );
  };

  // This is an array containing all the rows for Linked Accounts if they exist
  const linkedAccountsRows =
    linkedAccounts &&
    linkedAccounts?.length > 0 &&
    linkedAccounts.map((linkedAccount) => (
      <React.Fragment key={linkedAccount.id}>
        <tr
          className={clsx("table-link", linkedAccount.pause_reason && "text-gray-60")}
          key={linkedAccount.id}
          style={{ borderBottomWidth: 0 }}
          onClick={(event) => {
            event.stopPropagation();
            navigateToLinkedAccountDetailPage(history, linkedAccount);
          }}
        >
          <td>{linkedAccountDate(linkedAccount)}</td>
          <td>
            <div className="flex flex-row items-center gap-1.5">
              {linkedAccount?.is_duplicate && (
                <Tooltip title="Duplicate Linked Account detected">
                  <Users
                    size={16}
                    className={linkedAccount.pause_reason ? "text-gray-60" : "text-yellow-70"}
                  />
                </Tooltip>
              )}
              <Text>{linkedAccount.end_user.organization_name}</Text>
            </div>
          </td>
          <td>
            <div className="flex flex-row items-center gap-2">
              <IntegrationNameAndLogo
                isMuted={linkedAccount?.pause_reason ? true : false}
                integration={linkedAccount.integration}
              />
              {linkedAccount.auth_type === AuthType.SFTP && (
                <Badge className="items-center gap-1" color="purple">
                  SFTP
                </Badge>
              )}
            </div>
          </td>
          <td>
            {displayNameForAPICategoryIntegration(
              linkedAccount.category,
              linkedAccount?.pause_reason === PauseReason.PLAN_LIMIT_REACHED,
            )}
          </td>
          <td>
            <LinkedAccountStatusBadge linkedAccount={linkedAccount} />
          </td>
          <td className="flex justify-end items-center gap-x-2">
            <Tooltip title="Delete linked account">
              <Button
                variant={ButtonVariant.IconOnly}
                size="sm"
                className="mr-2 max-w-7 "
                onClick={(event: { stopPropagation: () => void }) => {
                  event.stopPropagation();
                  setLinkedAccountToDelete(linkedAccount);
                }}
              >
                <Trash size={16} />
              </Button>
            </Tooltip>

            <Tooltip title="Review linked account details">
              <ChevronRight size={16} className="text-gray-60" />
            </Tooltip>
          </td>
        </tr>
      </React.Fragment>
    ));

  const content = (
    <>
      {!isLoading && linkedAccounts ? (
        linkedAccounts.length ? (
          linkedAccountsRows
        ) : (
          <tr>
            <td colSpan={6}>
              <EmptyStateWrapper isTable title="No Linked Accounts" />
            </td>
          </tr>
        )
      ) : (
        Array.from({ length: 25 }).map((_, i) => (
          <tr key={`linked-account-skel-row-${i}`} className="table-row">
            <td>
              <ContentLoader
                speed={1.4}
                width={80}
                height={20}
                viewBox="0 0 80 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
              </ContentLoader>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={80}
                height={20}
                viewBox="0 0 80 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
              </ContentLoader>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={40}
                height={20}
                viewBox="0 0 40 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="40" height="14" />
              </ContentLoader>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={80}
                height={20}
                viewBox="0 0 80 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
              </ContentLoader>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={80}
                height={20}
                viewBox="0 0 80 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
              </ContentLoader>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={20}
                height={20}
                viewBox="0 0 20 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="20" height="14" />
              </ContentLoader>
            </td>
          </tr>
        ))
      )}
    </>
  );

  return <MergeTable header={header} content={content} hasMorePaddingOnFirstElement />;
}

export default LinkedAccountTable;
