import React, { useEffect, useState } from "react";
import { Row, Col, Card, ListGroup } from "react-bootstrap";
import { User, MessageSquare } from "lucide-react";
import { IssuesStatusBadge } from "../../shared/MergeBadges";
import { IntegrationIssue, IntegrationIssueStatus } from "../IntegrationsManagementEntities";
import classNames from "classnames";
import { useHistory, Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { getCapitalizedTimeFromNow } from "../../shared/utils/SharedComponentUtils";
import { ISSUES_PATH, navigateToIndividualIssuePage } from "../../../router/RouterUtils";
import { spectrum } from "../../../styles/theme";
import IntegrationNameAndLogo from "../configuration/integrations/IntegrationNameAndLogo";
import { CardHeaderTitle } from "../../shared/MergeText";
import styled, { css } from "styled-components";
import DeprecatedH2 from "../../../deprecated/DeprecatedH2";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";

interface Props {
  integrationIssues: IntegrationIssue[] | undefined;
}

// Cross browser way to truncate to one line
const ONE_LINE_TRUNCATION = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContainerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftColumnDiv = styled.div`
  flex-basis: 80%;
  min-width: 0;
`;

const FirstRowDiv = styled.div`
  display: flex;
  align-items: center;
`;

const H4Truncated = styled(DeprecatedH4)`
  ${ONE_LINE_TRUNCATION};
`;

const EndUserOrganizationNameTruncated = styled.div`
  ${ONE_LINE_TRUNCATION};
  max-width: 150px;
`;

//Prevents wrapping of days left
const TimeDiv = styled.span`
  white-space: nowrap;
`;

const SecondRowDiv = styled.div`
  align-items: center;
  display: flex;
  white-space: nowrap;
`;

// Flexbox truncate text requires it at every level of the component when its nested inside a flexbox..
const IntegrationNameSpan = styled.span`
  ${ONE_LINE_TRUNCATION};
  display: inline-block;
`;

const IssuesStatusDiv = styled.div`
  margin-left: 16px;
`;

const IntegrationIssuesScreen = ({ integrationIssues }: Props) => {
  const numDisplayIssues = 5;
  const history = useHistory();
  const [displayIntegrationIssues, setDisplayIntegrationIssues] = useState<IntegrationIssue[]>([]);
  const [displayOngoing, setDisplayOngoing] = useState<boolean>(true);

  useEffect(() => {
    setDisplayIntegrationIssues(
      integrationIssues
        ?.filter(
          (integrationIssue: IntegrationIssue) =>
            integrationIssue.status == IntegrationIssueStatus.ONGOING,
        )
        .slice(0, numDisplayIssues) ?? [],
    );
  }, [integrationIssues]);

  return (
    <Card>
      <Card.Header>
        <CardHeaderTitle>Issues</CardHeaderTitle>
        {integrationIssues && (
          <ul className="nav nav-tabs nav-tabs-sm card-header-tabs">
            <li className="nav-item">
              <a
                className={classNames("nav-link", displayOngoing ? "active" : "")}
                data-toggle="tab"
                onClick={() => {
                  setDisplayIntegrationIssues(
                    integrationIssues
                      ?.filter(
                        (integrationIssue: IntegrationIssue) =>
                          integrationIssue.status == IntegrationIssueStatus.ONGOING,
                      )
                      .slice(0, numDisplayIssues),
                  );
                  setDisplayOngoing(true);
                }}
              >
                Ongoing
              </a>
            </li>
            <li className="nav-item">
              <a
                className={classNames("nav-link", displayOngoing ? "" : "active")}
                data-toggle="tab"
                onClick={() => {
                  setDisplayIntegrationIssues(integrationIssues?.slice(0, numDisplayIssues));
                  setDisplayOngoing(false);
                }}
              >
                All
              </a>
            </li>
          </ul>
        )}
      </Card.Header>

      <Card.Body className="p-0">
        {integrationIssues && displayIntegrationIssues ? (
          displayIntegrationIssues.length > 0 ? (
            <ListGroup variant="flush">
              {displayIntegrationIssues.map((integrationIssue) => (
                <ListGroup.Item
                  action
                  onClick={() => {
                    navigateToIndividualIssuePage(history, integrationIssue.id);
                  }}
                  className="pl-6 pr-6"
                  key={integrationIssue.id}
                >
                  <ContainerDiv>
                    <LeftColumnDiv>
                      <FirstRowDiv>
                        <H4Truncated className="issue-name mr-2 mb-0">
                          {integrationIssue.error_description}
                        </H4Truncated>
                        {integrationIssue.last_incident_time != null ? (
                          <TimeDiv className="text-gray-60 small font-weight-light mt-1">
                            {getCapitalizedTimeFromNow(integrationIssue.last_incident_time)}
                          </TimeDiv>
                        ) : null}
                      </FirstRowDiv>
                      <SecondRowDiv className="mt-1">
                        <IntegrationNameSpan className="issue-subdetail small text-gray-60 font-weight-light">
                          <IntegrationNameAndLogo
                            integration={integrationIssue.linked_account.integration}
                          />
                        </IntegrationNameSpan>
                        <span className="issue-subdetail small ml-3 text-gray-60 font-weight-light">
                          <User size={12} className="mr-2" />
                          <EndUserOrganizationNameTruncated>
                            {integrationIssue.linked_account.end_user.organization_name}
                          </EndUserOrganizationNameTruncated>
                        </span>
                        <span className="issue-subdetail small ml-3 text-gray-60 font-weight-light">
                          <MessageSquare size={12} className="mr-2" />
                          {integrationIssue.comment_count}
                        </span>
                      </SecondRowDiv>
                    </LeftColumnDiv>
                    <IssuesStatusDiv>
                      <IssuesStatusBadge className="float-right" status={integrationIssue.status} />
                    </IssuesStatusDiv>
                  </ContainerDiv>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : displayOngoing ? (
            <Row>
              <Col className="col d-flex align-items-center justify-content-center pt-16 pb-16">
                <DeprecatedH2 className="text-gray-60 mb-0">No ongoing issues</DeprecatedH2>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="col d-flex align-items-center justify-content-center pt-16 pb-16">
                <DeprecatedH2 className="text-gray-60 mb-0">No issues</DeprecatedH2>
              </Col>
            </Row>
          )
        ) : (
          <ListGroup variant="flush">
            {Array.from({ length: 5 }).map((_, i) => (
              <ListGroup.Item action className="pl-6 pr-6" key={`issue-preview-skel-row-${i}`}>
                <ContentLoader
                  speed={1.4}
                  width={240}
                  height={45}
                  viewBox="0 0 240 45"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="4" y="4" rx="3" ry="3" width="230" height="14" />
                  <rect x="4" y="27" rx="3" ry="3" width="160" height="14" />
                </ContentLoader>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Card.Body>
      <Card.Footer>
        <span className="float-right">
          <Link
            to={ISSUES_PATH}
            style={{ display: "flex", alignItems: "center" }}
            className="text-primary font-medium small"
          >
            Go to Issues
            <i className="fe fe-chevron-right" style={{ marginLeft: 1 }} />
          </Link>
        </span>
      </Card.Footer>
    </Card>
  );
};

export default IntegrationIssuesScreen;
