import React from "react";
import {
  ScraperLogEntry,
  ScraperRequestType,
  ScraperTriggerType,
} from "../../IntegrationsManagementEntities";
import { Spinner, Text } from "@merge-api/merge-javascript-shared";

type ScraperSectionProps = {
  logEntry?: ScraperLogEntry;
};

const requestTypeToHumanReadable = (value: string) => {
  switch (value) {
    case ScraperRequestType.COMMON_MODEL_DATA: {
      return "Common Model data";
    }
    case ScraperRequestType.AUTH_DATA: {
      return "Authentication";
    }
    default: {
      return value;
    }
  }
};

const triggerTypeToHumanReadable = (value: string) => {
  switch (value) {
    case ScraperTriggerType.FORCE_SYNC: {
      return "Force sync";
    }
    case ScraperTriggerType.INITIAL_LINK:
    case ScraperTriggerType.INITIAL_SYNC: {
      return "Initial sync";
    }
    case ScraperTriggerType.PERIODIC_SYNC: {
      return "Periodic sync";
    }
    default: {
      return value;
    }
  }
};

const prettyPrintDatetime = (dateString: string) => {
  const date = new Date(dateString);
  return date.toDateString() + " " + date.toLocaleTimeString();
};

const prettyPrintLogRuntime = (runtime: string) => {
  const date = new Date(1970, 0, 1);
  date.setSeconds(Number(runtime));
  return "" + date.getHours() + "h " + date.getMinutes() + "m " + date.getSeconds() + "s";
};

export const ScraperLogSidePanelRequestSection = ({ logEntry }: ScraperSectionProps) => {
  return (
    <>
      {logEntry === undefined ? (
        <div className="w-full h-full justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="w-100 fs-mask flex flex-col gap-y-6">
          <div className="break-all">
            <Text variant="h6" className="text-gray-60">
              Request type
            </Text>
            <Text>{requestTypeToHumanReadable(logEntry.request_type)}</Text>
          </div>
          <div className="break-all">
            <Text variant="h6" className="text-gray-60">
              Trigger type
            </Text>
            <Text>{triggerTypeToHumanReadable(logEntry.trigger_type)}</Text>
          </div>
        </div>
      )}
    </>
  );
};

export const ScraperLogSidePanelResponseSection = ({ logEntry }: ScraperSectionProps) => {
  return (
    <>
      {logEntry === undefined ? (
        <div className="w-full h-full justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="w-100 fs-mask flex flex-col gap-y-6">
          {logEntry.error_message && (
            <div className="break-all">
              <Text variant="h6" className="text-gray-60">
                Error message
              </Text>
              <Text>{logEntry.error_message}</Text>
            </div>
          )}

          <div className="break-all">
            <Text variant="h6" className="text-gray-60">
              Runtime
            </Text>
            <Text>{prettyPrintLogRuntime(logEntry.runtime)}</Text>
          </div>

          <div className="break-all">
            <Text variant="h6" className="text-gray-60">
              Completion time
            </Text>
            <Text>{prettyPrintDatetime(logEntry.completion_time)}</Text>
          </div>

          <div className="break-all">
            <Text variant="h6" className="text-gray-60">
              Last fetch time
            </Text>
            <Text>{prettyPrintDatetime(logEntry.last_fetch_time)}</Text>
          </div>
        </div>
      )}
    </>
  );
};
