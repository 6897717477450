import React from "react";
import { LinkedAccount, LinkedAccountStatus, PauseReason } from "../../../../models/Entities";
import { Badge, Link, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { AlertCircle, CheckCircle2, Info, PauseCircle, XCircle } from "lucide-react";
import { BILLING_PATH } from "../../../../router/RouterUtils";
import { LinkedAccountStatuses } from "../../../../constants";
import { BaseColor } from "@merge-api/merge-javascript-shared/dist/designSystem/types";

type Props = {
  linkedAccount: LinkedAccount;
  size?: "sm" | "md" | "lg" | undefined;
};

const LinkedAccountStatusBadge = ({ linkedAccount, size }: Props) => {
  // New code
  if (linkedAccount.status === LinkedAccountStatus.RELINK_NEEDED) {
    return (
      <Badge color="red" className="flex items-center whitespace-nowrap gap-1" size={size}>
        <XCircle size={12} />
        Relink needed
      </Badge>
    );
  }

  if (linkedAccount.pause_reason === PauseReason.PLAN_LIMIT_REACHED) {
    return (
      <div className="flex items-center gap-2">
        <Text className="text-gray-60">Upgrade plan</Text>
        <Tooltip
          title={
            <>
              You can only connect 3 production and 3 test Linked Accounts on the Free plan.{" "}
              <Link href={BILLING_PATH} className="underline text-white">
                Upgrade your plan
              </Link>{" "}
              to link more accounts.
            </>
          }
        >
          <Info className="text-gray-60" size={16} />
        </Tooltip>
      </div>
    );
  }

  const status =
    linkedAccount.pause_reason == PauseReason.FAILING_SYNCS ||
    linkedAccount.pause_reason == PauseReason.LINKED_ACCOUNT_INACTIVITY ||
    linkedAccount.pause_reason == PauseReason.PRICING_PLAN_LIMIT
      ? LinkedAccountStatuses.IDLE
      : linkedAccount.status;

  let color;
  let icon;
  let text;

  switch (status) {
    case LinkedAccountStatus.COMPLETE:
      color = "teal";
      icon = <CheckCircle2 size={12} />;
      text = "Linked";
      break;
    case LinkedAccountStatus.INCOMPLETE:
      color = "yellow";
      icon = <AlertCircle size={12} />;
      text = "Incomplete";
      break;
    case "IDLE":
      color = "gray";
      icon = <PauseCircle size={12} />;
      text = "Idle";
      break;
    default:
      color = "teal";
      icon = <CheckCircle2 size={12} />;
      text = "Linked";
      break;
  }
  return (
    <Badge
      color={color as BaseColor}
      className="flex items-center whitespace-nowrap gap-1"
      size={size}
    >
      {icon}
      {text}
    </Badge>
  );
};

export default LinkedAccountStatusBadge;
