import React from "react";
import type { match } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { WebhookType } from "../../../../models/LogFilter";
import IntegrationNameAndLogo from "../../configuration/integrations/IntegrationNameAndLogo";
import { HTTPMethodBadge, ResponseCodeBadge } from "../../../shared/MergeBadges";
import type { WebhookLogEntry } from "../../IntegrationsManagementEntities";
import DirectionCell from "./DirectionCell";
import { LinkedAccount } from "../../../../models/Entities";
import { formatDate } from "../../../../models/Helpers";
import { Tooltip, Text } from "@merge-api/merge-javascript-shared";
import { ChevronRight } from "lucide-react";
import clsx from "clsx";

interface Props {
  log: WebhookLogEntry;
  match: match;
  linkedAccount?: LinkedAccount | null;

  /**
   * Called when the route is pushed when clicking this row
   */
  onPush?: () => void;
}

/**
 * Creates one table row for a log entry
 */
const WebhookLogEntryRow = ({ log, match, onPush, linkedAccount }: Props) => {
  const history = useHistory();
  const isLinkedAccountTab = !!linkedAccount;

  const navigateToLogEntrySidePanel = (logId: string) => {
    onPush && onPush();
    history.push(`${match.url}/${logId}`, { from: window.location.pathname });
  };

  const methodCell = (
    <td>
      <HTTPMethodBadge method={log?.method ?? "POST"} />
    </td>
  );

  const urlCell = (
    <td>
      {log.url && (
        <Tooltip
          title={log.url}
          className={clsx(
            "truncate max-w-[50px] md:max-w-[100px]",
            isLinkedAccountTab ? "lg:max-w-[400px]" : "lg:max-w-[150px]",
          )}
        >
          <Text className="truncate">{log.url}</Text>
        </Tooltip>
      )}
    </td>
  );

  const statusCell = (
    <td>
      <ResponseCodeBadge responseCode={log.response_code} />
    </td>
  );

  const integrationCell = !linkedAccount ? (
    <td>
      {log.linked_account?.integration && (
        <IntegrationNameAndLogo integration={log.linked_account.integration} />
      )}
    </td>
  ) : null;

  const userCell = !linkedAccount ? (
    <td>
      <Text>{log.linked_account?.end_user?.organization_name}</Text>
    </td>
  ) : null;
  const typeCell = (
    <td>
      <Text>
        {log?.webhook.type === WebhookType.CHANGED_DATA
          ? "Changed data"
          : log?.webhook?.type === WebhookType.SYNC_NOTIFICATION
          ? "Sync notification"
          : log?.webhook?.type === WebhookType.RECEIVING_WEBHOOK
          ? "Receiving webhook"
          : log?.webhook.type === WebhookType.LINKED_ACCOUNT_ISSUE
          ? "Linked Account issue"
          : ""}
      </Text>
    </td>
  );

  const eventCell = (
    <td>
      <Text>{log?.webhook?.event}</Text>
    </td>
  );

  const dateCell = (
    <td>
      <Text className="text-gray-60">{formatDate(log.created_at)}</Text>
    </td>
  );

  return (
    <tr className="table-link" onClick={() => navigateToLogEntrySidePanel(log.id)}>
      {methodCell}
      {urlCell}
      {log?.direction && <DirectionCell log={log} />}
      {statusCell}
      {log?.webhook?.type && typeCell}
      {log?.webhook?.event && eventCell}
      {integrationCell}
      {userCell}
      {dateCell}
      <td className="text-right">
        <Tooltip title="Review log details">
          <ChevronRight size={16} className="text-gray-60" />
        </Tooltip>
      </td>
    </tr>
  );
};

export default WebhookLogEntryRow;
