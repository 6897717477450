import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Integration, LinkedAccount, Log } from "../../../models/Entities";
import {
  navigateToIssueLogEntrySidePanel,
  navigateToLinkedAccountLogsPageById,
} from "../../../router/RouterUtils";
import { Arrow, ArrowDirection } from "../logs/table/DirectionCell";
import { Dialog, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { formatDate } from "../../../models/Helpers";
import { APIRequestLogEntry } from "../IntegrationsManagementEntities";
import useAppContext from "../../context/useAppContext";
import { RefreshCw } from "lucide-react";
import useRetryLog from "../utils/useRetryLog";
import { ResponseCodeBadge } from "../../shared/MergeBadges";
import MergeCodeBlock from "../../shared/MergeCodeBlock";
import EmptyStateWrapper from "../../shared/EmptyStateWrapper";

const TextField = ({
  name,
  value,
  children,
}: {
  name: string;
  value: string | object | undefined | null;
  children?: JSX.Element;
}) => {
  const text =
    !value || (typeof value === "string" && value.length == 0)
      ? "None"
      : typeof value === "string" && !children
      ? value
      : children;
  return (
    <>
      <Text variant="h6">{name}</Text>
      <Text className="text-wrap break-words col-span-2">{text}</Text>
    </>
  );
};

type RetryLogModalProps = {
  log?: APIRequestLogEntry;
  responseCode: number | undefined;
  responseBody: string;
  isShown: boolean;
  setIsShown: (value: boolean) => void;
};

const RetryLogModal = ({
  log,
  responseCode,
  responseBody,
  isShown,
  setIsShown,
}: RetryLogModalProps) => {
  return (
    <Dialog
      open={isShown}
      footerButtonsHidden
      onClose={() => setIsShown(false)}
      title="Retry API request results"
      variant="lg"
    >
      {responseCode ? (
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-3 gap-3 pb-3 border-b-[0.5px] border-gray-20">
            <TextField name="URL" value={log?.url} />
            <TextField name="Direction" value={log?.direction} />
            <TextField name="Method" value={log?.method} />
          </div>
          <div className="grid grid-cols-3 gap-3 pb-3 border-b-[0.5px] border-gray-20">
            <TextField name="Headers" value={log?.request_headers} />
            <TextField name="Body" value={log?.request_body} />
            <TextField name="Status" value={undefined}>
              <ResponseCodeBadge responseCode={responseCode} size="sm" />
            </TextField>
          </div>
          <div className="pt-3">
            <MergeCodeBlock
              codeBlockName="Response body"
              language="json"
              colorMode="light"
              textToCopy={responseBody}
            >
              {responseBody || "The remote API returned an empty body."}
            </MergeCodeBlock>
          </div>
        </div>
      ) : (
        <EmptyStateWrapper isSpinner />
      )}
    </Dialog>
  );
};

type LogRowProp = {
  log: Log | APIRequestLogEntry;
  linkedAccount: LinkedAccount;
  issueID?: string;
};

const LogRow = ({ log, linkedAccount, issueID }: LogRowProp) => {
  const history = useHistory();
  const { isUserPrivileged } = useAppContext();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [testedLog, setTestedLog] = useState<APIRequestLogEntry>();
  const [responseCode, setResponseCode] = useState<number>();
  const [responseBody, setResponseBody] = useState<string>("");

  const { retryLog } = useRetryLog(
    log as APIRequestLogEntry,
    linkedAccount.integration as Integration,
    setResponseCode,
    setResponseBody,
    setShowModal,
  );

  return (
    <>
      <div
        className="flex flex-row items-center px-4 py-3 gap-x-3 border-t-[0.5px] border-gray-20 hover:bg-gray-0 hover:cursor-pointer"
        onClick={() => {
          issueID
            ? navigateToIssueLogEntrySidePanel(history, issueID, log.id)
            : navigateToLinkedAccountLogsPageById(history, linkedAccount.id, log.id);
        }}
      >
        <Text variant="pre-title" className="text-red-60 whitespace-nowrap">
          {log.response_code} Error
        </Text>
        <div className="flex flex-row flex-grow items-center bg-gray-0 rounded-2xl p-1 truncate justify-between">
          <div className="gap-x-2 flex flex-row truncate">
            <img
              src={log.linked_account?.integration?.square_image}
              width="24"
              height="24"
              className="rounded-full"
              alt=""
            />
            <Arrow
              direction={ArrowDirection.LEFT}
              tooltip={`Merge to ${log.linked_account?.integration?.name}`}
            />
            <div className="flex items-center gap-x-1 truncate">
              <Text variant="pre-title" className="text-blue-40">
                {log.method}
              </Text>
              <Text variant="sm" className="truncate">
                {log.url}
              </Text>
            </div>
          </div>

          {isUserPrivileged && issueID && (
            <Tooltip title="Retry request">
              <div
                className="flex justify-center items-center bg-white rounded-full w-6 h-6"
                onClick={(e) => {
                  e.stopPropagation();
                  retryLog();
                  setTestedLog(log as APIRequestLogEntry);
                }}
              >
                <RefreshCw size={12} />
              </div>
            </Tooltip>
          )}
        </div>

        <Text variant="sm" className="text-gray-60 truncate">
          {formatDate(log.created_at)}
        </Text>
      </div>
      <RetryLogModal
        log={testedLog}
        responseCode={responseCode}
        responseBody={responseBody}
        isShown={showModal}
        setIsShown={setShowModal}
      />
    </>
  );
};

export default LogRow;
