import React from "react";
import { Globe } from "lucide-react";
import { LinkedAccount } from "../../../../../../models/Entities";
import { Badge } from "@merge-api/merge-javascript-shared";
import useAppContext from "../../../../../context/useAppContext";

interface FieldMappingRemoteFieldsBadgeProps {
  linkedAccount?: LinkedAccount;
  isOrganizationWide?: boolean;
}

export const FieldMappingRemoteFieldsBadge = ({
  linkedAccount,
  isOrganizationWide,
}: FieldMappingRemoteFieldsBadgeProps) => {
  const { user } = useAppContext();
  return (
    <Badge
      color={isOrganizationWide ? "orange" : "yellow"}
      className="flex items-center gap-x-1 no-wrap truncate"
      size="sm"
    >
      <Globe className={isOrganizationWide ? "text-orange-70" : "text-yellow-80"} size={12} />
      <div className="font-normal truncate">
        {isOrganizationWide
          ? user.organization.name + "-wide mapping"
          : linkedAccount && linkedAccount?.end_user.organization_name.length <= 15
          ? linkedAccount?.end_user.organization_name + "-specific mapping"
          : "Linked Account-specific mapping"}
      </div>
    </Badge>
  );
};

export default FieldMappingRemoteFieldsBadge;
