import React from "react";
import classNames from "classnames";
import { Spinner, Text } from "@merge-api/merge-javascript-shared";

interface Props {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  isTable?: boolean;
  isSpinner?: boolean;
}

function EmptyStateWrapper(props: Props) {
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center gap-y-1",
        props.isTable ? "my-9" : "m-9",
      )}
    >
      {props.title && (
        <Text variant="h5" className={classNames("text-gray-60", !props.subtitle && "mb-0")}>
          {props.title}
        </Text>
      )}
      {props.subtitle && (
        <Text variant="sm" className={classNames("text-gray-60", "mb-0")}>
          {props.subtitle}
        </Text>
      )}
      {props.isSpinner && <Spinner />}
    </div>
  );
}

export default EmptyStateWrapper;
