import React from "react";

type IPAddressCellProps = {
  ipAddress: string;
};

const IPAddressCell = ({ ipAddress }: IPAddressCellProps) => {
  return <td className="text-sm font-medium text-gray-60">{ipAddress}</td>;
};

export default IPAddressCell;
