import styled from "styled-components";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import {
  APICategory,
  FieldMappingTarget,
  FieldMappingCommonModelConfiguration,
  HTTPMethod,
  FieldMappingMetaResponse,
  OverriddenCommonModelTarget,
  OverriddenCommonModelInstance,
  CreateOverriddenCommonModelInstanceDict,
  EditOverriddenCommonModelInstanceDict,
  CreateOrgWideCommonModelOverrideTargetDict,
  OverrideModelAndFieldOptions,
  OverrideCommonModelIntegrationInfo,
  AvailableFieldMappingIntegration,
  FieldMappingOptions,
  FieldMappingCreationAndEditDict,
  FieldMappingOptionsResponse,
  FieldMappingPreview,
} from "../../../../../models/Entities";
import get from "lodash/get";
import { UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";
export const getFieldMappingTargets = ({
  category,
  onResponse,
  onError,
}: {
  category: APICategory;
  onResponse: (response: FieldMappingTarget[]) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-targets/${category}`,
    method: HTTPMethod.GET,
    onResponse,
    onError,
  });
};

export const getCommonModelsFromCategory = (props: {
  category: APICategory;
  onFetch: (response: FieldMappingTarget[]) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/common-model-toggles/${props.category}`,
    method: HTTPMethod.GET,
    onResponse: (data: FieldMappingTarget[]) => {
      props.onFetch(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const getFieldMappingTarget = ({
  onResponse,
  onError,
  fieldMappingTargetId,
}: {
  fieldMappingTargetId: string;
  onResponse: (response: FieldMappingTarget) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-target/${fieldMappingTargetId}`,
    method: HTTPMethod.GET,
    onResponse,
    onError,
  });
};

export const patchFieldMappingTarget = ({
  body,
  fieldMappingTargetId,
  onResponse,
  onError,
}: {
  body: {
    is_mappable_in_link: boolean;
    weight: number;
  };
  fieldMappingTargetId: string;
  onResponse: (updatedFieldMappingTarget: FieldMappingTarget) => void;
  onError: (errorResponse: Response | undefined) => void;
}) =>
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-targets/${fieldMappingTargetId}/edit`,
    method: HTTPMethod.PATCH,
    body,
    onResponse,
    onError,
  });

export const addNewFieldMappingTarget = ({
  body,
  category,
  onResponse,
  onError,
}: {
  body: {
    common_model_id?: string;
    field_key?: string;
    field_description?: string;
  };
  category: APICategory;
  onResponse: () => void;
  onError: (errorResponse: Response | undefined) => void;
}) =>
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-targets/${category}`,
    method: HTTPMethod.POST,
    body,
    onResponse,
    onError,
  });

export const deleteFieldMappingTarget = ({
  fieldMappingTargetId,
  onResponse,
  onError,
}: {
  fieldMappingTargetId: string;
  onResponse: () => void;
  onError: (errorResponse: Response | undefined) => void;
}) =>
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-targets/${fieldMappingTargetId}/edit`,
    method: HTTPMethod.DELETE,
    onResponse,
    onError,
  });

export const getFieldMappingConfigurations = (props: {
  category: APICategory | UnreleasedAPICategory;
  onFetch: (response: FieldMappingCommonModelConfiguration[]) => void;
  onError: (errorResponse: Response | undefined) => void;
  linked_account_id?: string | undefined;
}) => {
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-configurations/${props.category}${
      props.linked_account_id ? `?linked_account_id=${props.linked_account_id}` : ""
    }`,
    method: HTTPMethod.GET,
    onResponse: (data: FieldMappingCommonModelConfiguration[]) => {
      props.onFetch(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const patchFieldMappingConfiguration = ({
  body,
  category,
  onResponse,
  onError,
}: {
  body: {
    configuration_id: string;
    allow_user_created_field_mapping_targets: boolean;
  };
  category: APICategory;
  onResponse: (updatedConfiguration: FieldMappingCommonModelConfiguration) => void;
  onError: (errorResponse: Response | undefined) => void;
}) =>
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-configurations/${category}`,
    method: HTTPMethod.PATCH,
    body,
    onResponse,
    onError,
  });

export const getFieldMappingOptions = (props: {
  linkedAccountID: string;
  onFetch: (response: FieldMappingMetaResponse) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/linked-account/field-mappings/${props.linkedAccountID}/meta?include_overrides_meta=True`,
    method: HTTPMethod.GET,
    onResponse: props.onFetch,
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const getFieldMappingMeta = (props: {
  linkedAccountID: string;
  onFetch: (response: FieldMappingMetaResponse) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/linked-account/field-mappings/${props.linkedAccountID}/meta?include_overrides_meta=True&skip_field_mapping_options=True`,
    method: HTTPMethod.GET,
    onResponse: props.onFetch,
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const getLinkedAccountFieldMappings = (props: {
  linkedAccountID: string;
  onFetch: (response: FieldMappingOptionsResponse) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/linked-account/${props.linkedAccountID}/field-mapping-options`,
    method: HTTPMethod.GET,
    onResponse: props.onFetch,
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const getAvailableIntegrationsForFieldMapping = ({
  fieldMappingTargetId,
  onResponse,
  onError,
}: {
  fieldMappingTargetId: string;
  onResponse: (response: { integrations: AvailableFieldMappingIntegration[] }) => void;
  onError: (error: any) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-targets/${fieldMappingTargetId}/available-integrations`,
    method: HTTPMethod.GET,
    onResponse,
    onError,
  });
};

export const createOverriddenCommonModelInstance = (
  body: CreateOverriddenCommonModelInstanceDict,
  onSuccess: (data: OverriddenCommonModelInstance) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `/integrations/linked-account/common-model-override-instances/create`,
    method: HTTPMethod.POST,
    body,
    onResponse: (data) => {
      onSuccess(data);
    },
    onError: onError,
  });
};

export const deleteOverriddenCommonModelInstance = (
  overriddenCommonModelID: string,
  onSuccess: () => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/common-model-override-instances/${overriddenCommonModelID}`,
    method: HTTPMethod.DELETE,
    onResponse: () => {
      onSuccess();
    },
    onError,
  });
};

export const editOverriddenCommoModelInstance = (
  overriddenCommonModelID: string,
  body: EditOverriddenCommonModelInstanceDict,
  onSuccess: (data: OverriddenCommonModelInstance) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/common-model-override-instances/${overriddenCommonModelID}`,
    body,
    method: HTTPMethod.PATCH,
    onResponse: (data) => {
      onSuccess(data);
    },
    onError,
  });
};

// get avaialble override fields per cateogry
export const getAllOverrideModelAndFieldOptionsForCategory = (
  category: string,
  onSucess: (data: OverrideModelAndFieldOptions) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/available-common-model-overrides/${category}`,
    method: HTTPMethod.GET,
    onResponse: (data) => {
      onSucess(data);
    },
    onError,
  });
};

export const getAllOrganizationCommonModelOverrideTargetsForCategory = (
  category: string,
  onSuccess: (data: OverriddenCommonModelTarget[]) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/organization/override-common-model-targets/${category}`,
    method: HTTPMethod.GET,
    onResponse: (data) => {
      onSuccess(data);
    },
    onError,
  });
};
export const createOrgWideCommonModelOverrideTarget = (
  body: CreateOrgWideCommonModelOverrideTargetDict,
  onSuccess: (data: OverriddenCommonModelTarget) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/organization/override-common-model-targets`,
    body,
    method: HTTPMethod.POST,
    onResponse: (data) => {
      onSuccess(data);
    },
    onError,
  });
};

export const getCommonModelOverrideIntegrationInformation = (
  overrideCommonModelTargetId: string,
  onSuccess: (data: OverrideCommonModelIntegrationInfo[]) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/organization/override-common-model-targets/${overrideCommonModelTargetId}/available-integrations`,
    method: HTTPMethod.GET,
    onResponse: (data) => {
      onSuccess(data);
    },
    onError,
  });
};

export const getCommonModelOverrideTarget = (
  overrideCommonModelTargetId: string,
  onSuccess: (data: OverriddenCommonModelTarget) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/organization/override-common-model-targets/${overrideCommonModelTargetId}`,
    method: HTTPMethod.GET,
    onResponse: (data) => {
      onSuccess(data);
    },
    onError,
  });
};

export const createFieldMappingOptions = (
  fieldMappingOptions: FieldMappingOptions,
  commonModel: any,
) => {
  const options: FieldMappingCreationAndEditDict[] = [];
  if (Object.keys(fieldMappingOptions).includes(commonModel) && fieldMappingOptions[commonModel]) {
    Object.keys(fieldMappingOptions[commonModel])
      .sort()
      .forEach((apiEndpointId) => {
        Object.keys(fieldMappingOptions[commonModel][apiEndpointId]).forEach((traversalPath) => {
          options.push({
            ...fieldMappingOptions[commonModel][apiEndpointId][traversalPath],
            key: `${fieldMappingOptions[commonModel][apiEndpointId][traversalPath].display_name}-${
              fieldMappingOptions[commonModel][apiEndpointId][traversalPath].api_endpoint_id
            }-${Math.floor(Math.random() * 10000)}`,
          });
        });
      });
  }
  return options;
};

export const createFieldMappingOptionsForAllModels = (
  fieldMappingOptions: FieldMappingOptions,
  fieldMappingConfigurations: Array<FieldMappingCommonModelConfiguration>,
) => {
  const options: FieldMappingCreationAndEditDict[] = [];

  const commonModelsWithRemoteData = fieldMappingConfigurations.reduce(
    (commonModelsWithRemoteData: string[], config: FieldMappingCommonModelConfiguration) => {
      if (config?.has_remote_data_enabled) {
        commonModelsWithRemoteData.push(config?.common_model_name);
      }
      return commonModelsWithRemoteData;
    },
    [],
  );

  Object.keys(fieldMappingOptions).forEach((commonModel) => {
    if (commonModelsWithRemoteData.includes(commonModel)) {
      Object.keys(fieldMappingOptions[commonModel])
        .sort()
        .forEach((apiEndpointId) => {
          Object.keys(fieldMappingOptions[commonModel][apiEndpointId]).forEach((traversalPath) => {
            options.push({
              ...fieldMappingOptions[commonModel][apiEndpointId][traversalPath],
              commonModelName: commonModel,
              key: `${
                fieldMappingOptions[commonModel][apiEndpointId][traversalPath].display_name
              }-${
                fieldMappingOptions[commonModel][apiEndpointId][traversalPath].api_endpoint_id
              }-${Math.floor(Math.random() * 10000)}`,
            });
          });
        });
    }
  });

  return options;
};

// ---------------- Legacy Methods ----------------

export const deleteCommonModelOverrideTarget = (
  overrideCommonModelTargetId: string,
  onSuccess: () => void,
  onFailure: () => void,
) => {
  fetchWithAuth({
    path: `integrations/organization/override-common-model-targets/${overrideCommonModelTargetId}`,
    method: HTTPMethod.DELETE,
    onResponse: () => {
      onSuccess();
    },
    onError: () => {
      onFailure();
    },
  });
};

export const addNewFieldMappingTargetForOrg = ({
  body,
  category,
  onSuccess,
  onError,
}: {
  body: {
    common_model_id: string;
    field_key?: string;
    field_description: string | null;
  };
  category: APICategory | string;
  onSuccess: (data: FieldMappingTarget) => void;
  onError: (errorResponse: Response | undefined) => void;
}) =>
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-targets/${category}`,
    method: HTTPMethod.POST,
    body,
    onResponse: (data) => {
      onSuccess(data);
    },
    onError,
  });

export const getFieldMappingPreviewValues = ({
  linkedAccountID,
  commonModelName,
  commonModelObjectID,
  onSuccess,
  onError,
}: {
  linkedAccountID: string;
  commonModelName: string;
  commonModelObjectID: string;
  onSuccess: (data: FieldMappingPreview) => void;
  onError: () => void;
}) => {
  fetchWithAuth({
    path: `integrations/linked-account/${linkedAccountID}/field-mappings/${commonModelName}/${commonModelObjectID}/preview`,
    method: HTTPMethod.GET,
    onResponse: (data) => {
      onSuccess(data);
    },
    onError,
  });
};

const _filterFieldMappingOptionsForDateTimeFormat = (
  fieldMappingOptions: FieldMappingCreationAndEditDict[],
) => {
  return fieldMappingOptions.filter((fieldMappingOptions) => {
    const fieldFormat = get(fieldMappingOptions, "format");
    return fieldFormat == "date" || fieldFormat == "date-time";
  });
};

export const filterFieldMappingOptions = (
  selectedModel: string,
  fieldMappingOptions: FieldMappingOptions,
  selectedFieldType: string | null,
  isDateField: boolean,
) => {
  if (!selectedFieldType) {
    return [];
  }
  const fieldMappingDropdownOptions = createFieldMappingOptions(fieldMappingOptions, selectedModel);

  const filteredForTypeOptions = fieldMappingDropdownOptions.filter((fieldMappingOption) => {
    return (
      fieldMappingOption.type.toLowerCase() == selectedFieldType.toLowerCase() ||
      fieldMappingOption.type.toLowerCase() == "unknown" ||
      fieldMappingOption.type.toLowerCase() == "dict" ||
      fieldMappingOption.type.toLowerCase().includes("list") ||
      (fieldMappingOption.type.toLowerCase() == "number" &&
        selectedFieldType.toLowerCase() == "integer") ||
      (fieldMappingOption.type.toLowerCase() == "string" &&
        selectedFieldType.toLowerCase() == "enum")
    );
  });

  if (isDateField) {
    return _filterFieldMappingOptionsForDateTimeFormat(filteredForTypeOptions);
  }
  return filteredForTypeOptions;
};
