import React from "react";
import MergeMark from "../../../../../../assets/img/mark-borderless.png";

export const MergeMarkAvatar = () => {
  return (
    <div className="flex flex-shrink-0">
      <img src={MergeMark} width="48" height="48" alt="Merge logo" />
    </div>
  );
};

export default MergeMarkAvatar;
