import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { fetchCurrentUser, fetchWithAuth } from "../../../../api-client/APIClient";
import { LinkedAccount } from "../../../../models/Entities";
import { SectionHeaderWrapper, TitleHeading } from "../../../shared/MergeLayouts";
import { showSuccessToast } from "../../../shared/Toasts";
import LinkedAccountTestAccountConversionModal from "./LinkedAccountTestAccountConversionModal";
import { Alert, Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import useAppContext from "../../../context/useAppContext";

type Props = {
  linkedAccount: LinkedAccount;
  updateLinkedAccount: (account: LinkedAccount) => void;
  guardrailsEnforced: boolean;
};

const LinkedAccountTypeConvertButton = ({
  linkedAccount,
  updateLinkedAccount,
  guardrailsEnforced,
}: Props) => {
  const [showConversionModal, setShowConversionModal] = useState(false);
  const { setUser } = useAppContext();

  const convertToProduction = () =>
    fetchWithAuth({
      path: `/integrations/linked-accounts/${linkedAccount.id}/update/test`,
      method: "PATCH",
      body: {
        is_test_account: false,
      },
      onResponse: () => {
        updateLinkedAccount(linkedAccount);
        showSuccessToast(`Converted to production Linked Account!`);
        fetchCurrentUser(setUser);
      },
    });

  return (
    <Card className="mb-6 px-6 pb-6" style={{ paddingTop: "16px" }}>
      <SectionHeaderWrapper
        title="Convert to production Linked Account"
        titleHeading={TitleHeading.H2}
        style={{ marginBottom: "-8px" }}
      >
        <>
          <p>
            Production Linked Accounts count towards billing and sync regularly on an interval
            wheareas test Linked Accounts allow for easier development but can only be synced
            manually through this dashboard.
          </p>
          <p className="mt-3 mb-6">Conversion is irreversible!</p>

          {guardrailsEnforced && (
            <Alert color="red" showWarningIcon className="mb-8">
              Please upgrade your plan to continue adding production Linked Accounts
            </Alert>
          )}
          <Button
            disabled={guardrailsEnforced}
            variant={ButtonVariant.DangerFilled}
            size="md"
            onClick={() => setShowConversionModal(true)}
          >
            Convert to production Linked Account
          </Button>

          <LinkedAccountTestAccountConversionModal
            isModalOpen={showConversionModal}
            convertToProduction={convertToProduction}
            closeModal={() => setShowConversionModal(false)}
          />
        </>
      </SectionHeaderWrapper>
    </Card>
  );
};

export default LinkedAccountTypeConvertButton;
