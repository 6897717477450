import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";

type FieldMappingCardRemoteFieldsSubtitleProps = {
  exampleValue?: string | null;
  jmesPath?: string | null;
  remoteEndpointMethod: string | null;
  remoteEndpointPath: string;
};
const FieldMappingCardRemoteFieldsSubtitle = ({
  exampleValue,
  jmesPath,
  remoteEndpointMethod,
  remoteEndpointPath,
}: FieldMappingCardRemoteFieldsSubtitleProps) => {
  return (
    <Text
      variant="sm"
      className="flex flex-col w-full gap-y-2 whitespace-normal break-all text-wrap text-gray-60"
    >
      {exampleValue ? (
        <>Value: {JSON.stringify(exampleValue)}</>
      ) : (
        jmesPath && <>Advanced: {jmesPath}</>
      )}
      <span>
        <span className="mr-1 font-semibold">{remoteEndpointMethod}</span>
        {remoteEndpointPath ?? "/"}
      </span>
    </Text>
  );
};

export default FieldMappingCardRemoteFieldsSubtitle;
