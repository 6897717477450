import React from "react";
import useAppContext from "../../../context/useAppContext";
import EmptyStateWrapper from "../../../shared/EmptyStateWrapper";
import { isBillingPlanFreeLaunch } from "../../settings/billing/BillingUtils";

/**
 * Creates a row with an empty state in it for any empty state
 */
const EmptyState = () => {
  const { user, isUserPrivileged } = useAppContext();

  return (
    <tr>
      <td colSpan={10} className="py-9">
        <EmptyStateWrapper
          title={
            isUserPrivileged
              ? "No recent activity"
              : "You must be an admin or developer to view activity"
          }
          subtitle={
            isUserPrivileged
              ? `Logs are retained for ${
                  isBillingPlanFreeLaunch(user.organization.organization_billing_plan?.billing_plan)
                    ? 3
                    : 7
                } days`
              : undefined
          }
        />
      </td>
    </tr>
  );
};

export default EmptyState;
