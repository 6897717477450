import React, { useEffect } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import styled from "styled-components";
import LiveWaitingIndicator from "./components/LiveWaitingIndicator";
import DashboardOnboardingSteps from "./DashboardOnboardingSteps";
import OnboardingHeader from "./OnboardingHeader";
import {
  Link,
  ArrowLeftRight,
  Code,
  Boxes,
  RefreshCw,
  ArrowUp,
  Webhook,
  Codesandbox,
  Lock,
  Calculator,
} from "lucide-react";
import { HeaderPretitle } from "../../shared/MergeText";
import {
  ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH,
  ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH,
  ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH,
  ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH,
  ONBOARDING_ADVANCED_SYNCING_DATA_PATH,
  ONBOARDING_ADVANCED_MERGE_WRITES_PATH,
  ONBOARDING_ADVANCED_WEBHOOKS_PATH,
  ONBOARDING_ADVANCED_METADATA_PATH,
  ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH,
  navigateToAddLinkToApp,
  navigateToIntegrateSyncedData,
  navigateToSendAPIRequest,
  navigateToSyncSandboxData,
  navigateToOnboardingSyncingData,
  navigateToOnboardingMergeWrites,
  navigateToOnboardingWebhooks,
  navigateToOnboardingMetadata,
  navigateToOnboardingSupplementalData,
  ONBOARDING_GET_STARTED_TOGGLE_SCOPES_PATH,
  navigateToToggleScopes,
} from "../../../router/RouterUtils";
import { useHistory, useLocation } from "react-router-dom";
import DashboardNavbarDropdown from "./DashboardNavbarDropdown";
import MergeFlagWrapper from "../../shared/MergeFlagWrapper";
import { MergeFlagFeature, useMergeFlag } from "../../shared/hooks/useMergeFlag";
import useAppContext from "../../context/useAppContext";

type Props = {
  dashboardOnboardingStatus: DashboardOnboardingStatus | null;
  children: JSX.Element | JSX.Element[];
};

export interface DashboardOnboardingStatus {
  has_toggled_scopes: boolean; // step 1
  has_successfully_linked_account: boolean; // step 2
  has_pulled_common_model_data: boolean; // step 3
  has_made_request: boolean; // step 4
  has_opened_production_link: boolean; // step 5
}

/*
 ** This is the container that will hold the full-sized navigation buttons
 *  The media query is set up so navigation bar is displayed until 1330px, and will disappear at 1329px
 *  1330px is the minimum screen width before the buttons distort when being resized - if we want to lower this in the future we'll need a new design for the buttons
 *  The 'StyledDropdownContainer' will appear at 1329 px to replace it
 */

const VerticalOnboardingNav = styled.div`
  display: none;

  @media (min-width: 1330px) {
    display: block;
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
    align-self: flex-start;
    z-index: 1020;
    margin-right: 32px;
  }
`;

/*
 ** This is the container that will hold the drop-down button
 *  The media query is set up so the dropdown appears at 1329px, when the navigation bar (VerticalOnboardingNav) disappears
 */

const StyledDropdownContainer = styled.div`
  display: none;
  margin-bottom: 32px;

  @media (max-width: 1329px) {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const Container = styled.div`
  padding-top: 40px;
  min-height: 100vh;
`;

/*
 * This StyledCol replicates the max-width settings we have enabled on our log and issues pages
 */
const StyledCol = styled(Col)`
&.col-xl-8 {
  @media (min-width: 1200px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: 1400px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 1600px;
  }
`;

const StyledHeaderPreTitle = styled(HeaderPretitle)`
  margin-bottom: 12px;
`;

const IssuesFooter = styled.p`
  margin-top: 32px;
  color: var(--gray60);
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`;

const DashboardOnboardingWrapper = ({ dashboardOnboardingStatus, children }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const stepPathName = location.pathname;
  const { user } = useAppContext();

  const { enabled: isDefaultOffScopesEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_DEFAULT_OFF_SCOPES,
    organizationId: user.organization.id,
  });

  const dashboardOnboardingStatuses = [
    dashboardOnboardingStatus?.has_made_request,
    dashboardOnboardingStatus?.has_successfully_linked_account,
    dashboardOnboardingStatus?.has_pulled_common_model_data,
    dashboardOnboardingStatus?.has_opened_production_link,
  ];

  if (isDefaultOffScopesEnabled) {
    dashboardOnboardingStatuses.push(dashboardOnboardingStatus?.has_toggled_scopes);
  }

  const onboardingStepCount = dashboardOnboardingStatuses.filter((value) => value).length;

  /*
   ** This is to scroll back to the top of page
   */

  const header = document.getElementById("scroll-to-row");

  useEffect(() => {
    header?.scrollIntoView({ behavior: "smooth" });
  }, [stepPathName]);

  return (
    <Row className="justify-content-center">
      <StyledCol className="col-12 col-lg-10 col-xl-8">
        <Container className="d-flex">
          <VerticalOnboardingNav>
            <StyledHeaderPreTitle>Get started</StyledHeaderPreTitle>
            <MergeFlagWrapper
              feature={MergeFlagFeature.MERGE_FLAG_DEFAULT_OFF_SCOPES}
              organizationId={user.organization.id}
            >
              <DashboardOnboardingSteps
                stepTitle="Data access"
                icon={<Lock size={16} />}
                lightIndicator={
                  dashboardOnboardingStatus?.has_toggled_scopes ? (
                    <LiveWaitingIndicator color="green" />
                  ) : (
                    <LiveWaitingIndicator color="red" />
                  )
                }
                isSelected={location.pathname === ONBOARDING_GET_STARTED_TOGGLE_SCOPES_PATH}
                onClick={() => {
                  navigateToToggleScopes(history);
                }}
              />
            </MergeFlagWrapper>
            <DashboardOnboardingSteps
              stepTitle="Linked Account"
              icon={<Link size={16} />}
              lightIndicator={
                dashboardOnboardingStatus?.has_successfully_linked_account ? (
                  <LiveWaitingIndicator color="green" />
                ) : (
                  <LiveWaitingIndicator color="red" />
                )
              }
              isSelected={location.pathname === ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH}
              onClick={() => {
                navigateToSyncSandboxData(history);
              }}
            />
            <DashboardOnboardingSteps
              stepTitle="Try Unified API"
              icon={<ArrowLeftRight size={16} />}
              lightIndicator={
                dashboardOnboardingStatus?.has_pulled_common_model_data ? (
                  <LiveWaitingIndicator color="green" />
                ) : (
                  <LiveWaitingIndicator color="red" />
                )
              }
              isSelected={location.pathname === ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH}
              onClick={() => {
                navigateToSendAPIRequest(history);
              }}
            />
            <DashboardOnboardingSteps
              stepTitle="Connect Unified API"
              icon={<Code size={16} />}
              lightIndicator={
                dashboardOnboardingStatus?.has_made_request ? (
                  <LiveWaitingIndicator color="green" />
                ) : (
                  <LiveWaitingIndicator color="red" />
                )
              }
              isSelected={location.pathname === ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH}
              onClick={() => {
                navigateToIntegrateSyncedData(history);
              }}
            />
            <DashboardOnboardingSteps
              stepTitle="Merge Link"
              icon={<Calculator size={16} />}
              lightIndicator={
                dashboardOnboardingStatus?.has_opened_production_link ? (
                  <LiveWaitingIndicator color="green" />
                ) : (
                  <LiveWaitingIndicator color="red" />
                )
              }
              isSelected={location.pathname === ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH}
              onClick={() => {
                navigateToAddLinkToApp(history);
              }}
            />
            <StyledHeaderPreTitle className="mt-9">Advanced</StyledHeaderPreTitle>
            <DashboardOnboardingSteps
              stepTitle="Syncing data"
              icon={<RefreshCw size={16} />}
              isSelected={location.pathname === ONBOARDING_ADVANCED_SYNCING_DATA_PATH}
              onClick={() => {
                navigateToOnboardingSyncingData(history);
              }}
            />
            <DashboardOnboardingSteps
              stepTitle="Writing to Merge"
              icon={<ArrowUp size={16} />}
              isSelected={location.pathname === ONBOARDING_ADVANCED_MERGE_WRITES_PATH}
              onClick={() => {
                navigateToOnboardingMergeWrites(history);
              }}
            />
            <DashboardOnboardingSteps
              stepTitle="Webhooks"
              icon={<Webhook size={16} />}
              isSelected={location.pathname === ONBOARDING_ADVANCED_WEBHOOKS_PATH}
              onClick={() => {
                navigateToOnboardingWebhooks(history);
              }}
            />
            <DashboardOnboardingSteps
              stepTitle="Metadata"
              icon={<Codesandbox size={16} />}
              isSelected={location.pathname === ONBOARDING_ADVANCED_METADATA_PATH}
              onClick={() => {
                navigateToOnboardingMetadata(history);
              }}
            />
            <DashboardOnboardingSteps
              stepTitle="Supplemental data"
              icon={<Boxes size={16} />}
              isSelected={location.pathname === ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH}
              onClick={() => {
                navigateToOnboardingSupplementalData(history);
              }}
            />
            <IssuesFooter>
              Issues? Email <a href="mailto:support@merge.dev">support@merge.dev</a>!
            </IssuesFooter>
          </VerticalOnboardingNav>
          <div className="flex-grow-1 flex flex-column justify-content-center self-start">
            {[
              ONBOARDING_GET_STARTED_TOGGLE_SCOPES_PATH,
              ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH,
              ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH,
              ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH,
              ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH,
            ].includes(location.pathname) ? (
              <OnboardingHeader
                title="Get started"
                subtitle="Rapidly add integrations to your product with our guides"
                className="px-0"
                badge={
                  isDefaultOffScopesEnabled
                    ? `${onboardingStepCount} / 5 complete`
                    : `${onboardingStepCount} / 4 complete`
                }
              />
            ) : (
              <OnboardingHeader
                title="Advanced"
                subtitle={
                  "Expand your product's integration capabilities with Merge's advanced features"
                }
                className="px-0"
              />
            )}
            <StyledDropdownContainer>
              <DashboardNavbarDropdown
                title={
                  <>
                    {stepPathName === ONBOARDING_GET_STARTED_TOGGLE_SCOPES_PATH &&
                      isDefaultOffScopesEnabled && (
                        <>
                          <TextContainer>
                            <Link className="mr-3" size={16} />
                            Manage data access
                            <div className="ml-3 mt-2">
                              {dashboardOnboardingStatus?.has_toggled_scopes ? (
                                <LiveWaitingIndicator color="green" />
                              ) : (
                                <LiveWaitingIndicator color="red" />
                              )}
                            </div>
                          </TextContainer>
                        </>
                      )}

                    {stepPathName === ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH && (
                      <>
                        <TextContainer>
                          <Link className="mr-3" size={16} />
                          Sync sandbox data
                          <div className="ml-3 mt-2">
                            {dashboardOnboardingStatus?.has_successfully_linked_account ? (
                              <LiveWaitingIndicator color="green" />
                            ) : (
                              <LiveWaitingIndicator color="red" />
                            )}
                          </div>
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH && (
                      <>
                        <TextContainer>
                          <ArrowLeftRight className="mr-3" size={16} />
                          Send API request
                          <div className="ml-3 mt-2">
                            {dashboardOnboardingStatus?.has_pulled_common_model_data ? (
                              <LiveWaitingIndicator color="green" />
                            ) : (
                              <LiveWaitingIndicator color="red" />
                            )}
                          </div>
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH && (
                      <>
                        <TextContainer>
                          <Code className="mr-3" size={16} />
                          Integrate synced data
                          <div className="ml-3 mt-2">
                            {dashboardOnboardingStatus?.has_made_request ? (
                              <LiveWaitingIndicator color="green" />
                            ) : (
                              <LiveWaitingIndicator color="red" />
                            )}
                          </div>
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH && (
                      <>
                        <TextContainer>
                          <Calculator size={16} className="mr-3" />
                          Add Merge Link to your application
                          <div className="ml-3 mt-2">
                            {dashboardOnboardingStatus?.has_opened_production_link ? (
                              <LiveWaitingIndicator color="green" />
                            ) : (
                              <LiveWaitingIndicator color="red" />
                            )}
                          </div>
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_ADVANCED_SYNCING_DATA_PATH && (
                      <>
                        <TextContainer>
                          <RefreshCw size={16} className="mr-3" />
                          Syncing data
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_ADVANCED_MERGE_WRITES_PATH && (
                      <>
                        <TextContainer>
                          <ArrowUp size={16} className="mr-3" />
                          Writing to Merge
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_ADVANCED_WEBHOOKS_PATH && (
                      <>
                        <TextContainer>
                          <Webhook size={16} className="mr-3" />
                          Webhooks
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_ADVANCED_METADATA_PATH && (
                      <>
                        <TextContainer>
                          <Codesandbox size={16} className="mr-3" />
                          Metadata
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH && (
                      <>
                        <TextContainer>
                          <Boxes size={16} className="mr-3" />
                          Supplemental data
                        </TextContainer>
                      </>
                    )}
                  </>
                }
              >
                <MergeFlagWrapper
                  feature={MergeFlagFeature.MERGE_FLAG_DEFAULT_OFF_SCOPES}
                  organizationId={user.organization.id}
                >
                  <Dropdown.Item
                    key={1}
                    onClick={() => {
                      navigateToToggleScopes(history);
                    }}
                  >
                    Manage data access
                  </Dropdown.Item>
                </MergeFlagWrapper>
                <Dropdown.Item
                  key={2}
                  onClick={() => {
                    navigateToSyncSandboxData(history);
                  }}
                >
                  Sync sandbox data
                </Dropdown.Item>
                <Dropdown.Item
                  key={3}
                  onClick={() => {
                    navigateToSendAPIRequest(history);
                  }}
                >
                  Send API request
                </Dropdown.Item>
                <Dropdown.Item
                  key={4}
                  onClick={() => {
                    navigateToIntegrateSyncedData(history);
                  }}
                >
                  Integrate synced data
                </Dropdown.Item>
                <Dropdown.Item
                  key={5}
                  onClick={() => {
                    navigateToAddLinkToApp(history);
                  }}
                >
                  Add Merge Link to your application
                </Dropdown.Item>
                <Dropdown.Item
                  key={6}
                  onClick={() => {
                    navigateToOnboardingSyncingData(history);
                  }}
                >
                  Syncing data
                </Dropdown.Item>
                <Dropdown.Item
                  key={7}
                  onClick={() => {
                    navigateToOnboardingMergeWrites(history);
                  }}
                >
                  Writing to Merge
                </Dropdown.Item>
                <Dropdown.Item
                  key={8}
                  onClick={() => {
                    navigateToOnboardingWebhooks(history);
                  }}
                >
                  Webhooks
                </Dropdown.Item>
                <Dropdown.Item
                  key={9}
                  onClick={() => {
                    navigateToOnboardingMetadata(history);
                  }}
                >
                  Metadata
                </Dropdown.Item>
                <Dropdown.Item
                  key={10}
                  onClick={() => {
                    navigateToOnboardingSupplementalData(history);
                  }}
                >
                  Supplemental data
                </Dropdown.Item>
              </DashboardNavbarDropdown>
            </StyledDropdownContainer>

            {children}
          </div>
        </Container>
      </StyledCol>
    </Row>
  );
};

export default DashboardOnboardingWrapper;
