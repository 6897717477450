import React from "react";

type FieldMappingsGridContainerProps = {
  children: JSX.Element;
  hasDropdown?: boolean;
};
const FieldMappingsGridContainer = ({ children, hasDropdown }: FieldMappingsGridContainerProps) => {
  return (
    <div
      className={`w-full grid ${
        hasDropdown ? "grid-cols-[4fr_0.5fr_4fr_0.1fr]" : "grid-cols-[4fr_0.5fr_4fr]"
      }`}
    >
      {children}
    </div>
  );
};
export default FieldMappingsGridContainer;
