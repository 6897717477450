import React, { useEffect, useRef, useState } from "react";
import { Text, Button, Tooltip } from "@merge-api/merge-javascript-shared";
import {
  FileStorageSyncSelectionTypes,
  LinkedAccount,
  LinkedAccountFileStorageFilters,
} from "../../../../../../models/Entities";
import useLoadLinkedAccountFileStorageFilters from "../hooks/useLoadLinkedAccountFileStorageFilters";
import SkeletonLoader from "../../../../../shared/SkeletonLoader";
import MergeTable from "../../../../../shared/MergeTable";
import { Folder, HardDrive } from "lucide-react";

type LinkedAccountFileStorageFiltersProps = {
  linkedAccountFileStorageFilters: LinkedAccountFileStorageFilters | undefined;
};

const LinkedAccountFileStorageFiltersTable = ({
  linkedAccountFileStorageFilters,
}: LinkedAccountFileStorageFiltersProps) => {
  // hooks

  // state
  const [rowWidth, setRowWidth] = useState<number>(516);
  const tableRef = useRef<HTMLDivElement>(null);

  // event handlers
  const updateWidth = () => {
    if (tableRef.current) {
      setRowWidth(tableRef.current.getBoundingClientRect().width);
    }
  };

  function formatBytes(bytes: number) {
    if (!+bytes) return "0 Bytes";

    const k = 1000;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(0))} ${sizes[i]}`;
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    // Format the date using toLocaleString
    return date.toLocaleString("en-US", {
      month: "short", // abbreviated month name
      day: "2-digit", // two-digit day
      hour: "numeric", // numeric hour
      minute: "2-digit", // two-digit minute
      hour12: true, // 12-hour format
    });
  };

  // effects
  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [tableRef, rowWidth]);

  const skeletonRows = new Array(8).fill(null).map((_, index) => (
    <tr key={index}>
      <td>
        <SkeletonLoader width={280} height={12} borderRadius={4} />
      </td>
      <td>
        <SkeletonLoader width={106} height={12} borderRadius={4} />
      </td>
      <td>
        <SkeletonLoader width={120} height={12} borderRadius={4} />
      </td>
    </tr>
  ));

  return (
    <>
      <div ref={tableRef}>
        <MergeTable
          hasMorePaddingOnFirstElement
          hover={false}
          header={
            <>
              <th scope="col">
                <div className="font-semibold">Name</div>
              </th>
              <th scope="col font-semibold">
                <div className="font-semibold">Size</div>
              </th>
              <th scope="col font-semibold">
                <div className="font-medium">Updated</div>
              </th>
            </>
          }
          content={
            linkedAccountFileStorageFilters
              ? linkedAccountFileStorageFilters.map((filter, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className="flex flex-row items-center">
                          <div className="flex mr-3">
                            {filter.type.toLowerCase() ===
                            FileStorageSyncSelectionTypes.DRIVE.toLowerCase() ? (
                              <HardDrive height={16} width={16} color="gray" />
                            ) : (
                              <Folder height={16} width={16} color="gray" />
                            )}
                          </div>

                          <div className="flex text-base font-medium">{filter.name}</div>
                        </div>
                      </td>
                      <td>
                        {filter.size ? (
                          formatBytes(Number(filter.size))
                        ) : (
                          <div className="text-base font-medium text-gray-60">—</div>
                        )}
                      </td>
                      <td>
                        {filter.updated_at ? (
                          formatDate(filter.updated_at)
                        ) : (
                          <div className="text-base font-medium text-gray-60">—</div>
                        )}
                      </td>
                    </tr>
                  );
                })
              : skeletonRows
          }
          hasMarginBottom={false}
        />
      </div>
    </>
  );
};

export default LinkedAccountFileStorageFiltersTable;
