import React, { useState } from "react";
import NameEmailCell from "./NameEmailCell";
import RoleCell from "./RoleCell";
import TwoFactorAuthCell from "./TwoFactorAuthCell";
import { User } from "../../../../../../../models/Entities";
import useAppContext from "../../../../../../context/useAppContext";
import { fetchWithAuth } from "../../../../../../../api-client/APIClient";
import { showSuccessToast, showErrorToast } from "../../../../../../shared/Toasts";
import { Dropdown, MenuItem, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { MoreVertical } from "lucide-react";

type MemberTableRowProps = {
  user: User;
};

const MembersTableRow = ({ user }: MemberTableRowProps) => {
  // STATE
  const [member, setMember] = useState<User>(user);

  // HOOKS
  const { user: meUser, isUserPrivileged: isMeUserPrivileged, setUser } = useAppContext();

  // HANDLERS
  const resetMfaForUser = (user: User) => {
    const cloneUser: User = JSON.parse(JSON.stringify(user));
    cloneUser.is_two_factor_enabled = false;

    fetchWithAuth({
      path: `/users/totp/disable/${user.id}`,
      method: "POST",
      body: JSON.parse(JSON.stringify(user)),
      onResponse: () => {
        showSuccessToast(`Successfully reset ${user.name}'s two-factor authentication!`);
        setMember(cloneUser);
      },
    });
  };

  const setUserAccountActive = (user: User, isActive: boolean) => {
    const cloneUser = JSON.parse(JSON.stringify(user));
    cloneUser.is_active = isActive;

    fetchWithAuth({
      path: `/organizations/users/${user.id}`,
      method: "PATCH",
      body: cloneUser,
      onResponse: (data) => {
        setMember(cloneUser);
        showSuccessToast(
          `Successfully ${isActive ? "reactivated" : "disabled"} ${user.name}'s account!`,
        );

        if (user.email === meUser.email) {
          setUser(data.user);
        }
      },
      onError: () => {
        showErrorToast(
          `Failed to ${isActive ? "reactivate" : "deactivate"} ${user.name}'s account!`,
        );
      },
    });
  };

  return (
    <tr>
      <NameEmailCell user={member} />
      <RoleCell user={member} />
      <TwoFactorAuthCell user={member} />
      <td className="w-24">
        {isMeUserPrivileged && meUser.email != member.email && (
          <Dropdown
            ButtonProps={{
              children: <MoreVertical size={16} />,
              variant: ButtonVariant.IconShadowHidden,
              size: "md",
            }}
            menuPlacement="bottom-end"
          >
            {member.is_two_factor_enabled && (
              <MenuItem onClick={() => resetMfaForUser(member)}>Reset 2FA</MenuItem>
            )}
            {member.is_active ? (
              <MenuItem onClick={() => setUserAccountActive(member, false)}>
                Disable account
              </MenuItem>
            ) : (
              <MenuItem onClick={() => setUserAccountActive(member, true)}>
                Re-enable account
              </MenuItem>
            )}
          </Dropdown>
        )}
      </td>
    </tr>
  );
};

export default MembersTableRow;
