import React, { Dispatch, SetStateAction } from "react";
import {
  FieldMappingInstance,
  FieldMappingMetaResponse,
  FieldMappingTarget,
  LinkedAccount,
  FieldMappingOptionsResponse,
  FieldMappingCreationAndEditDict,
} from "../../../../../../../models/Entities";
import { FieldMappingCommonModelConfiguration } from "../../../../../../../models/Entities";

interface FieldMappingModalContextState {
  linkedAccount: LinkedAccount | null;
  setFormStep: Dispatch<SetStateAction<string>>;
  onModalClose: () => void;
  linkedAccountMappingInfo: FieldMappingMetaResponse | {};
  linkedAccountFieldMappingOptions: FieldMappingOptionsResponse | undefined;
  commonModelsForIntegration: Array<string>;
  commonModelsToMappingConfig: { [common_model_id: string]: FieldMappingCommonModelConfiguration };
  commonModelSelected: string | undefined;
  setCommonModelSelected: Dispatch<SetStateAction<string | undefined>>;
  fieldMappingTargetName: string | null;
  fieldMappingInstances: Array<FieldMappingInstance>;
  setFieldMappingTargetName: Dispatch<SetStateAction<string | null>>;
  fieldMappingTargetDescription: string | null;
  setFieldMappingTargetDescription: Dispatch<SetStateAction<string | null>>;
  fieldMappingTargets: Array<FieldMappingTarget>;
  isCommonModelOverrideFlow: boolean;
  fieldToOverride: {
    name: string;
    type: string;
    description: string;
    format: string | null;
  } | null;
  setFieldToOverride: Dispatch<
    SetStateAction<{
      name: string;
      type: string;
      description: string;
      format: string | null;
    } | null>
  >;
  remoteFieldSelected: FieldMappingCreationAndEditDict | null;
}

const FieldMappingModalContext = React.createContext<FieldMappingModalContextState>({
  linkedAccount: null,
  setFormStep: () => {},
  onModalClose: () => {},
  linkedAccountMappingInfo: {},
  commonModelsForIntegration: [],
  commonModelsToMappingConfig: {},
  commonModelSelected: undefined,
  setCommonModelSelected: () => {},
  fieldMappingTargetName: null,
  setFieldMappingTargetName: () => {},
  fieldMappingTargetDescription: null,
  setFieldMappingTargetDescription: () => {},
  fieldMappingTargets: [],
  isCommonModelOverrideFlow: false,
  fieldToOverride: null,
  setFieldToOverride: () => {},
  fieldMappingInstances: [],
  linkedAccountFieldMappingOptions: undefined,
  remoteFieldSelected: null,
});

export default FieldMappingModalContext;
