import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BookOpen, ChevronRight, Plus } from "lucide-react";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import EmptyStateWrapper from "../../../shared/EmptyStateWrapper";
import DottedOutlineTextCard from "../../../shared/DottedOutlineTextCard";
import { Webhook } from "../../../../models/Entities";
import { showSuccessToast } from "../../../shared/Toasts";
import { SectionHeaderWrapper } from "../../../shared/MergeLayouts";
import {
  DOCS_WEBHOOKS_MERGE_TO_YOU_PATH,
  DOCS_WEBHOOKS_SECURITY_PATH,
  INITIAL_AND_ANY_SYNC_GUIDE,
  navigateToAddWebhookPage,
  navigateToEditIndividualWebhookPage,
  openInNewTab,
} from "../../../../router/RouterUtils";
import { Alert, Button, Dialog, ButtonVariant, Link } from "@merge-api/merge-javascript-shared";
import { HookEvent } from "./ConfigurationAddEditWebhooksPage/enums";
import WebhookRow from "./ConfigurationAddEditWebhooksPage/components/WebhookRow";
import useAppContext from "../../../context/useAppContext";

const ConfigurationWebhookEmittersPage = () => {
  const { isUserPrivileged } = useAppContext();
  const MAX_SHOW_COLLAPSED_WEBHOOKS_COUNT = 5;
  const history = useHistory();
  const [webhooks, setWebhooks] = useState<Array<Webhook>>();
  const [webhookKey, setWebhookKey] = useState<string>("Loading...");
  const [isShowingAllWebhooks, setIsShowingAllWebhooks] = useState<boolean>(false);
  const [isSignatureBeingRegenerated, setIsSignatureBeingRegenerated] = useState<boolean>(false);
  const [isSignatureBeingRegeneratedLoading, setIsSignatureBeingRegeneratedLoading] =
    useState<boolean>(false);

  useEffect(() => {
    fetchWebhooks();
    fetchWebhookKey();
  }, []);

  const fetchWebhooks = () => {
    fetchWithAuth({
      path: "/integrations/webhooks/targets",
      method: "GET",
      onResponse: (data) => {
        setWebhooks(data);
      },
    });
  };

  const fetchWebhookKey = () => {
    fetchWithAuth({
      path: "/integrations/configuration/webhooks",
      method: "GET",
      onResponse: (data) => {
        setWebhookKey(data.webhook_key);
      },
    });
  };

  const regenerateWebhookKey = () => {
    setIsSignatureBeingRegeneratedLoading(true);
    fetchWithAuth({
      path: "/integrations/configuration/webhooks",
      method: "PATCH",
      onResponse: (data) => {
        setWebhookKey(data.webhook_key);
        setIsSignatureBeingRegenerated(false);
        setIsSignatureBeingRegeneratedLoading(false);
        showSuccessToast("Successfully regenerated webhook key!");
      },
    });
  };

  const subscribedWebhookEvents = new Set(webhooks?.map((webhook) => webhook.events).flat());
  const isUsingAnySyncWebhook = subscribedWebhookEvents.has(HookEvent.ACCOUNT_SYNCED_HOOK);
  const isUsingInitalSyncWebhook =
    subscribedWebhookEvents.has(HookEvent.ACCOUNT_SYNCED_INITIAL_HOOK) ||
    subscribedWebhookEvents.has(HookEvent.ACCOUNT_FULLY_SYNCED_INITIAL_HOOK);

  return (
    <>
      <Dialog
        open={isSignatureBeingRegenerated}
        onClose={() => setIsSignatureBeingRegenerated(false)}
        onPrimaryButtonClick={regenerateWebhookKey}
        primaryButtonLoading={isSignatureBeingRegeneratedLoading}
        primaryButtonText="Regenerate"
        onSecondaryButtonClick={() => setIsSignatureBeingRegenerated(false)}
        secondaryButtonText="Cancel"
        title="Regenerate signature?"
        variant="sm"
      >
        This will replace previous signatures and you will need to update any existing
        webhook-related request verification in your API.
      </Dialog>

      <div className="flex flex-row min-w-0">
        <div className="w-full flex flex-col min-w-0">
          <SectionHeaderWrapper
            title="Merge webhooks"
            subtitle="Trigger POST requests to a specified URL when you want to be notified of a change in your integrations"
            headerRightHandContent={
              <Button
                size="sm"
                variant={ButtonVariant.TertiaryWhite}
                onClick={() => openInNewTab(DOCS_WEBHOOKS_MERGE_TO_YOU_PATH)}
                leftIcon={<BookOpen size={12} />}
              >
                Webhook docs
              </Button>
            }
          >
            <div className="my-6">
              {(isUsingAnySyncWebhook || isUsingInitalSyncWebhook) && (
                <Alert className="" color="yellow" showWarningIcon>
                  <div>
                    Our <b>initial sync</b> and <b>any sync</b> notification webhooks are
                    deprecated. Creating new webhooks of these types is no longer supported. We
                    highly recommend using our new <b>Linked Account synced</b> webhook.{" "}
                    <a href={INITIAL_AND_ANY_SYNC_GUIDE} target="_blank" rel="noreferrer">
                      Learn more
                    </a>
                    .
                  </div>
                </Alert>
              )}
            </div>
          </SectionHeaderWrapper>

          <div className="w-full flex flex-col bg-white shadow-md rounded-[10px] mb-8 min-w-0">
            <div className="flex flex-row px-6 py-5 items-center justify-between border-b border-gray-10 min-w-0">
              <h4 className="">Webhooks</h4>
              {isUserPrivileged && (
                <Button
                  size="sm"
                  leftIcon={<Plus size={12} />}
                  onClick={() => navigateToAddWebhookPage(history)}
                >
                  Webhook
                </Button>
              )}
            </div>
            {webhooks && webhooks.length === 0 ? (
              <EmptyStateWrapper title="No webhooks" />
            ) : !webhooks ? (
              Array(5)
                .fill(null)
                .map((_, index) => <WebhookRow isLoading key={index} isLastWebhook={index === 4} />)
            ) : (
              webhooks
                .slice(
                  0,
                  isShowingAllWebhooks ? webhooks.length : MAX_SHOW_COLLAPSED_WEBHOOKS_COUNT,
                )
                .map((webhook: Webhook, index) => {
                  const isLastWebhook = webhooks.length < 5 && webhooks.length === index + 1;
                  return (
                    <WebhookRow
                      isLastWebhook={isLastWebhook}
                      isLoading={false}
                      webhook={webhook}
                      key={webhook.id}
                      onClick={() => navigateToEditIndividualWebhookPage(history, webhook.id)}
                    />
                  );
                })
            )}
            {webhooks && webhooks.length > 5 && (
              <div
                onClick={() => setIsShowingAllWebhooks(!isShowingAllWebhooks)}
                className="flex flex-row items-center font-semibold justify-center text-blue-40 hover:text-blue-60 px-6 py-4 cursor-pointer hover:bg-gray-0 rounded-b-[10px]"
              >
                {isShowingAllWebhooks ? (
                  <>View less</>
                ) : (
                  <>View more ({webhooks.length - MAX_SHOW_COLLAPSED_WEBHOOKS_COUNT})</>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row mb-10">
        <div className="w-full flex flex-col bg-white shadow-md rounded-[10px]">
          <div className="flex flex-row px-6 py-5 items-center justify-between border-b border-gray-10">
            <h4>Security signature</h4>
            <Link className="text-sm" target="_blank" href={DOCS_WEBHOOKS_SECURITY_PATH}>
              Learn about webhook security <ChevronRight size={10} />
            </Link>
          </div>
          <div className="flex flex-col px-6 py-5 text-left">
            <div className="text base mb-4">
              <b>Important:</b> Your API should verify incoming POST requests with this signature
              for authenticity
            </div>
            <DottedOutlineTextCard
              text={webhookKey}
              iconSize={16}
              onClickRegenerate={() => setIsSignatureBeingRegenerated(true)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigurationWebhookEmittersPage;
