import { useEffect, useState } from "react";

import { APIEndpointMethod, APITesterLinkedAccount } from "../../../../../models/Entities";
import {
  BLANK_POSTMAN_TABLE_ROW,
  CONTENT_TYPE_TABLE_ROW,
} from "../ApiTesterRequestCard/components/ParamsHeadersAndBody/components/PostmanTable/PostmanTable";
import { ApiTesterDirection, LinkedAccountType } from "../../enums";
import { Row } from "../ApiTesterRequestCard/components/ParamsHeadersAndBody/components/PostmanTable/components/PostmanTableRow";
import useSendAPITesterRequest, { SendAPITesterRequestState } from "./useSendAPITesterRequest";
import useProductRestrictions from "../../../../shared/hooks/useProductRestrictions";
import { APICategory, UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";
import useLoadIntegrationsDropdownOptions, {
  IntegrationDropdownOption,
} from "../../../../../hooks/useLoadIntegrationsDropdownOptions";
import { formatMockSandboxAccountToken } from "../../utils";
import { parseUrlPath } from "../utils/parseUrl";
import useDeriveActiveCategory from "./useDeriveActiveCategory";
import useLinkedAccountType from "./useLinkedAccountType";
import { useLocation } from "react-router-dom";
import { APIRequestLogEntry } from "../../../IntegrationsManagementEntities";
import { MERGE_BASE_URL } from "../hooks/useSendAPITesterRequest";
import { LOG_DIRECTIONS } from "../../../../../constants";

const YOU_TO_MERGE = ApiTesterDirection.YOU_TO_MERGE;
const MERGE_TO_THIRD_PARTY = ApiTesterDirection.MERGE_TO_THIRD_PARTY;
const LOG_INBOUND = LOG_DIRECTIONS.LOG_INBOUND;
const LOG_OUTBOUND = LOG_DIRECTIONS.LOG_OUTBOUND;

export type ApiTesterRequestState = {
  method: APIEndpointMethod;
  setMethod: React.Dispatch<React.SetStateAction<APIEndpointMethod>>;
  path: string;
  setPath: React.Dispatch<React.SetStateAction<string>>;
  params: Row[];
  setParams: React.Dispatch<React.SetStateAction<Row[]>>;
  headers: Row[];
  setHeaders: React.Dispatch<React.SetStateAction<Row[]>>;
  body: string;
  setBody: React.Dispatch<React.SetStateAction<string>>;
  url: string;
  mockSandboxCategory: APICategory;
  setMockSandboxCategory: React.Dispatch<React.SetStateAction<APICategory>>;
  category: APICategory | UnreleasedAPICategory;
  mockSandboxIntegration: IntegrationDropdownOption | undefined;
  setMockSandboxIntegration: React.Dispatch<
    React.SetStateAction<IntegrationDropdownOption | undefined>
  >;
  mockSandboxIntegrations: IntegrationDropdownOption[] | undefined;
} & SendAPITesterRequestState;

type UseApiTesterRequestState = {
  direction: ApiTesterDirection;
  linkedAccount: APITesterLinkedAccount | undefined;
};

const useApiTesterRequestState = ({
  direction,
  linkedAccount,
}: UseApiTesterRequestState): ApiTesterRequestState => {
  // state
  const [method, setMethod] = useState<APIEndpointMethod>(APIEndpointMethod.GET);
  const [path, setPath] = useState<string>("");
  const [params, setParams] = useState<Row[]>([{ ...BLANK_POSTMAN_TABLE_ROW }]);
  const [headers, setHeaders] = useState<Row[]>([
    { ...CONTENT_TYPE_TABLE_ROW },
    { ...BLANK_POSTMAN_TABLE_ROW },
  ]);
  const [body, setBody] = useState<string>("");
  const [mockSandboxCategory, setMockSandboxCategory] = useState<APICategory>(APICategory.hris);
  const [mockSandboxIntegration, setMockSandboxIntegration] = useState<
    IntegrationDropdownOption | undefined
  >();

  // hooks
  const { linkedAccountType } = useLinkedAccountType();
  const { productRestrictions } = useProductRestrictions();
  const category = useDeriveActiveCategory({
    direction,
    linkedAccountType,
    linkedAccount,
    mockSandboxCategory,
  });
  const {
    integrationsDropdownOptions: mockSandboxIntegrations,
  } = // this state is here to prevent reloading when tabs change
    useLoadIntegrationsDropdownOptions({
      category: mockSandboxCategory,
      filterDisabled: true,
      disabled: direction === ApiTesterDirection.MERGE_TO_THIRD_PARTY,
    });
  const { onSendClick, isLoading, responseBody, responseCode, responseHeaders, url } =
    useSendAPITesterRequest({
      linkedAccount,
      path,
      setPath,
      params,
      headers,
      body,
      direction,
      linkedAccountType,
      category,
      // Mock Sandbox only supports GET
      method: linkedAccountType === LinkedAccountType.MOCK_SANDBOX ? APIEndpointMethod.GET : method,
      mockSandboxAccountToken: formatMockSandboxAccountToken(mockSandboxIntegration),
    });

  const location = useLocation<APIRequestLogEntry>();

  // effects

  // set headers based on method
  useEffect(() => {
    setHeaders((prevHeaders) => {
      const newHeaders = prevHeaders.map((header) =>
        header.key === CONTENT_TYPE_TABLE_ROW.key
          ? { ...header, active: method === "POST" }
          : header,
      );
      return newHeaders;
    });
  }, [method]);

  // set include_remote_data when you_to_merge & correct productRestrictions
  useEffect(() => {
    if (
      linkedAccountType === LinkedAccountType.LINKED_ACCOUNT &&
      direction === ApiTesterDirection.YOU_TO_MERGE &&
      (productRestrictions?.are_toggles_enabled ||
        productRestrictions?.are_remote_data_toggles_enabled)
    ) {
      setParams((params) => [
        {
          key: "include_remote_data",
          active: false,
          value: "true",
        },
        ...params,
      ]);
    }
  }, [
    direction,
    linkedAccountType,
    productRestrictions?.are_remote_data_toggles_enabled,
    productRestrictions?.are_toggles_enabled,
  ]);

  // remove include_remote_data for mock sandbox
  useEffect(() => {
    if (
      linkedAccountType === LinkedAccountType.MOCK_SANDBOX &&
      direction === ApiTesterDirection.YOU_TO_MERGE
    ) {
      setParams((params) => params.filter(({ key }) => key !== "include_remote_data"));
    }
  }, [direction, productRestrictions, linkedAccountType]);

  // set initial mockSandboxIntegration
  useEffect(() => {
    if (mockSandboxIntegrations?.length) {
      setMockSandboxIntegration(mockSandboxIntegrations[0]);
    }
  }, [mockSandboxIntegrations]);

  // if linked from a log, fill out log information into api tester
  useEffect(() => {
    const logEntry = location.state;
    if (!logEntry || !linkedAccount) return;
    // don't load state for the wrong direction
    if (
      (logEntry.direction === LOG_INBOUND && direction !== YOU_TO_MERGE) ||
      (logEntry.direction === LOG_OUTBOUND && direction !== MERGE_TO_THIRD_PARTY)
    )
      return;

    // set path based on direction
    const baseURL =
      direction === ApiTesterDirection.MERGE_TO_THIRD_PARTY
        ? linkedAccount?.override_base_api_url || linkedAccount?.integration.base_api_url || ""
        : `${MERGE_BASE_URL}/api/${category}/v1`;
    setPath(parseUrlPath(baseURL, logEntry.url));
    setMethod(logEntry.method == "POST" ? APIEndpointMethod.POST : APIEndpointMethod.GET);
    setBody(logEntry.request_body || "");

    // map parameters
    const requestQueryParams = logEntry.request_query_params;
    if (requestQueryParams && Object.entries(requestQueryParams).length) {
      const params = Object.entries(requestQueryParams).map(([key, value]) => ({
        key,
        value,
        active: true,
      }));
      setParams(params);
    }
  }, [location.state, linkedAccount, direction, category]);

  return {
    // Mock Sandbox only supports GET
    method: linkedAccountType === LinkedAccountType.MOCK_SANDBOX ? APIEndpointMethod.GET : method,
    setMethod,
    path,
    setPath,
    params,
    setParams,
    headers,
    setHeaders,
    body,
    setBody,
    onSendClick,
    isLoading,
    responseBody,
    responseCode,
    responseHeaders,
    url,
    mockSandboxIntegration,
    mockSandboxIntegrations,
    setMockSandboxIntegration,
    mockSandboxCategory,
    setMockSandboxCategory,
    category,
  };
};

export default useApiTesterRequestState;
