import React, { useState } from "react";
import styled from "styled-components";
import {
  getFormattedEventDistanceFromNow,
  getIssueCreatedAt,
  getIssueUpdatedAt,
} from "../../../utils/IntegrationsManagementUtils";
import { useHistory } from "react-router-dom";
import { navigateToIndividualIssuePage } from "../../../../../router/RouterUtils";
import { snakeCaseToSentenceCase } from "../../../../../services";
import { Button, ButtonVariant, Tooltip } from "@merge-api/merge-javascript-shared";
import { MessageSquare, Check, VolumeX, ChevronRight, Volume1 } from "lucide-react";
import { updateIntegrationIssueStatus } from "../../../utils/IntegrationsManagementAPIClient";
import { IntegrationIssueStatus } from "../../../IntegrationsManagementEntities";
import { showSuccessToast, showErrorToast } from "../../../../shared/Toasts";
import { IntegrationIssueCommentModal } from "../../../issues/IntegrationIssueModals";
import { muteIntegrationIssue } from "../../../utils/IntegrationsManagementAPIClient";
import { getLastActionMessage } from "../../../issues/IntegrationsManagementIssuesPage";
import { IntegrationIssue } from "../../../IntegrationsManagementEntities";
import { isIssueMuted } from "../../../utils/IntegrationsManagementUtils";
type IssueStatusProps = {
  status: string;
};
const IssueStatusText = styled.td<IssueStatusProps>`
  color: ${(props) => (props.status === IntegrationIssueStatus.ONGOING ? "#E62837" : "#1A9B87")};
  font-weight: 500;
`;

const ClickableIssueRow = styled.tr`
  cursor: pointer;
`;
type IssuePageProps = {
  linkedAccountIssue: IntegrationIssue;
};

export const getMuteFor1DayTime = () => {
  const now = new Date();
  now.setHours(24);
  return now;
};
const IssuesPageRow = ({ linkedAccountIssue }: IssuePageProps) => {
  const history = useHistory();
  const [integrationIssue, setIntegrationIssue] = useState(linkedAccountIssue);
  const [isCommentModalShown, setIsCommentModalShown] = useState(false);

  const isMuted = isIssueMuted(integrationIssue);
  return (
    <ClickableIssueRow
      onClick={() => {
        if (!isCommentModalShown) {
          navigateToIndividualIssuePage(history, integrationIssue.id, "linked_account_page");
        }
      }}
    >
      <td colSpan={2} className="font-medium">
        {integrationIssue.error_description}
      </td>
      <IssueStatusText status={integrationIssue.status}>
        {snakeCaseToSentenceCase(integrationIssue.status)}
      </IssueStatusText>
      <td className="text-gray-60">
        {getLastActionMessage(linkedAccountIssue.last_action, linkedAccountIssue.is_muted)}
      </td>
      <td className="text-gray-60">
        {getFormattedEventDistanceFromNow(getIssueUpdatedAt(integrationIssue))}
      </td>
      <td className="text-gray-60">
        {getFormattedEventDistanceFromNow(getIssueCreatedAt(integrationIssue))}
      </td>
      <td>
        <div className="flex items-center justify-start gap-3">
          <Tooltip title="View comments">
            <Button
              size="sm"
              variant={ButtonVariant.IconOnly}
              onClick={(e) => {
                e.stopPropagation();
                setIsCommentModalShown(!isCommentModalShown);
              }}
            >
              <MessageSquare size={16} />
            </Button>
          </Tooltip>
          {integrationIssue.status === IntegrationIssueStatus.ONGOING && (
            <>
              {isMuted ? (
                <Tooltip title="Unmute ongoing issue">
                  <Button
                    size="sm"
                    variant={ButtonVariant.IconOnly}
                    onClick={(e) => {
                      e.stopPropagation();
                      muteIntegrationIssue({
                        integrationIssueID: integrationIssue.id,
                        muted_until: undefined,
                        mute_incident_count: undefined,
                        onSuccess: (updatedIntegrationIssue) => {
                          setIntegrationIssue(updatedIntegrationIssue);
                          showSuccessToast("Successfully unmuted issue");
                        },
                        onFailure: () => {
                          showErrorToast("Error occurred while muting issue");
                        },
                      });
                    }}
                  >
                    <Volume1 size={16} />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="Mute ongoing issue">
                  <Button
                    size="sm"
                    variant={ButtonVariant.IconOnly}
                    onClick={(e) => {
                      e.stopPropagation();
                      muteIntegrationIssue({
                        integrationIssueID: integrationIssue.id,
                        muted_until: getMuteFor1DayTime(),
                        onSuccess: (updatedIntegrationIssue) => {
                          setIntegrationIssue(updatedIntegrationIssue);
                          showSuccessToast("Successfully muted issue for 24 hours");
                        },
                        onFailure: () => {
                          showErrorToast("Error occurred while muting issue");
                        },
                      });
                    }}
                  >
                    <VolumeX size={16} />
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="Resolve ongoing issue">
                <Button
                  size="sm"
                  variant={ButtonVariant.IconOnly}
                  onClick={(e) => {
                    e.stopPropagation();
                    updateIntegrationIssueStatus({
                      integrationIssueID: integrationIssue.id,
                      status: IntegrationIssueStatus.RESOLVED,
                      onSuccess: (updatedIntegrationIssue) => {
                        setIntegrationIssue(updatedIntegrationIssue);
                        showSuccessToast("Updated Integration Issue");
                      },
                      onError: () => {
                        showErrorToast("Unable To Update Integration Issue");
                      },
                    });
                  }}
                >
                  <Check size={16} />
                </Button>
              </Tooltip>
            </>
          )}
          <div className="ml-auto">
            <Tooltip title="See more details">
              <div
                onClick={() => {
                  navigateToIndividualIssuePage(
                    history,
                    integrationIssue.id,
                    "linked_account_page",
                  );
                }}
              >
                <ChevronRight size={12} />
              </div>
            </Tooltip>
          </div>
        </div>
      </td>
      <IntegrationIssueCommentModal
        integrationIssue={integrationIssue}
        isShown={isCommentModalShown}
        setIsShown={setIsCommentModalShown}
      />
    </ClickableIssueRow>
  );
};
export default IssuesPageRow;
