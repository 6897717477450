import React, { useState } from "react";
import {
  Dialog,
  ButtonVariant,
  Text,
  TextField,
  Select,
  TextFieldVariant,
} from "@merge-api/merge-javascript-shared";
import { UserType } from "../../../../../models/Entities";
import useAppContext from "../../../../context/useAppContext";
import { REGEX_EMAIL_ADDRESS } from "../../../../shared/utils/SharedComponentUtils";
import { displayNameForUserType } from "../../../../../models/Helpers";
import { MERGE_USER_ROLE_HELP_CENTER } from "../../../../../router/RouterUtils";

type InviteTeamMemberModalProps = {
  isShown: boolean;
  onHide: () => void;
  onHandleSubmit: (data: { email: string; user_type: UserType }) => void;
};

const InviteTeamMemberModal = ({ isShown, onHide, onHandleSubmit }: InviteTeamMemberModalProps) => {
  // HOOKS
  const { user } = useAppContext();

  // STATE
  const [inviteUserType, setInviteUserType] = useState<UserType>(UserType.admin_without_billing);
  const [inviteUserEmail, setInviteUserEmail] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);

  // HANDLERS
  const handleInviteUserEmail = (email: string) => {
    setIsError(!REGEX_EMAIL_ADDRESS.test(email));
    setInviteUserEmail(email);
  };

  return (
    <Dialog
      open={isShown}
      onClose={onHide}
      variant="md"
      title="Invite team member"
      primaryButtonText="Send invite"
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      onPrimaryButtonClick={() =>
        onHandleSubmit({ email: inviteUserEmail, user_type: inviteUserType })
      }
      primaryButtonDisabled={isError}
    >
      <div className="flex flex-col gap-2">
        <Text variant="h6">Email address</Text>
        <TextField
          variant={TextFieldVariant.Shadowed}
          placeholder="Enter email address"
          onChange={(e) => handleInviteUserEmail(e.target.value)}
        />
        <div>
          <Text variant="h6">Role</Text>
          <Text className="text-gray-60 text-sm">
            Learn more about Merge's{" "}
            <a href={MERGE_USER_ROLE_HELP_CENTER} target="_blank" rel="noreferrer">
              user permission levels
            </a>{" "}
          </Text>
        </div>
        <Select
          clearable={false}
          options={user.allowed_invite_roles.sort((a, b) => b.localeCompare(a))} // sort so that it goes from least permissions to most permissions
          getOptionLabel={(option) => {
            return displayNameForUserType(option);
          }}
          onChange={(_, selectedOption) => {
            if (selectedOption) {
              setInviteUserType(selectedOption);
            }
          }}
          value={UserType.member}
        />
      </div>
    </Dialog>
  );
};

export default InviteTeamMemberModal;
