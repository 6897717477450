import { format } from "date-fns";
import React from "react";
import {
  DataWarehouseImportConfig,
  DataWarehouseSyncStatusType,
  LinkedAccount,
} from "../../../../../../models/Entities";
import { Badge, Text } from "@merge-api/merge-javascript-shared";
import { RefreshCw } from "lucide-react";
import IntegrationNameAndLogo from "../../../../configuration/integrations/IntegrationNameAndLogo";

export interface Props {
  linkedAccount: LinkedAccount;
  importConfig: DataWarehouseImportConfig;
}

const getSyncStatusBadge = (syncStatus: DataWarehouseSyncStatusType) => {
  switch (syncStatus) {
    case DataWarehouseSyncStatusType.SYNCING:
      return (
        <Badge color="blue">
          <div className="inline-flex items-center justify-center">
            <RefreshCw className="rotating m-0 p-0" size={10} />
            <span className="ml-1.5">Syncing</span>
          </div>
        </Badge>
      );
    case DataWarehouseSyncStatusType.COMPLETE:
      return <Badge color="teal">Done</Badge>;

    case DataWarehouseSyncStatusType.FAILED:
      return <Badge color="red">Failed</Badge>;
    default:
      return <div className="text-sm font-medium text-gray-60">—</div>;
  }
};

const DataWarehouseSyncHistoryExpandableRow = ({ linkedAccount, importConfig }: Props) => {
  const mostRecentExecutionDetails =
    importConfig.active_execution_details || importConfig.latest_execution_details;

  return (
    <>
      <tr key={importConfig.import_id}>
        <td className="pl-6">
          <IntegrationNameAndLogo
            integration={linkedAccount.integration}
            customText={importConfig.source_connection.name}
            textVariant="sm"
          />
        </td>
        <td>
          <IntegrationNameAndLogo
            integration={importConfig.destination_connection.integration}
            customText={importConfig.destination_connection.name}
            textVariant="sm"
          />
        </td>
        <td>{getSyncStatusBadge(mostRecentExecutionDetails?.status)}</td>
        <td>
          <Text variant="sm">
            {mostRecentExecutionDetails?.initiated_at ? (
              format(new Date(mostRecentExecutionDetails.initiated_at), "MMM dd, hh:mm a")
            ) : mostRecentExecutionDetails?.initiated_at ? (
              format(new Date(mostRecentExecutionDetails.initiated_at), "MMM dd, hh:mm a")
            ) : (
              <div className="text-gray-60">—</div>
            )}
          </Text>
        </td>
        <td>
          <Text variant="sm">
            {importConfig?.sync_schedule_next_run_at ? (
              format(new Date(importConfig?.sync_schedule_next_run_at), "MMM dd, hh:mm a")
            ) : (
              <div className="text-gray-60">—</div>
            )}
          </Text>
        </td>
        <td className="pr-6">
          {importConfig?.sync_schedule_next_run_at ? (
            <Badge color="blue">Periodic</Badge>
          ) : (
            <Badge color="gray">Manual</Badge>
          )}
        </td>
      </tr>
    </>
  );
};

export default DataWarehouseSyncHistoryExpandableRow;
