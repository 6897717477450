import React, { useState } from "react";
import type { Props as ConfigProps } from "../../IntegrationSettingsConfigParameterRow";
import { TextField, Text, TextFieldVariant } from "@merge-api/merge-javascript-shared";
import { snakeCaseToSpacedCapitalized } from "../../../../../../services";
import FileUpload from "./FileUpload";
import { ConfigFieldMap } from "./hooks/usePartnershipModal";
import { getSanitizedHTML } from "../../../../../../models/Helpers";
type PartnershipConfigurationFieldInputProps = {
  configField: ConfigProps["fieldConfigStatus"];
  configFieldMap: ConfigFieldMap;
  handleFieldInput: (fieldInputName: string, value: string | Blob, editting?: boolean) => void;
};

const PartnershipConfigurationFieldInput = ({
  configField,
  configFieldMap,
  handleFieldInput,
}: PartnershipConfigurationFieldInputProps) => {
  const fieldNameDisplay = snakeCaseToSpacedCapitalized(configField.field_name);
  const [isEditing, setIsEditing] = useState<boolean>(
    configFieldMap[configField.field_name].isModified,
  );
  const isCompleted = configField.completed;
  return (
    <div>
      {configField.field_type == "string" &&
        (isCompleted && !isEditing ? (
          <>
            <div className="mb-2 px-1">
              <Text variant="h6">{fieldNameDisplay}</Text>
              {configField.description && (
                <div className="text-sm text-gray-60">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getSanitizedHTML(configField.description),
                    }}
                  />
                </div>
              )}
            </div>
            <div className="flex items-center px-1">
              <div className="flex-grow-1">************</div>
              <button
                onClick={() => {
                  setIsEditing(true);
                  handleFieldInput(configField.field_name, "", true);
                }}
                className="text-blue-40 hover:cursor-pointer hover:text-blue-60 font-semibold"
              >
                Change
              </button>
            </div>
          </>
        ) : (
          <TextField
            onChange={(e) => {
              const needToSaveChanges = configField.completed == false;
              handleFieldInput(configField.field_name, e.target.value, needToSaveChanges);
            }}
            className="px-1"
            variant={TextFieldVariant.Shadowed}
            label={
              <div className="mb-2">
                <Text variant="h6">{fieldNameDisplay}</Text>
                {configField.description && (
                  <div className="text-sm text-gray-60">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getSanitizedHTML(configField.description),
                      }}
                    />
                  </div>
                )}
              </div>
            }
            value={configFieldMap[configField.field_name].value!}
            placeholder={`Enter ${fieldNameDisplay}`}
          />
        ))}
      {configField.field_type == "file" && (
        <FileUpload
          isComplete={configField.completed}
          fileHelperText={configField.description}
          fileToUpload={configField.field_name}
          filesToAccept={
            configField?.field_name.toLowerCase() == "SSL certificate file".toLowerCase()
              ? ".pem"
              : ".key,.txt"
          } // TODO: refactor so the files to accept just comes from the backend
          handleFileUpload={(fileUploaded: Blob, isEditing = false) => {
            handleFieldInput(configField.field_name, fileUploaded, isEditing);
          }}
        />
      )}
    </div>
  );
};

export default PartnershipConfigurationFieldInput;
