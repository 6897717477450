import React from "react";

type UserCellProps = {
  name?: string;
  email?: string;
};

const UserCell = ({ name, email }: UserCellProps) => {
  if (!name) {
    return <td>-</td>;
  }

  return (
    <td>
      <div className="text-base font-medium flex-wrap">{name}</div>
      <div className="text-xs text-gray-60 flex-wrap">{email}</div>
    </td>
  );
};

export default UserCell;
