import React, { useEffect, useState } from "react";
import IntegrationNameAndLogo from "../../../integrations/IntegrationNameAndLogo";
import { MoreHorizontal, Plus } from "lucide-react";
import DeleteFieldMappingModal from "../../../../linked-accounts/detail-page/field-mappings/DeleteFieldMappingModal";
import useAppContext from "../../../../../context/useAppContext";
import {
  EditFieldMappingInstanceProps,
  NewIntegrationWideFieldMapping,
  createIntegrationWideFieldMapping,
  editFieldMappingInstance,
  fetchFieldMappingInstance,
  fetchIntegrationWideFieldMappingOptions,
} from "../../../../../../api-client/APIClient";
import { showSuccessToast } from "../../../../../shared/Toasts";
import { FieldMappingCreationAndEditDict } from "../../../../../../models/Entities";
import { FieldMappingSource } from "../../../../../../constants";
import FieldMappingDropdownChild from "../../../../../shared/FieldMappingDropdownChild";
import {
  Button,
  ButtonVariant,
  Dropdown,
  MenuItem,
  Tooltip,
  Text,
  Typeahead,
  TextFieldVariant,
} from "@merge-api/merge-javascript-shared";

type FieldMappingByIntegrationRowProps = {
  fieldMappingTargetID: string;
  fieldMappingInstanceId: string | undefined;
  integrationID: string;
  name: string;
  squareImage: string | undefined;
  remoteEndpointPath: string | undefined;
  remoteEndpointMethod: string | undefined;
  originType: string | undefined;
  originField: string | undefined;
  isLinkedAccountOverrideEnabled: boolean | undefined;
  fieldMappingTargetCommonModelName: string;
  fieldMappingTargetCategory: string;
  fieldMappingTargetFieldKey: string;
  updateAvailableIntegrations?: (
    integrationID: string,
    fieldMappingInstanceId: string,
    originField: string,
    remoteEndpointPath: string,
    remoteEndpointMethod: string,
    originType: string,
    isLinkedAccountOverrideEnabled: boolean,
    traversalPath: Array<string>,
  ) => void;
  handleDelete?: () => void;
  isRemoteDataEnabled?: boolean;
};

const FieldMappingByIntegrationRow = ({
  fieldMappingTargetID,
  fieldMappingInstanceId,
  integrationID,
  name,
  squareImage,
  remoteEndpointPath,
  remoteEndpointMethod,
  originField,
  isLinkedAccountOverrideEnabled,
  fieldMappingTargetCommonModelName,
  fieldMappingTargetCategory,
  fieldMappingTargetFieldKey,
  updateAvailableIntegrations,
  handleDelete,
  isRemoteDataEnabled = true,
}: FieldMappingByIntegrationRowProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLinkedAccountOverrideEnabledState, setIsLinkedAccountOverridesEnabledState] = useState(
    isLinkedAccountOverrideEnabled,
  );
  const [fieldMappingOptions, setFieldMappingOptions] = useState<FieldMappingCreationAndEditDict[]>(
    [],
  );
  const [originFields, setOriginFields] = useState<FieldMappingCreationAndEditDict>();
  const [isAddingFieldMapping, setIsAddingFieldMapping] = useState(false);
  const { user } = useAppContext();

  useEffect(() => {
    setIsLinkedAccountOverridesEnabledState(isLinkedAccountOverrideEnabled);
  }, [isLinkedAccountOverrideEnabled]);

  const createIntegrationFieldMapping = (currOriginField: FieldMappingCreationAndEditDict) => {
    const body: NewIntegrationWideFieldMapping = {
      integration_id: integrationID,
      organization_id: user.organization.id,
      common_model_id: `${fieldMappingTargetCategory}.${fieldMappingTargetCommonModelName}`,
      field_key: fieldMappingTargetFieldKey,
      field_traversal_path: currOriginField.traversal_path.split("."),
      create_for_organization: true,
      field_mapping_target_id: fieldMappingTargetID,
      configured_by: FieldMappingSource.ORGANIZATION,
      api_endpoint_id: currOriginField.api_endpoint_id,
      display_name: currOriginField.display_name,
      enable_linked_account_level_overrides: true,
      origin_type: currOriginField.type,
    };
    createIntegrationWideFieldMapping(
      body,
      () => {
        showSuccessToast("Successfully added Field Mapping!");
      },
      (new_instance_id) => {
        fetchFieldMappingInstance(new_instance_id, (data) => {
          updateAvailableIntegrations!(
            integrationID,
            new_instance_id,
            currOriginField.display_name,
            data?.remote_endpoint_path,
            currOriginField.api_endpoint_method,
            currOriginField.type,
            isLinkedAccountOverrideEnabledState ?? false,
            currOriginField.traversal_path.split("."),
          );
          setIsAddingFieldMapping(false);
        });
      },
    );
  };

  const editIntegrationFieldMapping = (currOriginField: FieldMappingCreationAndEditDict) => {
    if (fieldMappingInstanceId) {
      const body: EditFieldMappingInstanceProps = {
        field_mapping_instance_id: fieldMappingInstanceId,
        field_traversal_path: currOriginField.traversal_path.split("."),
        api_endpoint_id: currOriginField.api_endpoint_id,
        display_name: currOriginField.display_name,
        enable_linked_account_level_overrides: true,
        origin_type: currOriginField.type,
      };
      editFieldMappingInstance(fieldMappingInstanceId, body, () => {
        showSuccessToast("Successfully edited Field Mapping!");
        fetchFieldMappingInstance(fieldMappingInstanceId, (data) => {
          updateAvailableIntegrations!(
            integrationID,
            fieldMappingInstanceId,
            currOriginField.display_name,
            data?.remote_endpoint_path,
            currOriginField.api_endpoint_method,
            currOriginField.type,
            isLinkedAccountOverrideEnabledState ?? false,
            currOriginField.traversal_path.split("."),
          );
          setIsAddingFieldMapping(false);
        });
      });
    }
  };

  useEffect(() => {
    const optionallySelected = originField
      ? fieldMappingOptions?.find((option) => option.display_name === originField)
      : null;
    if (optionallySelected != null) {
      setOriginFields(optionallySelected);
    }
  }, [fieldMappingOptions]);

  const fieldMappingOptionsLoading = fieldMappingOptions === undefined;

  return (
    <tr>
      <td className="pl-6">
        <IntegrationNameAndLogo
          integration={{
            id: name,
            name: name,
            square_image: squareImage,
          }}
        />
      </td>
      <td colSpan={isAddingFieldMapping ? 2 : 1} className="items-center py-0">
        {isAddingFieldMapping ? (
          <Typeahead
            className="w-100"
            loading={fieldMappingOptionsLoading}
            borderVariant={TextFieldVariant.Bordered}
            value={originFields}
            options={fieldMappingOptions}
            onChange={(_, selectedField: any) => {
              if (selectedField) {
                if (originField != null) {
                  // edit field
                  editIntegrationFieldMapping(selectedField);
                } else {
                  // create field
                  createIntegrationFieldMapping(selectedField);
                }
              } else {
                setIsAddingFieldMapping(false);
                setOriginFields(selectedField);
              }
            }}
            getOptionLabel={(option: FieldMappingCreationAndEditDict) => option.display_name}
            renderOption={(option: FieldMappingCreationAndEditDict) => (
              <FieldMappingDropdownChild option={option} isIntegrationWide />
            )}
            placeholder="Search fields..."
          />
        ) : originField ? (
          <Tooltip title={originField}>
            <div className="inline-block align-middle truncate w-40 font-medium text-base">
              {originField}
            </div>
          </Tooltip>
        ) : isRemoteDataEnabled ? (
          <Button
            variant={ButtonVariant.SecondaryBlue}
            size="sm"
            leftIcon={<Plus size={12} />}
            onClick={() => {
              fetchIntegrationWideFieldMappingOptions(
                integrationID,
                fieldMappingTargetID,
                fieldMappingTargetCommonModelName,
                setFieldMappingOptions,
              );
              setIsAddingFieldMapping(true);
            }}
          >
            Mapping
          </Button>
        ) : (
          <div className="text-gray-60">—</div>
        )}
      </td>
      {!isAddingFieldMapping && (
        <td width={278} className="text-gray-60">
          {remoteEndpointPath ? (
            <Tooltip title={remoteEndpointPath}>
              <div className="flex w-fit items-center">
                <Text variant="title-sm" className="mr-1.5">
                  {remoteEndpointMethod ?? "GET"}
                </Text>
                <div className="inline-block align-middle truncate w-56 font-medium text-sm">
                  {remoteEndpointPath}
                </div>
              </div>
            </Tooltip>
          ) : (
            <>—</>
          )}
        </td>
      )}

      {originField ? (
        <td className="py-0 pr-6">
          <DeleteFieldMappingModal
            showModal={showDeleteModal}
            fieldMappingID={fieldMappingInstanceId!}
            headerText="Delete mapping"
            onHide={() => {
              setShowDeleteModal(false);
            }}
            handlePostDelete={handleDelete}
            text={"This will delete all Field Mappings to this " + name + " origin field."}
            secondaryText="Mapping overrides will not be deleted."
          />
          <div className="float-right">
            <Dropdown
              ButtonProps={{
                children: <MoreHorizontal size={16} />,
                size: "sm",
                variant: ButtonVariant.IconShadowHidden,
              }}
              menuPlacement="bottom-end"
            >
              <MenuItem
                onClick={() => {
                  fetchIntegrationWideFieldMappingOptions(
                    integrationID,
                    fieldMappingTargetID,
                    fieldMappingTargetCommonModelName,
                    setFieldMappingOptions,
                  );
                  setIsAddingFieldMapping(true);
                }}
              >
                Edit mapping
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setShowDeleteModal(true);
                }}
              >
                Delete mapping
              </MenuItem>
            </Dropdown>
          </div>
        </td>
      ) : (
        <td></td>
      )}
    </tr>
  );
};

export default FieldMappingByIntegrationRow;
