import React from "react";
import EmptyStateWrapper from "../../../../../shared/EmptyStateWrapper";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { X } from "lucide-react";

export const LogSidePanelNotFound = ({ onClickExit }: { onClickExit: () => void }) => (
  <>
    <div className="p-6 flex justify-start w-full items-center">
      <Button size="sm" variant={ButtonVariant.IconShadowHidden} onClick={() => onClickExit()}>
        <X size={20} />
      </Button>
    </div>
    <div className="flex h-full justify-center mt-10">
      <EmptyStateWrapper title="Log not found" />
    </div>
  </>
);
