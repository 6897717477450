import React from "react";
import clsx from "clsx";
import MergeCodeBlock from "../../../../../shared/MergeCodeBlock";
import { Alert, Text } from "@merge-api/merge-javascript-shared";
import useApiTesterDirection from "../../hooks/useApiTesterDirection";
import { ApiTesterDirection } from "../../../enums";

// estimate on number of characters in 7500 lines of code according to chat gpt. Basically just a very high number
const MAX_RESPONSE_BODY_LENGTH = 562500;

type ResponseBodySectionProps = {
  className?: string;
  responseBody?: string;
  hasMadeInitialRequest: boolean;
  isLoading?: boolean;
  responseCode?: number;
};

const ResponseBodySection = ({
  className,
  responseBody,
  hasMadeInitialRequest,
  isLoading,
  responseCode,
}: ResponseBodySectionProps) => {
  //
  const direction = useApiTesterDirection();

  // check to see if response body is too long (which can lead to code block crashing) and truncate
  const truncateResponseBody = (responseBody: string): string => {
    // this is only for merge to third party. It is not expected that a call to our API would ever exceed the max character limit, but just in case.
    if (direction === ApiTesterDirection.YOU_TO_MERGE) {
      return responseBody;
    }

    if (responseBody.length > MAX_RESPONSE_BODY_LENGTH) {
      responseBody = responseBody.slice(0, MAX_RESPONSE_BODY_LENGTH); // Cut the responseBody at 500,000 characters
      responseBody +=
        "... \nResponse size exceeded, please make a direct request to the third-party using passthrough.";
    }

    return responseBody;
  };

  return (
    <div className={clsx("h-full", className)}>
      <Text variant="h5" className="mb-5">
        Body
      </Text>
      {isLoading ? (
        <div className="flex justify-center items-center h-48">
          <Text variant="lg" className="text-gray-60">
            Loading...
          </Text>
        </div>
      ) : hasMadeInitialRequest ? (
        <div>
          <MergeCodeBlock
            colorMode="light"
            codeBlockName="JSON"
            textToCopy={responseBody ?? "The remote API returned an empty body."}
          >
            {responseBody
              ? truncateResponseBody(responseBody)
              : "The remote API returned an empty body."}
          </MergeCodeBlock>
          {responseCode === 404 && (
            <Alert className="mt-4" showWarningIcon>
              Make sure that you have entered the correct endpoint
            </Alert>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center h-48">
          <Text variant="lg" className="text-gray-60">
            Enter the endpoint path and click <b>Send</b> to get a response
          </Text>
        </div>
      )}
    </div>
  );
};

export default ResponseBodySection;
