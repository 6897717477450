import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import { GUARDRAILS_ENFORCED_STATUSES, LinkedAccount } from "../../../../models/Entities";
import {
  navigateToProductionLinkedAccountsTablePage,
  navigateToTestLinkedAccountsTablePage,
} from "../../../../router/RouterUtils";
import useAppContext from "../../../context/useAppContext";
import EmptyStateWrapper from "../../../shared/EmptyStateWrapper";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import LinkedAccountDeleteSwitch from "../shared/LinkedAccountDeleteSwitch";
import LinkedAccountDeletionModal from "../shared/LinkedAccountDeletionModal";
import LinkedAccountTypeConvertButton from "../shared/LinkedAccountTypeConvertButton";
import { SectionTitle, TitleHeading } from "../../../shared/MergeLayouts";
import DottedOutlineTextCard from "../../../shared/DottedOutlineTextCard";

type Props = {
  /**
   * Something to call when the linked account updates
   */
  updateLinkedAccount: (linkedAccount: LinkedAccount) => void;
  linkedAccount: LinkedAccount | null;
};

/**
 * Shows a few settings for the linked account itself. Always guaranteed to have content if there's a linked account
 */
const SettingsPage = ({ linkedAccount, updateLinkedAccount }: Props) => {
  const { user, isUserPrivileged } = useAppContext();
  const history = useHistory();
  const isTestAccount = useMemo(() => linkedAccount?.is_test_account ?? false, [linkedAccount]);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const deleteAccount = useCallback(() => {
    if (!linkedAccount) {
      return;
    }
    let isCancelled = false;
    fetchWithAuth({
      path: `/integrations/linked-accounts/${linkedAccount.id}`,
      method: "DELETE",
      onResponse: () => {
        if (isCancelled) {
          return;
        }
        setShowDeletionModal(false);
        showSuccessToast(`Deleted linked account!`);
        if (isTestAccount) {
          navigateToTestLinkedAccountsTablePage(history);
        } else {
          navigateToProductionLinkedAccountsTablePage(history);
        }
        updateLinkedAccount(linkedAccount);
      },
      onError: () => {
        if (isCancelled) {
          return;
        }
        showErrorToast("Failed to delete linked account. Please try again.");
      },
    });
    return () => {
      isCancelled = true;
    };
  }, [linkedAccount]);

  // Adds both the confirm modal and the card to delete
  const deleteAccountContent = linkedAccount && (
    <>
      <LinkedAccountDeleteSwitch
        linkedAccount={linkedAccount}
        onClick={() => setShowDeletionModal(true)}
      />
      <LinkedAccountDeletionModal
        isModalOpen={showDeletionModal}
        deleteLinkedAccount={deleteAccount}
        closeModal={() => setShowDeletionModal(false)}
        linkedAccount={linkedAccount}
      />
    </>
  );

  const linkedAccountInfoContent = linkedAccount && (
    <Card className="flex flex-col">
      <div className="border-b border-gray-10 p-5">
        <SectionTitle title="Account details" titleHeading={TitleHeading.H2} />
      </div>
      <div className="flex flex-col gap-8 p-5">
        <div className="flex flex-col gap-y-2">
          <SectionTitle title="End user origin ID" titleHeading={TitleHeading.H4} />
          <DottedOutlineTextCard text={linkedAccount?.end_user.origin_id} />
        </div>
        <div className="flex flex-col gap-y-2">
          <SectionTitle title="Email" titleHeading={TitleHeading.H4} />
          <DottedOutlineTextCard text={linkedAccount?.end_user.email_address} />
        </div>
      </div>
    </Card>
  );

  const guardrailsEnforced =
    user.organization.billing_guardrails_status !== undefined &&
    GUARDRAILS_ENFORCED_STATUSES.includes(user.organization.billing_guardrails_status);
  return (
    <>
      {linkedAccount && isTestAccount && isUserPrivileged && (
        <>
          <LinkedAccountTypeConvertButton
            linkedAccount={linkedAccount}
            updateLinkedAccount={updateLinkedAccount}
            guardrailsEnforced={guardrailsEnforced}
          />
        </>
      )}
      {linkedAccountInfoContent}
      {deleteAccountContent}
      {!linkedAccount && <EmptyStateWrapper title="No Settings" />}
    </>
  );
};

export default SettingsPage;
