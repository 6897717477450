import React from "react";
import { navigateToLinkedAccountDetailWebhooksPathForId } from "../../../../../../../router/RouterUtils";
import ConfigurationWidgetCard from "./ConfigurationWidgetCard";
import { useHistory } from "react-router-dom";
import { Text, Badge, Link } from "@merge-api/merge-javascript-shared";

type WebhookReceiversWidgetCardProps = {
  activeWebhookReceiversCount: number | undefined;
  linkedAccountId: string;
};

const WebhookReceiversWidgetCard = ({
  activeWebhookReceiversCount,
  linkedAccountId,
}: WebhookReceiversWidgetCardProps) => {
  const history = useHistory();

  return (
    <ConfigurationWidgetCard
      cardTitle={
        <div className="flex flex-row items-center gap-2">
          <Text variant="h6">Webhooks</Text>
          <Badge color="gray">{activeWebhookReceiversCount}</Badge>
        </div>
      }
      badgeChildren={
        <Link
          variant="h6"
          className="text-blue-40 px-0"
          onClick={() => navigateToLinkedAccountDetailWebhooksPathForId(history, linkedAccountId)}
        >
          Edit
        </Link>
      }
    />
  );
};

export default WebhookReceiversWidgetCard;
