import React from "react";
import { HTTPMethodBadge, ResponseCodeBadge } from "../../../../../shared/MergeBadges";
import { Tooltip, Text } from "@merge-api/merge-javascript-shared";
import CopyComponentWithIcon from "../../../../../shared/CopyComponentWithIcon";

type Props = {
  title: string;
  subtitle: string;
  image?: JSX.Element;
  request_method?: string;
  response_status?: number;
};

export const LogSidePanelBodySectionHeader = ({
  title,
  subtitle,
  image,
  request_method,
  response_status,
}: Props) => {
  return (
    <div className="flex flex-row items-center px-6 py-5 gap-6 border-b-[0.5px] border-gray-20">
      {image && (
        <div className="flex">
          <div>{image}</div>
        </div>
      )}
      <div className="flex flex-grow flex-col justify-start flex-nowrap truncate">
        <Text variant="h3">{title}</Text>
        <div className="flex flex-row flex-nowrap items-center gap-x-2">
          <div className="w-fit text-gray-60">
            <CopyComponentWithIcon fontSize={16} text={subtitle} />
          </div>
          <Tooltip title={subtitle} className="truncate">
            <Text className="text-gray-60 truncate">{subtitle}</Text>
          </Tooltip>
        </div>
      </div>
      <div className="flex items-center whitespace-nowrap">
        {response_status && <ResponseCodeBadge responseCode={response_status} size="lg" />}
        {request_method && <HTTPMethodBadge method={request_method} size="lg" />}
      </div>
    </div>
  );
};
