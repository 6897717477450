import React from "react";
import styled from "styled-components";
import ScopesDropdown from "../data/ScopesDropdown";
import { Dropdown } from "react-bootstrap";
import { Tooltip } from "@merge-api/merge-javascript-shared";
import AnimatedChevron from "../../../configuration/common-models/AnimatedChevron";
import EnabledDisabledDiv from "./components/EnabledDisabledDiv";
import { ToggleLeftIcon, ToggleRightIcon } from "lucide-react";

const DropdownMenuOption = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export interface LinkedAccountSettingToggleProps {
  onDropdownSelected: (eventKey: LinkedAccountSettingToggleDropdownOption) => void;
  isSettingEnabled: boolean;
  isDefaultOverridden: boolean;
  includeToggleIcon?: boolean;
}

export enum LinkedAccountSettingToggleDropdownOption {
  ENABLE = "ENABLE",
  DISABLE = "DISABLE",
  RESET = "RESET",
}

const LinkedAccountSettingToggle: React.FC<LinkedAccountSettingToggleProps> = ({
  onDropdownSelected,
  isSettingEnabled,
  isDefaultOverridden,
  includeToggleIcon = false,
}: LinkedAccountSettingToggleProps) => {
  return (
    <div className="flex items-center flex-nowrap my-2">
      <ScopesDropdown
        menuOptions={
          <>
            <Dropdown.Item
              className={
                !isSettingEnabled ? "text-black hover:bg-gray-0" : "text-gray-60 cursor:none"
              }
              eventKey={LinkedAccountSettingToggleDropdownOption.ENABLE}
              disabled={isSettingEnabled}
              onClick={(e) => {
                e.stopPropagation();
                onDropdownSelected(LinkedAccountSettingToggleDropdownOption.ENABLE);
              }}
            >
              <DropdownMenuOption>Enabled</DropdownMenuOption>
            </Dropdown.Item>
            <Dropdown.Item
              className={
                isSettingEnabled ? "text-black hover:bg-gray-0" : "text-gray-60 cursor:pointer"
              }
              eventKey={LinkedAccountSettingToggleDropdownOption.DISABLE}
              disabled={!isSettingEnabled}
              active={isSettingEnabled}
              onClick={(e) => {
                e.stopPropagation();
                onDropdownSelected(LinkedAccountSettingToggleDropdownOption.DISABLE);
              }}
            >
              <DropdownMenuOption>Disabled</DropdownMenuOption>
            </Dropdown.Item>
            <Dropdown.Item
              className={`${
                isDefaultOverridden ? "text-black hover:bg-gray-0" : "text-gray-60 cursor:none"
              }`}
              disabled={!isDefaultOverridden}
              eventKey={LinkedAccountSettingToggleDropdownOption.RESET}
              onClick={(e) => {
                e.stopPropagation();
                onDropdownSelected(LinkedAccountSettingToggleDropdownOption.RESET);
              }}
            >
              <DropdownMenuOption>Reset to org default</DropdownMenuOption>
            </Dropdown.Item>
          </>
        }
      >
        <EnabledDisabledDiv isEnabled={isSettingEnabled} className="flex items-center">
          {includeToggleIcon &&
            (isSettingEnabled ? (
              <ToggleRightIcon size={16} className="mr-2" />
            ) : (
              <ToggleLeftIcon size={16} className="mr-2" />
            ))}
          {isSettingEnabled ? "Enabled" : "Disabled"}
          <AnimatedChevron className="ml-1" $isUp={false} />
        </EnabledDisabledDiv>
      </ScopesDropdown>
      {isDefaultOverridden && (
        <Tooltip placement="top" title="Override of your organization's default setting">
          <div className="font-inter italic text-yellow-50 ml-2">Overridden</div>
        </Tooltip>
      )}
    </div>
  );
};

export default LinkedAccountSettingToggle;
