import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";
import IconForFieldType from "./IconForFieldType";

type CommonModelFieldDropdownProps = {
  fieldDescription: string | undefined;
  fieldType: string;
  fieldName: string;
};
const CommonModelFieldDropdown = ({
  fieldDescription,
  fieldType,
  fieldName,
}: CommonModelFieldDropdownProps) => {
  return (
    <div className="flex flex-row w-full whitespace-normal gap-x-2 items-start ">
      <div className="flex">
        <IconForFieldType size={16} type={fieldType} className="text-gray-60" />
      </div>
      <div className="flex flex-grow flex-col mt-0.5">
        <Text variant="md-mono">{fieldName}</Text>
        {fieldDescription && (
          <Text variant="sm" className="text-gray-60 text-wrap">
            {fieldDescription.split(".")[0]}
          </Text>
        )}
      </div>
    </div>
  );
};

export default CommonModelFieldDropdown;
