import React from "react";
import clsx from "clsx";
import {
  APICategory,
  ConditionPreset,
  ConditionPresetMeta,
} from "../../../../../../models/Entities";
import { Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { getOperatorValue, getSupportedIntegrations } from "../helpers";
import size from "lodash/size";
import { Info } from "lucide-react";

type SupportedIntegrationsTooltipProps = {
  className?: string;
  conditionPreset: Partial<ConditionPreset>;
  conditionPresetMeta: ConditionPresetMeta;
  category: APICategory;
};

const SupportedIntegrationsTooltip = ({
  className,
  conditionPreset,
  conditionPresetMeta,
  category,
}: SupportedIntegrationsTooltipProps) => {
  const operatorValue = getOperatorValue(conditionPreset, conditionPresetMeta, category);
  const supportedIntegrations = getSupportedIntegrations(
    conditionPreset,
    conditionPresetMeta,
    category,
  );

  if (!operatorValue || !size(supportedIntegrations)) {
    return null;
  }

  return (
    <div className={clsx(className)}>
      <Text variant="pre-title" className="text-gray-60 flex flex-row items-center cursor-default">
        <Tooltip
          title={
            <div className="flex flex-col text-white">
              <Text>Integration support for this filter:</Text>
              {supportedIntegrations?.map(({ id, name, square_image }) => (
                <div key={id} className="flex flex-row items-center mt-1">
                  <img
                    src={square_image}
                    alt={name}
                    className="w-4 h-4 object-cover rounded-full bg-white mr-1.5"
                  />
                  <Text>{name}</Text>
                </div>
              ))}
            </div>
          }
        >
          <div className="flex items-center">
            <Info size={12} className="mr-1.5" />
            {size(supportedIntegrations)} integration{size(supportedIntegrations) > 1 && "s"}{" "}
            supported
          </div>
        </Tooltip>
      </Text>
    </div>
  );
};

export default SupportedIntegrationsTooltip;
