import React, { useEffect, useState } from "react";
import { APICategory, Select } from "@merge-api/merge-javascript-shared";
import useLoadIntegrationsDropdownOptions, {
  IntegrationDropdownOption,
} from "../../../../../hooks/useLoadIntegrationsDropdownOptions";

export type CustomizedIntegrationDropdownOption = {
  id: number;
  name: string;
  image: string;
  square_image: string;
  slug: string;
  is_customized?: boolean;
};

interface IntegrationsDropdownProps {
  onChange: (event: any, option: CustomizedIntegrationDropdownOption | null) => void;
  category?: APICategory;
  filterDisabled?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  customizedIntegrationIDs?: number[];
  selectedIntegrationID: number | null;
}

/**
 *
 * This is a full copy of IntegrationsDropdown but with some customization since we cant pass in variables to the render function
 * but need ways to distinguish customized vs default integration customizations
 *
 */

const CustomizationIntegrationsDropdown = ({
  category,
  filterDisabled,
  disabled,
  onChange,
  clearable = true,
  customizedIntegrationIDs,
  selectedIntegrationID,
}: IntegrationsDropdownProps) => {
  const [customizedIntegrationsDropdownOptions, setCustomizedIntegrationsDropdownOptions] =
    useState<CustomizedIntegrationDropdownOption[] | undefined>(undefined);
  const [value, setValue] = useState<CustomizedIntegrationDropdownOption | null>(null);
  // hooks
  const { integrationsDropdownOptions } = useLoadIntegrationsDropdownOptions({
    category,
    filterDisabled,
  });

  useEffect(() => {
    if (selectedIntegrationID == null) {
      setValue(null);
    } else if (customizedIntegrationsDropdownOptions != undefined) {
      const selectedIntegration = customizedIntegrationsDropdownOptions.find(
        (option) => option.id === selectedIntegrationID,
      );
      setValue(selectedIntegration ?? null);
    }
  }, [selectedIntegrationID]);

  useEffect(() => {
    setCustomizedIntegrationsDropdownOptions(
      integrationsDropdownOptions?.map((option) => {
        if (customizedIntegrationIDs?.includes(option.id)) {
          const newOption = { ...option, is_customized: true };
          return newOption;
        } else {
          const newOption = { ...option, is_customized: false };
          return newOption;
        }
      }),
    );
  }, [JSON.stringify(customizedIntegrationIDs), JSON.stringify(integrationsDropdownOptions)]);

  return (
    <Select
      options={
        customizedIntegrationsDropdownOptions == undefined
          ? []
          : customizedIntegrationsDropdownOptions
      }
      renderOption={(option: CustomizedIntegrationDropdownOption) => (
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row items-center gap-2 text-center">
            <img className="h-[25px] w-[25px] rounded-full" src={option.square_image} />{" "}
            {option.name}
          </div>
          {option.is_customized === true ? (
            <div className="flex text-blue-40 text-sm font-normal items-center text-center">
              Customized
            </div>
          ) : (
            <div className="flex text-gray-60 text-sm font-normal items-center text-center">
              Default
            </div>
          )}
        </div>
      )}
      getOptionLabel={(option) => option.name}
      placeholder="Select integration..."
      disabled={disabled}
      onChange={onChange}
      clearable={clearable}
      value={value}
    />
  );
};

export default CustomizationIntegrationsDropdown;
