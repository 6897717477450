import React from "react";
import { Form } from "react-bootstrap";
import { fetchCurrentUser, fetchWithAuth } from "../../../../../api-client/APIClient";
import { PreexistingProductionLinkedAccountPreference } from "../../../../../models/Entities";
import useAppContext from "../../../../context/useAppContext";
import { SectionHeaderWrapper } from "../../../../shared/MergeLayouts";
import { showErrorToast } from "../../../../shared/Toasts";
import { Toggle } from "@merge-api/merge-javascript-shared";
import ConfigurationSettingsCard from "../../link/helpers/ConfigurationSettingsCard";
import { CONFIGURATION_INTEGRATIONS_PATH } from "../../../../../router/RouterUtils";
import { Link } from "react-router-dom";

const ConfigurationIntegrationsSettings = () => {
  const { user, setUser, isUserPrivileged } = useAppContext();
  const autoEnableNewIntegrations = user.organization?.auto_enable_new_integrations;
  const preexistingProductionLinkedAccountPreference =
    user.organization?.preexisting_production_linked_account_preference;

  const updateSettings = ({
    autoEnableNewIntegrations,
    preexistingProductionLinkedAccountPreference,
  }: {
    autoEnableNewIntegrations?: boolean;
    preexistingProductionLinkedAccountPreference?: PreexistingProductionLinkedAccountPreference;
  }) => {
    fetchWithAuth({
      path: "/organizations/me",
      method: "PATCH",
      body: {
        auto_enable_new_integrations: autoEnableNewIntegrations,
        preexisting_production_linked_account_preference:
          preexistingProductionLinkedAccountPreference,
      },
      onResponse: () => {
        fetchCurrentUser(setUser);
      },
      onError: () => {
        showErrorToast("Something went wrong, please check your connection and try again.");
      },
    });
  };

  const linkToNewIntegrations = (
    <Link to={CONFIGURATION_INTEGRATIONS_PATH} className="flex items-center text-sm font-medium">
      View integrations <i className="fe fe-chevron-right ml-1" />
    </Link>
  );

  const linkToDuplicateProductionLinkedAccounts = (
    <a
      href="https://help.merge.dev/en/articles/6463873-duplicate-production-linked-accounts"
      className="flex items-center text-sm font-medium"
      target="_blank"
      rel="noreferrer"
    >
      Learn more about duplicates <i className="fe fe-chevron-right ml-1" />
    </a>
  );

  // Card for enabling new integrations by default
  const newIntegrationsByDefault = (
    <ConfigurationSettingsCard
      title="Enable new integrations by default"
      rightHandContent={linkToNewIntegrations}
    >
      <div className="flex flex-row justify-between">
        <p className="mb-0">When Merge releases new integrations, add them automatically to Link</p>
        <Toggle
          checked={autoEnableNewIntegrations}
          label={autoEnableNewIntegrations ? "Enabled" : "Disabled"}
          onChange={(autoEnableNewIntegrations) => updateSettings({ autoEnableNewIntegrations })}
        />
      </div>
    </ConfigurationSettingsCard>
  );

  const duplicateProductionLinkedAccountDetectionCard = (
    <ConfigurationSettingsCard
      title="Duplicate Linked Account detection"
      rightHandContent={linkToDuplicateProductionLinkedAccounts}
    >
      <>
        Define what Merge should do when your user attempts to integrate using credentials (e.g. API
        Key, OAuth credentials, etc.) that match an existing production Linked Account
        <Form style={{ marginTop: "20px" }}>
          <Form.Check
            className="mb-3"
            type="radio"
            id="create-new-linked-account"
            label={
              <div className="text-body">
                Create a new Linked Account with the previously used credentials
              </div>
            }
            onChange={() =>
              updateSettings({
                preexistingProductionLinkedAccountPreference:
                  PreexistingProductionLinkedAccountPreference.CREATE_AS_NEW_END_USER,
              })
            }
            checked={
              preexistingProductionLinkedAccountPreference ===
              PreexistingProductionLinkedAccountPreference.CREATE_AS_NEW_END_USER
            }
          />
          <Form.Check
            className="mb-3"
            type="radio"
            id="use-preexisting"
            label={
              <div className="text-body">
                Show a success message in Link to the end user but do not create a new Linked
                Account with the previously used credentials
                <p className="mb-0 text-gray-60">
                  Note: This option should only be used after you’ve defined logic to associate
                  multiple of your users with the same account token in your Merge Link
                  implementation.
                </p>
              </div>
            }
            onChange={() =>
              updateSettings({
                preexistingProductionLinkedAccountPreference:
                  PreexistingProductionLinkedAccountPreference.USE_PREEXISTING_END_USER,
              })
            }
            checked={
              preexistingProductionLinkedAccountPreference ===
              PreexistingProductionLinkedAccountPreference.USE_PREEXISTING_END_USER
            }
          />
          <Form.Check
            className="mb-3"
            type="radio"
            id="block-duplicates"
            label={
              <div className="text-body">
                Show an error message in Link to the end user and do not create a new Linked Account
                with the previously used credentials
              </div>
            }
            onChange={() =>
              updateSettings({
                preexistingProductionLinkedAccountPreference:
                  PreexistingProductionLinkedAccountPreference.BLOCK,
              })
            }
            checked={
              preexistingProductionLinkedAccountPreference ===
              PreexistingProductionLinkedAccountPreference.BLOCK
            }
          />
        </Form>
      </>
    </ConfigurationSettingsCard>
  );

  return (
    <SectionHeaderWrapper title="Configuration" subtitle="Customize your preferences in Merge Link">
      <>
        {newIntegrationsByDefault}
        {isUserPrivileged && duplicateProductionLinkedAccountDetectionCard}
      </>
    </SectionHeaderWrapper>
  );
};

export default ConfigurationIntegrationsSettings;
