import React from "react";
import { getCommonModelName } from "../../../utils/IntegrationsManagementUtils";
import { Text } from "@merge-api/merge-javascript-shared";

type ImpactedModelsRowProps = {
  impactedModels: { [key: string]: number };
  isOverviewPage: boolean;
};

const ImpactedModelsRow = ({ impactedModels, isOverviewPage }: ImpactedModelsRowProps) => {
  return (
    <div className={`flex gap-2 mb-1 flex-wrap ${isOverviewPage ? "mt-2" : ""}`}>
      {Object.entries(impactedModels).map(([impactedModel, number]) => {
        return (
          <div className="px-1.5 py-[1px] bg-gray-0 rounded flex items-center gap-1.5">
            <Text variant="h6">{getCommonModelName(impactedModel)}</Text>
            <div className="bg-white rounded-[3px] whitespace-nowrap px-[3px] py-0 text-[10px] leading-4 semi-bold">
              {number} {number == 1 ? "issue" : "issues"}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ImpactedModelsRow;
