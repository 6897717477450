import React from "react";
import { Image } from "react-bootstrap";
import styled, { css } from "styled-components";
import { spectrum } from "../../../../styles/theme";
import {
  isWebConnectorLogEntry,
  isWebhookLogEntry,
  LogType,
} from "../../IntegrationsManagementEntities";
import MergeLogoMark from "./MergeLogoMark";
import { Tooltip } from "@merge-api/merge-javascript-shared";

interface Props {
  log: LogType;
}

interface CircleBadgeProps {
  backupInitial: string;
  imageUrl?: string;
  tooltip: string;
}

// Circular, with border and fixed size
const Container = styled.div`
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 20px;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
`;

const Grid = styled.div`
  display: inline-flex;
  grid-template-columns: 20px 16px 20px 16px 20px;
  grid-template-rows: 20px;
`;

/**
 * Helper component for arrow with a tooltip
 */
export enum ArrowDirection {
  RIGHT = "RIGHT",
  LEFT = "LEFT",
}

interface ArrowProps {
  tooltip: string;
  direction?: ArrowDirection;
}

const ArrowSVG = styled.svg<{ $isLeftArrow: boolean }>`
  width: 16px;
  height: 16px;
  fill: ${spectrum.gray40};
  ${({ $isLeftArrow }) =>
    $isLeftArrow &&
    css`
      transform: rotate(180deg);
    `};
`;

const RightArrow = () => (
  <>
    <rect x="4" y="7" width="5" height="2" />
    <path d="M12 8L9 5V11L12 8Z" />
  </>
);

export const Arrow = ({ tooltip, direction = ArrowDirection.RIGHT }: ArrowProps) => {
  return (
    <Tooltip title={tooltip}>
      <ArrowSVG viewBox="0 0 16 16" $isLeftArrow={direction === ArrowDirection.LEFT}>
        <RightArrow />
      </ArrowSVG>
    </Tooltip>
  );
};

/**
 * Helper component to create a circular badge with a given name/imageUrl if exists
 */
const CircleBadge = ({ backupInitial, imageUrl, tooltip }: CircleBadgeProps) => (
  <Tooltip title={tooltip}>
    <Container className="d-flex align-items-center justify-content-center text-black">
      {imageUrl ? <Image src={imageUrl} width={20} height={20} rounded /> : backupInitial}
    </Container>
  </Tooltip>
);

/**
 * Shows a cell with a directional visual with logos/text for where the log
 * came from. Has a standard 5 cells, but there's different content based on
 * if the direction is inbound/outbound.
 */
const DirectionCell = ({ log }: Props) => {
  const isInbound = log.direction === "INBOUND";

  const integrationName = log.linked_account.integration?.name || "None";
  const IntegrationCircleBadge = () => {
    return (
      <CircleBadge
        tooltip={integrationName}
        backupInitial={integrationName[0]}
        imageUrl={log.linked_account.integration?.square_image}
      />
    );
  };

  const organizationName = log.linked_account.organization.name;
  const OrganizationCircleBadge = () => {
    return <CircleBadge tooltip={organizationName} backupInitial={organizationName[0]} />;
  };

  return (
    <td>
      <Grid className="d-flex align-items-center">
        {isWebhookLogEntry(log) || isWebConnectorLogEntry(log) ? (
          isInbound ? (
            <>
              <IntegrationCircleBadge />
              <Arrow tooltip={`${integrationName} to Merge`} />
              <MergeLogoMark />
            </>
          ) : (
            <>
              <OrganizationCircleBadge />
              <Arrow tooltip={`Merge to ${organizationName}`} direction={ArrowDirection.LEFT} />
              <MergeLogoMark />
            </>
          )
        ) : isInbound ? (
          <>
            <OrganizationCircleBadge />
            <Arrow tooltip={`${organizationName} to Merge`} />
            <MergeLogoMark />
          </>
        ) : (
          <>
            <MergeLogoMark />
            <Arrow tooltip={`Merge to ${integrationName}`} />
            <IntegrationCircleBadge />
          </>
        )}
      </Grid>
    </td>
  );
};

export default DirectionCell;
