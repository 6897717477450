import React from "react";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { Integration } from "../../../models/Entities";
import { getAPIRequestPath } from "../../../models/Helpers";
import { showErrorToast } from "../../shared/Toasts";
import { APIRequestLogEntry } from "../IntegrationsManagementEntities";

const useRetryLog = (
  log: APIRequestLogEntry,
  integration: Integration,
  setResponseCode: React.Dispatch<React.SetStateAction<number | undefined>>,
  setResponseBody: React.Dispatch<React.SetStateAction<string>>,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const path = getAPIRequestPath(log.url, integration);

  const retryLog = () => {
    setShowModal(true);
    setResponseBody("");
    setResponseCode(undefined);
    fetchWithAuth({
      path: `/integrations/linked-accounts/${log.linked_account.id}/test-request`,
      method: "POST",
      body: { method: log.method, endpoint: path, body: log.request_body },
      onResponse: (responseData: { status: string; status_code: number; body: string }) => {
        setResponseCode(responseData.status_code);
        try {
          setResponseBody(JSON.stringify(JSON.parse(responseData.body), null, 2));
        } catch (err) {
          setResponseBody(responseData.body);
        }
      },
      onError: () => {
        showErrorToast("Oops! Looks like we encountered an error. Please try again.");
        setShowModal(false);
      },
    });
  };

  return { retryLog };
};

export default useRetryLog;
