import clsx from "clsx";
import React, { useEffect, useState } from "react";
import CopyComponentWithIcon from "./CopyComponentWithIcon";
import useTimeout from "./hooks/useTimeout";
import { Link, Tooltip, Text } from "@merge-api/merge-javascript-shared";
import { ExternalLink, Eye, EyeOff, RefreshCcw } from "lucide-react";

type Props = Pick<React.ComponentProps<"div">, "className"> & {
  text: string;
  onClickRegenerate?: () => void;
  isSecret?: boolean;
  isSecretHidden?: boolean;
  iconSize?: number;
  isUrl?: boolean;
};

const DottedOutlineTextCard = ({
  text,
  onClickRegenerate,
  isSecret,
  isSecretHidden,
  className,
  iconSize = 16,
  isUrl = false,
}: Props) => {
  const [isTextHidden, setIsTextHidden] = useState(false);
  const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);

  useEffect(() => {
    if (isSecret) {
      setIsTextHidden(true);
    }
  }, [isSecret]);

  // Resets the copy state after 3 seconds if you want to copy again
  useTimeout(
    () => {
      setHasCopiedToClipboard(false);
    },
    hasCopiedToClipboard ? 3000 : null,
  );

  return (
    <div
      className={clsx(
        "flex flex-row items-center justify-between gap-2 w-full bg-gray-0 rounded-md px-3 py-2",
        className,
      )}
    >
      {isSecret && !isSecretHidden && (
        <Tooltip title={isTextHidden ? "Show key" : "Hide key"}>
          {isTextHidden ? (
            <EyeOff
              size={iconSize}
              onClick={() => setIsTextHidden((isTextHidden) => !isTextHidden)}
              className="hover:cursor-pointer"
            />
          ) : (
            <Eye
              size={iconSize}
              onClick={() => setIsTextHidden((isTextHidden) => !isTextHidden)}
              className="hover:cursor-pointer"
            />
          )}
        </Tooltip>
      )}
      <div
        className={clsx(
          "flex flex-grow overflow-hidden whitespace-nowrap",
          !isTextHidden && "truncate",
        )}
      >
        <Text className={clsx("overflow-hidden whitespace-nowrap", !isTextHidden && "truncate")}>
          {isTextHidden ? "•".repeat(text.length) : text}
        </Text>
      </div>
      <div className="flex flex-row gap-2">
        <CopyComponentWithIcon text={text} fontSize={iconSize} />
        {isUrl && (
          <Tooltip title="Open in new tab">
            <Link href={text} target="_blank" className="text-black">
              <ExternalLink className="hover:cursor-pointer" size={iconSize} />
            </Link>
          </Tooltip>
        )}

        {onClickRegenerate && (
          <Tooltip title="Regenerate access token">
            <RefreshCcw
              size={iconSize}
              onClick={onClickRegenerate}
              className="hover:cursor-pointer"
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default DottedOutlineTextCard;
