import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useTimeout from "./hooks/useTimeout";
import { Tooltip } from "@merge-api/merge-javascript-shared";
import { Copy } from "lucide-react";

type Props = {
  text?: string;
  fontSize?: number;
};

const CopyComponentWithIcon = ({ text, fontSize = 16 }: Props) => {
  const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);

  // Resets the copy state after 3 seconds if you want to copy again
  useTimeout(
    () => {
      setHasCopiedToClipboard(false);
    },
    hasCopiedToClipboard ? 3000 : null,
  );

  return (
    <Tooltip title={hasCopiedToClipboard ? "Copied!" : "Copy"}>
      <CopyToClipboard text={text ?? ""} onCopy={() => setHasCopiedToClipboard(true)}>
        <Copy size={fontSize} className="hover:cursor-pointer" />
      </CopyToClipboard>
    </Tooltip>
  );
};

export default CopyComponentWithIcon;
