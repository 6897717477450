import React, { useEffect, useState } from "react";
import {
  APICategory,
  Link,
  Spinner,
  Text,
  displayNameForAPICategory,
} from "@merge-api/merge-javascript-shared";
import useManageConditionPresets from "./hooks/useManageConditionPresets";
import ConditionPresetsList from "./ConditionPresetsList";
import ConditionPresetConfirmDiscardChangesDialog from "./ConditionPresetConfirmDiscardChangesDialog";
import ConditionPresetHeader from "./ConditionPresetHeader";
import ConditionPresetPublishChangesDialog from "./ConditionPresetPublishChangesDialog";
import ConditionPresetPreviewLinkDialog from "./ConditionPresetPreviewLinkDialog";
import useLoadConditionPresetData from "./hooks/useLoadConditionPresetData";
import useConditionPresetIntegrations from "./ConditionPresetPreviewLinkDialog/hooks/useConditionPresetIntegrations";
import { useParams } from "react-router-dom";
import useAppContext from "../../../context/useAppContext";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import { OrganizationCustomization } from "../link/context/CustomizationContext";
import { BillingPlanTier } from "../../settings/billing/BillingModels";
import { MergeFlagFeature, useMergeFlag } from "../../../shared/hooks/useMergeFlag";
import { HTTPMethod } from "../../../../models/Entities";

interface CategoryProps {
  category: APICategory;
}

const NoFiltersAvailable = ({ category }: CategoryProps) => (
  <div className="mt-8 flex justify-center flex-col items-center">
    <Text variant="h4" className="flex text-gray-60">
      {category === APICategory.filestorage
        ? `Filters for ${displayNameForAPICategory(
            category,
          )} can only be set by your users in Merge Link`
        : `No filters are available for ${displayNameForAPICategory(category)}`}
    </Text>
    <Text variant="sm" className="flex text-gray-60 font-semibold">
      {category === APICategory.filestorage
        ? "You will see applied filters in each Linked Account"
        : `Selective Sync filters for ${displayNameForAPICategory(
            category,
          )} integrations can only be set by your end users in Merge Link.`}
    </Text>
  </div>
);

const LoadingSpinner = () => (
  <div className="flex justify-center">
    <Spinner />
  </div>
);

const NoMappedFiltersMessage = ({ category }: CategoryProps) => (
  <div className="mt-8 flex justify-center">
    <Text variant="h4" className="text-gray-60">
      No filters are currently available for {displayNameForAPICategory(category)}
    </Text>
  </div>
);

const UpsellFiltersMessage = () => (
  <div className="mt-8 flex flex-col justify-center items-center">
    <Text variant="h4" className="text-gray-60">
      No filters available
    </Text>
    <Text variant="sm" className="text-gray-60 font-semibold">
      <Link href="/billing" className="text-blue">
        Upgrade your plan{" "}
      </Link>
      to add filters
    </Text>
  </div>
);

const ConfigurationSelectiveSync = () => {
  const { user } = useAppContext();
  const [organizationCustomization, setOrganizationCustomization] =
    useState<OrganizationCustomization | null>(null);

  // state
  const [publishChangesDialogOpen, setPublishChangesDialogOpen] = useState(false);
  const [conditionPresetPreviewLinkDialogOpen, setConditionPresetPreviewLinkDialogOpen] =
    useState(false);

  // hooks
  const { category } = useParams<{ category: APICategory }>();
  const { loading, refetch, remoteConditionPresets, conditionPresetMeta } =
    useLoadConditionPresetData({});
  const {
    conditionPresets,
    conditionPresetErrors,
    onConditionPresetChange,
    onConditionPresetDelete,
    onConditionPresetAdd,
    publishChanges,
    changes,
    publishing,
    hasChanges,
    validateChanges,
  } = useManageConditionPresets({
    remoteConditionPresets,
    refetch: () => {
      refetch();
      setPublishChangesDialogOpen(false);
    },
  });
  const { previewLinkEnabled, hasMappedFiltersForCategory } = useConditionPresetIntegrations({
    conditionPresets,
    conditionPresetMeta,
    category,
  });

  useEffect(() => {
    fetchWithAuth({
      path: `integrations/customizable-link-settings`,
      method: "GET",
      onResponse: (customizationSettings) => {
        // if a customer does not have OrganizationCustomization but does have Organization, then we need to POST  and set
        // OrganizationCustomization.show_selective_sync_screen to Organization.show_selective_sync so that further changes
        // to this value can be processed correctly
        if (!customizationSettings.organization_customization) {
          const newOrganizationCustomization: OrganizationCustomization = {
            status: "PUBLISHED",
            show_selective_sync_screen: user.organization.show_selective_sync ?? false,
          };
          fetchWithAuth({
            path: `/integrations/create-update-customizable-link-settings`,
            method: HTTPMethod.POST,
            body: {
              status: "PUBLISHED",
              organization_customization_data: newOrganizationCustomization,
              integration_customizations_data: null,
            },
            onResponse: () => {},
          });
          setOrganizationCustomization(newOrganizationCustomization);
        } else {
          setOrganizationCustomization(customizationSettings.organization_customization);
        }
      },
    });
  }, []);

  // event handlers
  const onPublishChangesClick = () => {
    const hasErrors = validateChanges();

    if (hasErrors) return;
    setPublishChangesDialogOpen(true);
  };

  // this covers the non enterprise customers who were on the s2 flag who should not have a gated experience
  const { enabled: selectiveSyncEnabled } = useMergeFlag({
    feature: MergeFlagFeature.ENABLE_SELECTIVE_SYNC,
    organizationId: user.organization.id,
  });

  const showFileStorageSelectiveSync =
    category === APICategory.filestorage && user.is_file_storage_selective_sync_enabled;

  const showGatedSelectiveSync =
    !selectiveSyncEnabled &&
    user.organization.organization_billing_plan?.billing_plan.plan_tier !==
      BillingPlanTier.BILLING_PLAN_TIER_ENTERPRISE &&
    user.organization.organization_billing_plan?.billing_plan.plan_tier !==
      BillingPlanTier.BILLING_PLAN_TIER_EXPAND;

  return (
    <>
      <div className="flex flex-col pb-5">
        <ConditionPresetHeader
          hasChanges={hasChanges}
          organizationCustomization={organizationCustomization}
          setOrganizationCustomization={setOrganizationCustomization}
          previewLinkEnabled={previewLinkEnabled}
          onPublishChangesClick={onPublishChangesClick}
          setConditionPresetPreviewLinkDialogOpen={setConditionPresetPreviewLinkDialogOpen}
          showGatedSelectiveSync={showGatedSelectiveSync}
        />
        {showFileStorageSelectiveSync ? (
          <NoFiltersAvailable category={category} />
        ) : loading || !conditionPresetMeta ? (
          <LoadingSpinner />
        ) : remoteConditionPresets?.length === 0 &&
          hasMappedFiltersForCategory &&
          showGatedSelectiveSync ? (
          // no default filters, but has mapped filters, but is not enterprise so can't use those filters
          <UpsellFiltersMessage></UpsellFiltersMessage>
        ) : hasMappedFiltersForCategory ? (
          <ConditionPresetsList
            conditionPresets={conditionPresets}
            conditionPresetErrors={conditionPresetErrors}
            conditionPresetMeta={conditionPresetMeta}
            onConditionPresetChange={onConditionPresetChange}
            onConditionPresetDelete={onConditionPresetDelete}
            onConditionPresetAdd={onConditionPresetAdd}
            showGatedSelectiveSync={showGatedSelectiveSync}
            organizationCustomization={organizationCustomization}
            category={category}
          />
        ) : (
          <NoMappedFiltersMessage category={category} />
        )}
      </div>

      <ConditionPresetConfirmDiscardChangesDialog hasChanges={hasChanges} />

      <ConditionPresetPublishChangesDialog
        changes={changes}
        open={publishChangesDialogOpen}
        onClose={() => setPublishChangesDialogOpen(false)}
        publishChanges={publishChanges}
        publishing={publishing}
      />

      <ConditionPresetPreviewLinkDialog
        conditionPresets={conditionPresets}
        conditionPresetMeta={conditionPresetMeta}
        open={conditionPresetPreviewLinkDialogOpen}
        onClose={() => setConditionPresetPreviewLinkDialogOpen(false)}
        category={category}
      />
    </>
  );
};

export default ConfigurationSelectiveSync;
