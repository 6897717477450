import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ConfigurationCommonModelOverridesSettings from "./common-model-overrides/ConfigurationCommonModelOverrideSettings";
import {
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
  CONFIGURATION_FIELD_MAPPINGS_COMMON_MODEL_OVERRIDES_PATH,
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_ID_SETTINGS_PATH,
} from "../../../../../router/RouterUtils";
import CategoryCommonModelsMappingsOverview from "./overview/CategoryCommonModelMappingsOverview";
import IntegrationWideFieldMappingSettings from "./integration-field-mappings/IntegrationWideFieldMappingSettings";

const ConfigurationFieldMappingsPage = () => {
  return (
    <Switch>
      <Route
        exact
        path={CONFIGURATION_FIELD_MAPPINGS_COMMON_MODEL_OVERRIDES_PATH}
        children={<ConfigurationCommonModelOverridesSettings />}
      />
      <Route
        exact
        path={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_ID_SETTINGS_PATH}
        component={IntegrationWideFieldMappingSettings}
      />

      <Route
        path={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}
        component={CategoryCommonModelsMappingsOverview}
      />

      <Redirect to={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH} />
    </Switch>
  );
};

export default ConfigurationFieldMappingsPage;
