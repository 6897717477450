import classNames from "classnames";
import React from "react";
import clsx from "clsx";
import { Form, useAccordionToggle } from "react-bootstrap";
import styled from "styled-components";
import ClickableContainer from "./ClickableContainer";

export const EllipsesToggle = React.forwardRef(
  (
    {
      children,
      onClick,
    }: {
      children: React.ReactNode;
      onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    },
    ref: React.Ref<HTMLSpanElement>,
  ) => (
    <ClickableContainer>
      <span
        className="dropdown-ellipses dropdown-toggle"
        ref={ref}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onClick(event);
        }}
      >
        {children}
      </span>
    </ClickableContainer>
  ),
);

const StatusRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
`;

const StatusText = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-right: 24px;
`;

type StatusToggleProps = {
  /**
   * Whether the toggle is currently set to a truthy status or not.
   */
  isEnabled: boolean;

  /**
   * Callback for handling status change.
   */
  onChange: (newIsEnabled: boolean) => void;

  /**
   * HTML id attribute of the element to create.
   */
  id: string;

  /**
   * If true, hide the switch compoenent but keep the status text unmoved.
   */
  hideSwitch?: boolean;

  /**
   * If present, show `errorText` in red instead of showing status.
   */
  errorText?: string;
};

export const StatusToggle = ({
  id,
  isEnabled,
  onChange,
  hideSwitch,
  errorText,
}: StatusToggleProps) => {
  return (
    <div className="flex flex-row justify-start">
      <div>
        {errorText ? (
          <StatusText className={classNames("red font-medium")}>
            <StatusText className="mr-1 small fe fe-alert-circle red" />
            {errorText}
          </StatusText>
        ) : (
          <StatusText
            className={classNames(`${isEnabled ? "text-green" : "text-gray-60"} font-semibold`)}
          >
            {isEnabled ? "Enabled" : "Disabled"}
          </StatusText>
        )}
      </div>
      <div style={{ minWidth: "56px" }}>
        {!hideSwitch && (
          <Form>
            <Form.Check
              type="switch"
              id={`${id}-switch`}
              checked={isEnabled}
              onClick={() => onChange(!isEnabled)}
            />
          </Form>
        )}
      </div>
    </div>
  );
};

type ScopesFieldToggle = {
  children: React.ReactNode;
  isClickable: boolean;
  callback?: () => void;
  eventKey: string;
  style?: string;
};
export const ScopesFieldToggle = ({
  children,
  isClickable,
  callback,
  eventKey,
  style,
}: ScopesFieldToggle) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback());

  return (
    <tr
      className={clsx(
        style,
        isClickable && "hover:cursor-pointer active:cursor-pointer focus:cursor-pointer",
      )}
      onClick={decoratedOnClick}
    >
      {children}
    </tr>
  );
};
