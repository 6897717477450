import React from "react";
import { Image } from "react-bootstrap";
import styled, { css } from "styled-components";
import { spectrum } from "../../../../../../styles/theme";
import {
  isWebConnectorLogEntry,
  isWebhookLogEntry,
  LogType,
} from "../../../../IntegrationsManagementEntities";
import MergeMark from "../../../../../../assets/img/mark-borderless.png";
import { Tooltip } from "@merge-api/merge-javascript-shared";

interface Props {
  log: LogType;
}

/**
 * Helper component for arrow with a tooltip
 */
export enum ArrowDirection {
  RIGHT = "RIGHT",
  LEFT = "LEFT",
}

interface ArrowProps {
  tooltip: string;
  direction?: ArrowDirection;
}

const ArrowSVG = styled.svg<{ $isLeftArrow: boolean }>`
  width: 20px;
  height: 20px;
  fill: ${spectrum.gray40};
  ${({ $isLeftArrow }) =>
    $isLeftArrow &&
    css`
      transform: rotate(180deg);
    `};
`;

const RightArrow = () => (
  <>
    <rect x="4" y="7" width="5" height="2" />
    <path d="M12 8L9 5V11L12 8Z" />
  </>
);

export const Arrow = ({ tooltip, direction = ArrowDirection.RIGHT }: ArrowProps) => {
  return (
    <Tooltip title={tooltip}>
      <ArrowSVG viewBox="0 0 16 16" $isLeftArrow={direction === ArrowDirection.LEFT}>
        <RightArrow />
      </ArrowSVG>
    </Tooltip>
  );
};

interface CircleBadgeProps {
  badgeName: string;
  imageUrl?: string;
}

/**
 * Helper component to create a circular badge with a given name/imageUrl if exists
 */
const CircleBadge = ({ badgeName, imageUrl }: CircleBadgeProps) => (
  <Tooltip title={badgeName}>
    <div className="w-7 h-7 bg-white overflow-hidden rounded-full border-2 border-solid flex items-center justify-center text-black text-base font-semibold">
      {imageUrl ? (
        <Image src={imageUrl} width={24} height={24} rounded />
      ) : (
        badgeName[0].toUpperCase()
      )}
    </div>
  </Tooltip>
);

/**
 * Shows a cell with a directional visual with logos/text for where the log
 * came from. Has a standard 5 cells, but there's different content based on
 * if the direction is inbound/outbound.
 */
const BannerDirectionCell = ({ log }: Props) => {
  const isInbound = log.direction === "INBOUND";

  const integrationName = log.linked_account.integration?.name || "None";
  const IntegrationCircleBadge = () => {
    return (
      <CircleBadge
        badgeName={integrationName}
        imageUrl={log.linked_account.integration?.square_image}
      />
    );
  };

  const MergeCircleBadge = () => {
    return <CircleBadge badgeName="Merge" imageUrl={MergeMark} />;
  };

  const organizationName = log.linked_account.organization.name;
  const OrganizationCircleBadge = () => {
    return <CircleBadge badgeName={organizationName} />;
  };

  return (
    <div className="flex flex-row items-center bg-gray-0 rounded-full px-[5px] h-fit py-1">
      {isWebhookLogEntry(log) || isWebConnectorLogEntry(log) ? (
        isInbound ? (
          <>
            <IntegrationCircleBadge />
            <Arrow tooltip={`${integrationName} to Merge`} />
            <MergeCircleBadge />
          </>
        ) : (
          <>
            <OrganizationCircleBadge />
            <Arrow tooltip={`Merge to ${organizationName}`} direction={ArrowDirection.LEFT} />
            <MergeCircleBadge />
          </>
        )
      ) : isInbound ? (
        <>
          <OrganizationCircleBadge />
          <Arrow tooltip={`${organizationName} to Merge`} />
          <MergeCircleBadge />
        </>
      ) : (
        <>
          <MergeCircleBadge />
          <Arrow tooltip={`Merge to ${integrationName}`} />
          <IntegrationCircleBadge />
        </>
      )}
    </div>
  );
};

export default BannerDirectionCell;
