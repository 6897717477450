import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";
import { conditionPresetOperatorMap } from "./OperatorSelect";
import { USER_CONFIGURED_OPERATOR } from "../../../../../../models/Entities";
import clsx from "clsx";

type UserConfiguredPlaceholderProps = {
  disabled?: boolean;
};

const UserConfiguredPlaceholder = ({ disabled }: UserConfiguredPlaceholderProps) => {
  return (
    <div className={clsx("text-gray-60 flex flex-1", { "bg-gray-0": disabled })}>
      <Text className="text-gray-60 flex flex-1 pl-3">
        {conditionPresetOperatorMap[USER_CONFIGURED_OPERATOR].title}
      </Text>
    </div>
  );
};

export default UserConfiguredPlaceholder;
