import React from "react";
import { APITesterLinkedAccount, LinkedAccount } from "../../models/Entities";
import { Badge } from "@merge-api/merge-javascript-shared";

type Props = {
  /**
   * The account to render
   */
  linkedAccount: LinkedAccount | APITesterLinkedAccount;
  isFullText?: boolean;
};

/**
 * Either a pink or purple badge, depending on the account type
 */
const AccountTypeBadge = ({ linkedAccount, isFullText }: Props) => {
  const fullText = `${linkedAccount.is_test_account ? "Test" : "Production"} Linked Account`;

  return (
    <Badge
      color={linkedAccount.is_test_account ? "pink" : "purple"}
      className="flex items-center whitespace-nowrap"
    >
      {isFullText ? fullText : fullText.split(" ")[0]}
    </Badge>
  );
};

export default AccountTypeBadge;
