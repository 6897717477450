import React, { useState, useMemo, useEffect } from "react";
import useAppContext from "../../../../context/useAppContext";
import { ArrowLeft, MoreVertical } from "lucide-react";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";
import {
  deleteOverriddenCommonModelInstance,
  editOverriddenCommoModelInstance,
  createOverriddenCommonModelInstance,
} from "../../../configuration/field-mappings/utils/FieldMappingUtils";
import useLinkedAccountCustomMappingsContext from "./context/useLinkedAccountFieldMappingsContext";
import {
  OverriddenCommonModelInstance,
  FieldMappingCreationAndEditDict,
  FieldMappingOptionsResponse,
  LinkedAccount,
} from "../../../../../models/Entities";
import { filterFieldMappingOptions } from "../../../configuration/field-mappings/utils/FieldMappingUtils";
import {
  Typeahead,
  Button,
  ButtonVariant,
  MenuItem,
  Dropdown,
} from "@merge-api/merge-javascript-shared";
import EditJmesPathModal from "./modal/field-mappings/EditJmesPathModal";
import { ArrowUpRight } from "lucide-react";
import get from "lodash/get";
import FieldMappingDropdownChild from "../../../../shared/FieldMappingDropdownChild";
import FieldMappingsGridContainer from "./components/FieldMappingsGridContainer";
import FieldMappingCard from "./components/FieldMappingCard";
import FieldMappingCardRemoteFieldsSubtitle from "./components/FieldMappingCardRemoteFieldsSubtitle";
import FieldMappingRemoteFieldsBadge from "./components/FieldMappingRemoteFieldsBadge";
import FieldMappingTargetBadge from "./../../../../shared/FieldMappingTargetBadge";
import { navigateToIndividualCommonModelOverrideTargetPage } from "./../../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";

type FieldMappingOverrideRowProps = {
  linkedAccount: LinkedAccount;
  commonModelOverrideInstance: OverriddenCommonModelInstance;
  exampleValue?: string | null;
  fieldMappingOptions?: FieldMappingOptionsResponse;
};

const FieldMappingOverrideRow = ({
  linkedAccount,
  exampleValue,
  commonModelOverrideInstance,
  fieldMappingOptions,
}: FieldMappingOverrideRowProps) => {
  const { user } = useAppContext();
  const history = useHistory();
  const isAdvancedMappingEnabled = user?.is_field_mapping_advanced_enabled || false;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedOriginField, setSelectedOriginField] = useState<
    FieldMappingCreationAndEditDict | undefined
  >(undefined);

  const [commonModelOverride, setCommonModelOverride] = useState<OverriddenCommonModelInstance>(
    commonModelOverrideInstance,
  );
  const [filteredFieldMappingOptions, setFilteredFieldMappingOptions] = useState<
    FieldMappingCreationAndEditDict[]
  >([]);

  const { refreshFieldMappings: refreshCustomMappings } = useLinkedAccountCustomMappingsContext();

  const isDateTimeOverride =
    commonModelOverride?.overridden_field_definition?.format == "date-time";

  useEffect(() => {
    if (fieldMappingOptions) {
      const filteredFieldMappingOptions = availableFieldMappings
        ? filterFieldMappingOptions(
            commonModelOverride.common_model_name,
            availableFieldMappings,
            commonModelOverride.overridden_field_definition?.type || null,
            isDateTimeOverride,
          )
        : [];
      setFilteredFieldMappingOptions(filteredFieldMappingOptions);
      const originField = filteredFieldMappingOptions.find(
        (fieldMappingOption) =>
          fieldMappingOption.traversal_path.split(".").toString() ===
          commonModelOverride.field_traversal_path.toString(),
      );
      setSelectedOriginField(originField);
    }
  }, [fieldMappingOptions]);

  const availableFieldMappings = fieldMappingOptions?.remote_field_options;

  const jmesPath = get(commonModelOverride, "jmes_path");
  const hasOptionChanged =
    selectedOriginField &&
    get(selectedOriginField, "traversal_path", "").split(".").toString() !==
      commonModelOverride.field_traversal_path.toString();

  const [editingAdvancedMappingPath, setEditAdvancedMappingPath] = useState(false);

  const remoteFieldType = get(selectedOriginField, "type", "");
  const isAdvancedMappingType = remoteFieldType.includes("list") || remoteFieldType == "dict";

  const onDeleteSuccess = () => {
    refreshCustomMappings();
    showSuccessToast("Sucessfully deleted override!");
  };

  const editFieldMapping = (
    selectedOverride: FieldMappingCreationAndEditDict,
    jmesPath: string | null = null,
  ) => {
    const patchBody = {
      api_endpoint_id: selectedOverride.api_endpoint_id,
      field_traversal_path: selectedOverride.traversal_path.split("."),
      display_name: selectedOverride?.display_name,
      origin_type: selectedOverride.type,
      ...(jmesPath ? { jmes_path: jmesPath } : {}),
    };

    const onEditSuccess = (data: OverriddenCommonModelInstance) => {
      setCommonModelOverride(data);

      setIsEditing(false);
      showSuccessToast("Updated Common Model override!");
    };

    editOverriddenCommoModelInstance(commonModelOverride.id, patchBody, onEditSuccess, () => {
      showErrorToast("Unable to update Common Model override");
    });
  };

  const createLinkedAccountOverride = (
    selectedOverride: FieldMappingCreationAndEditDict,
    jmesPath: string | null = null,
  ) => {
    const postBody = {
      linked_account_id: linkedAccount.id,
      override_common_model_target_id: commonModelOverride.override_common_model_target.id,
      field_traversal_path: selectedOverride.traversal_path.split("."),
      api_endpoint_id: selectedOverride.api_endpoint_id,
      display_name: selectedOverride?.display_name,
      origin_type: selectedOverride?.type,
      ...(jmesPath ? { jmes_path: jmesPath } : {}),
    };
    const onSuccessSave = (data: OverriddenCommonModelInstance) => {
      setCommonModelOverride(data);
      setIsEditing(false);
      showSuccessToast("Created Linked Account override!");
    };

    const onError = () => {
      showErrorToast("Unable to update Common Model override");
      setIsEditing(false);
    };
    createOverriddenCommonModelInstance(postBody, onSuccessSave, onError);
  };

  const typeahead = useMemo(() => {
    return (
      <Typeahead
        value={selectedOriginField}
        options={filteredFieldMappingOptions}
        onChange={(_, selectedOverride: any) => {
          setSelectedOriginField(selectedOverride);
        }}
        getOptionLabel={(option: any) => {
          return option?.display_name ?? "";
        }}
        renderOption={(fieldMapping: FieldMappingCreationAndEditDict) => {
          return <FieldMappingDropdownChild option={fieldMapping} />;
        }}
        loadingText="Loading fields..."
        loading={availableFieldMappings == undefined}
      />
    );
  }, [fieldMappingOptions, selectedOriginField]);

  return (
    <>
      {editingAdvancedMappingPath && isAdvancedMappingType && selectedOriginField && (
        <EditJmesPathModal
          currentJmesPath={hasOptionChanged ? null : jmesPath}
          jsonData={selectedOriginField.value}
          remoteFieldDisplayName={selectedOriginField.display_name}
          onModalClose={() => {
            setEditAdvancedMappingPath(false);
          }}
          onUpdate={(jmesPath: string) => {
            if (!commonModelOverride.is_integration_wide) {
              editFieldMapping(selectedOriginField, jmesPath);
            } else {
              createLinkedAccountOverride(selectedOriginField, jmesPath);
            }
            // updateCustomMappingInstance(originFields, jmesPath);
            setEditAdvancedMappingPath(false);
          }}
        />
      )}

      <FieldMappingsGridContainer hasDropdown>
        <>
          <FieldMappingCard
            displayName={commonModelOverride.overridden_field}
            description={commonModelOverride.overridden_field_definition?.description}
            badge={<FieldMappingTargetBadge type="common-model-override-field" />}
            onClick={
              commonModelOverride.is_integration_wide ||
              commonModelOverride.is_integration_wide_override_mapping ||
              !commonModelOverride.remote_endpoint_path
                ? () =>
                    navigateToIndividualCommonModelOverrideTargetPage(
                      history,
                      commonModelOverride?.override_common_model_target?.id ||
                        commonModelOverride.id,
                    )
                : undefined
            }
          />
          <div className="flex justify-center items-center h-full">
            <ArrowLeft size={20} />
          </div>
          {isEditing ? (
            <div className="flex flex-col w-100 gap-y-3">
              {typeahead}
              {isAdvancedMappingType && isAdvancedMappingEnabled && (
                <>
                  {hasOptionChanged ? (
                    <Button
                      size="sm"
                      variant={ButtonVariant.SecondaryBlue}
                      onClick={() => {
                        setEditAdvancedMappingPath(true);
                      }}
                      className="ml-auto"
                      rightIcon={<ArrowUpRight size={12} />}
                    >
                      Advanced mapping
                    </Button>
                  ) : (
                    <div className="flex items-center py-2 px-3  justify-between bg-blue-0 rounded-md text-sm ">
                      <div className="text-wrap">{jmesPath}</div>
                      <Button
                        variant={ButtonVariant.TextBlue}
                        size="sm"
                        rightIcon={<ArrowUpRight size={12} />}
                        onClick={() => {
                          setEditAdvancedMappingPath(true);
                        }}
                      >
                        Advanced mapping
                      </Button>
                    </div>
                  )}
                </>
              )}
              <div className="flex flex-row items-center justify-content-end gap-x-3">
                <Button
                  size="sm"
                  variant={ButtonVariant.TextBlack}
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  variant={ButtonVariant.TertiaryWhite}
                  className={`text-sm shadow-sm p-3 pt-1 pb-1 ml-2 rounded font-semibold ${
                    selectedOriginField
                      ? "cursor-pointer"
                      : "cursor-not-allowed opacity-50 pointer-events-none"
                  }`}
                  onClick={() => {
                    if (selectedOriginField) {
                      if (!commonModelOverride.is_integration_wide) {
                        editFieldMapping(selectedOriginField);
                      } else {
                        createLinkedAccountOverride(selectedOriginField);
                      }
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <FieldMappingCard
              displayName={
                commonModelOverride?.display_name
                  ? commonModelOverride.display_name
                  : commonModelOverride.field_traversal_path &&
                    commonModelOverride.field_traversal_path.length > 0
                  ? commonModelOverride.field_traversal_path[
                      commonModelOverride.field_traversal_path.length - 1
                    ]
                  : ""
              }
              description={
                <FieldMappingCardRemoteFieldsSubtitle
                  exampleValue={exampleValue}
                  jmesPath={jmesPath}
                  remoteEndpointMethod={commonModelOverride.remote_endpoint_method}
                  remoteEndpointPath={commonModelOverride.remote_endpoint_path}
                />
              }
              badge={
                <FieldMappingRemoteFieldsBadge
                  isOrganizationWide={commonModelOverride.is_integration_wide}
                  linkedAccount={linkedAccount}
                />
              }
              type={commonModelOverride.origin_type}
            />
          )}

          <div className="flex items-center mx-1">
            <Dropdown
              ButtonProps={{
                children: <MoreVertical size={16} />,
                variant: ButtonVariant.IconShadowHidden,
                className: "p-0",
                size: "sm",
              }}
              menuPlacement="bottom-end"
            >
              {fieldMappingOptions && availableFieldMappings && (
                <MenuItem onClick={() => setIsEditing(true)}>Edit mapping</MenuItem>
              )}
              {!commonModelOverride.is_integration_wide && (
                <MenuItem
                  onClick={() => {
                    deleteOverriddenCommonModelInstance(
                      commonModelOverride.id,
                      onDeleteSuccess,
                      () => {
                        showErrorToast("Unable to delete Common Model override");
                      },
                    );
                  }}
                >
                  Delete mapping
                </MenuItem>
              )}
            </Dropdown>
          </div>
        </>
      </FieldMappingsGridContainer>
    </>
  );
};
export default FieldMappingOverrideRow;
