import React, { useCallback } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { updateOrganizationIntegrationSettings } from "../../../../api-client/organization/OrganizationIntegrationSettingsAPIClient";
import { BlueprintSpeed, OrganizationIntegrationSettings } from "../../../../models/Entities";
import { displayNameForBlueprintSpeed } from "../../../../models/Helpers";
import { ProductRestrictions } from "../../settings/billing/BillingModels";
import useProductRestrictions from "../../../shared/hooks/useProductRestrictions";
import MultiSwitch from "../../../shared/MultiSwitch";
import { BLUEPRINT_SPEED_COLORS } from "./IntegrationSettingsRowOverview";
import { showErrorToast } from "../../../shared/Toasts";
import IntegrationSettingsSectionContainer from "./IntegrationSettingsSectionContainer";
import { palette } from "../../../../styles/theme/colors";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import { Tooltip } from "@merge-api/merge-javascript-shared";

interface Props {
  /**
   * All settings for this integration
   */
  settings: OrganizationIntegrationSettings;

  /**
   * Something happens when we toggle the integration - this dictates it!
   */
  onIntegrationToggle: (data: OrganizationIntegrationSettings) => void;
}

// Items are vertically aligned to the center
export const VerticallyCenteredCol = styled(Col)`
  display: grid;
  align-content: center;
`;

// Items are right aligned on mobile only
const RightAlignedCol = styled(VerticallyCenteredCol)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 767.96px) {
    display: initial;
    margin-top: 8px;
  }
`;

// For use within a section
export const SmallHeader = styled(DeprecatedH4)`
  color: var(--gray80);
  font-size: 13px;
  line-height: 16px;
`;

// Underlines some text in a span
const HelpText = styled.span`
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
`;

const StyledMultiSwitch = styled(MultiSwitch)`
  button {
    font-size: 12px;
    line-height: 16px;
    opacity: ${(props) => (props.isDisabled ? "30%" : "100%")};
  }
`;

// All speeds we support
const SPEEDS = [BlueprintSpeed.SLOW, BlueprintSpeed.INTERMEDIATE, BlueprintSpeed.FAST];

/**
 * Creates an option for a given speed from speed + restrictions
 */
const optionForSpeed = (
  speed: BlueprintSpeed,
  isCustomSpeedSet: boolean,
  restrictions?: ProductRestrictions,
) => {
  const isDisabled =
    restrictions === undefined ||
    !restrictions.available_sync_speeds.includes(speed) ||
    isCustomSpeedSet;
  const toolTipMessage = isCustomSpeedSet
    ? "Your organization has a custom agreement in place with Merge. To modify sync frequency, please contact us."
    : "Upgrade your plan to unlock higher sync frequencies";
  return {
    text: displayNameForBlueprintSpeed(speed),
    id: speed,
    selectedColor: BLUEPRINT_SPEED_COLORS[speed],
    disable: isDisabled,
    disableTooltip: toolTipMessage,
  };
};

/**
 * Shows a section for the sync frequency & any upsells that exist
 */
const IntegrationSyncFrequencySection = ({ settings, onIntegrationToggle }: Props) => {
  const { productRestrictions } = useProductRestrictions();
  const hasCustomBlueprintSpeed = settings.blueprint_speed === BlueprintSpeed.CUSTOM;
  const speedChoices = hasCustomBlueprintSpeed ? SPEEDS.concat([BlueprintSpeed.CUSTOM]) : SPEEDS;

  const setBlueprintSpeed = useCallback(
    (newSpeed: BlueprintSpeed) => {
      updateOrganizationIntegrationSettings({
        settingsId: settings.id,
        body: {
          blueprint_speed: newSpeed,
        },
        onUpdate: (data: OrganizationIntegrationSettings) => {
          onIntegrationToggle(data);
        },
        onError: () => {
          showErrorToast(`Failed to update sync frequency for ${settings.integration.name}`);
        },
      });
    },
    [updateOrganizationIntegrationSettings, onIntegrationToggle, settings.id],
  );

  return (
    <IntegrationSettingsSectionContainer id="sync-frequency">
      <>
        <VerticallyCenteredCol sm={12} md={9} style={{ justifyContent: "flex-start" }}>
          <Tooltip
            title="A higher frequency instructs Merge to make data sync requests to third party APIs
                more often. The cadence and number of requests varies by integration depending on
                each API's intricacies."
          >
            <span color={palette.black} className="font-medium	text-sm	 d-inline-block font-medium">
              <i className="mr-3 font-medium fe fe-repeat" />
              <HelpText>Sync frequency</HelpText>
            </span>
          </Tooltip>
        </VerticallyCenteredCol>
        <RightAlignedCol sm={12} md={3}>
          <StyledMultiSwitch
            options={speedChoices.map((speed) =>
              optionForSpeed(speed, hasCustomBlueprintSpeed, productRestrictions),
            )}
            isDisabled={hasCustomBlueprintSpeed}
            selectedID={settings.blueprint_speed}
            onSelectOption={(option) =>
              setBlueprintSpeed(BlueprintSpeed[option.id as keyof typeof BlueprintSpeed])
            }
          />
        </RightAlignedCol>
      </>
    </IntegrationSettingsSectionContainer>
  );
};

export default IntegrationSyncFrequencySection;
