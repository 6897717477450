import classNames from "classnames";
import React from "react";
import { Badge as ReactBadge } from "react-bootstrap";
import { APICategory, HTTPMethod } from "../../models/Entities";
import { snakeCaseToTitleCase } from "../../services";
import { IntegrationIssueStatus } from "../pages/IntegrationsManagementEntities";
import { MergeChargeStatus } from "../pages/settings/billing/BillingModels";
import HttpStatusCode from "./utils/HttpStatusCode";
import { Badge } from "@merge-api/merge-javascript-shared";
import { BaseColor } from "@merge-api/merge-javascript-shared/dist/designSystem/types";

interface IssuesStatusBadgeProps {
  status: IntegrationIssueStatus;
  muted?: boolean;
  className?: string;
}

export const IssuesStatusBadge = ({ status, muted, className }: IssuesStatusBadgeProps) => {
  return (
    <span
      className={classNames(
        "badge font-medium",
        muted
          ? "badge-soft-secondary"
          : status == IntegrationIssueStatus.ONGOING
          ? "badge-soft-danger"
          : "badge-soft-success",
        className,
      )}
    >
      {status.charAt(0).toUpperCase() + status.substr(1).toLowerCase()}
    </span>
  );
};

type ResponseCodeBadgeProps = {
  responseCode: number;
  size?: "sm" | "md" | "lg" | undefined;
};

export const ResponseCodeBadge = ({ responseCode, size = "md" }: ResponseCodeBadgeProps) => {
  const isOKResponse = responseCode >= 200 && responseCode <= 299;
  const text =
    responseCode === 200
      ? "200 OK"
      : String(responseCode) + " " + snakeCaseToTitleCase(HttpStatusCode[responseCode]);

  return (
    <Badge color={isOKResponse ? "teal" : "red"} size={size}>
      {text}
    </Badge>
  );
};

type MergeChargeStatusBadgeProps = {
  status?: MergeChargeStatus | null;
  className?: string;
};
export const MergeChargeStatusBadge = ({ status, className }: MergeChargeStatusBadgeProps) => {
  if (!status) return null;
  switch (status) {
    case MergeChargeStatus.CHARGE_STATUS_FAILURE:
      return <span className={classNames("badge badge-soft-danger", className)}>Failed</span>;
    case MergeChargeStatus.CHARGE_STATUS_OVERDUE:
      return <span className={classNames("badge badge-soft-danger", className)}>Overdue</span>;
    case MergeChargeStatus.CHARGE_STATUS_SUSPENDED:
      return <span className={classNames("badge badge-soft-dark", className)}>Suspended</span>;
    case MergeChargeStatus.CHARGE_STATUS_PENDING:
      return <span className={classNames("badge badge-soft-danger", className)}>Not paid</span>;
    case MergeChargeStatus.CHARGE_STATUS_SUCCESS:
      return <span className={classNames("badge badge-soft-success", className)}>Paid</span>;
    case MergeChargeStatus.CHARGE_STATUS_WAIVED:
      return <span className={classNames("badge badge-soft-success", className)}>Waived</span>;
    default:
      return null;
  }
};

type HTTPMethodBadgeProps = {
  method: HTTPMethod | string;
  size?: "sm" | "md" | "lg" | undefined;
};

export const HTTPMethodBadge = ({ method, size = "md" }: HTTPMethodBadgeProps) => {
  let color;

  switch (method) {
    case HTTPMethod.GET:
      color = "blue";
      break;
    case HTTPMethod.POST:
      color = "teal";
      break;
    case HTTPMethod.PUT:
      color = "teal";
      break;
    case HTTPMethod.DELETE:
      color = "red";
      break;
    case HTTPMethod.PATCH:
      color = "gray";
      break;
    case HTTPMethod.OPTIONS:
      color = "purple";
      break;
    case HTTPMethod.HEAD:
      color = "yellow";
      break;
    case "FETCH":
      color = "blue";
      break;
    default:
      color = "blue";
      break;
  }

  return (
    <Badge color={color as BaseColor} size={size}>
      {method}
    </Badge>
  );
};

/**
 * Badge for categories
 */
type CommonModelBadgeProps = {
  commonModel: string;
  color?: string;
  background?: string;
};

export const CommonModelBadge = ({ commonModel, color, background }: CommonModelBadgeProps) => {
  let style = "light";
  const category = commonModel.split(".")[0];
  if (!color && !background) {
    switch (category) {
      case APICategory.hris:
        style = "soft-primary";
        break;
      case APICategory.ats:
        style = "soft-secondary";
        break;
      case APICategory.accounting:
        style = "soft-success";
        break;
      case APICategory.ticketing:
        style = "soft-danger";
        break;
      case APICategory.crm:
        style = "soft-warning";
        break;
      case APICategory.mktg:
        style = "soft-warning";
        break;
      case APICategory.filestorage:
        style = "soft-warning";
        break;
      default:
      // If this fails, we are not supporting the category
    }
  }

  return (
    <ReactBadge variant={style} style={{ color: color, background: background }} className="mr-2">
      {commonModel}
    </ReactBadge>
  );
};
