import React from "react";
import OrganizationSettingsCard from "./cards/OrganizationSettingsCard";
import OrganizationMembersCard from "./cards/OrganizationMembersCard/OrganizationMembersCard";

const OrganizationPage = () => {
  return (
    <>
      <OrganizationSettingsCard />
      <OrganizationMembersCard />
    </>
  );
};

export default OrganizationPage;
