import React from "react";
import { Integration } from "../../../../models/Entities";
import { APIRequestLogEntryIntegration } from "../../IntegrationsManagementEntities";
import { OrganizationAvatar, UserAvatarSize } from "../../../shared/MergeAvatars";
import { Text } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";

interface Props {
  textVariant?: string;
  integration: Integration | APIRequestLogEntryIntegration;
  customText?: string;
  isMuted?: boolean;
}

const IntegrationNameAndLogo = ({ textVariant, integration, customText, isMuted }: Props) => {
  return (
    <div className="flex flex-row items-center min-w-0 whitespace-nowrap gap-x-2">
      <OrganizationAvatar
        size={UserAvatarSize.xs}
        imageURL={integration.square_image}
        organizationName={
          integration.abbreviated_name ? integration.abbreviated_name : integration.name
        }
        isCircle
        className={clsx(isMuted && "opacity-50")}
      />
      <div className="truncate">
        {/*@ts-ignore need to input textVariant, but we dont have a type for this*/}
        <Text as="span" className={clsx(isMuted && "text-gray-60")} variant={textVariant || "md"}>
          {customText || integration.abbreviated_name
            ? integration.abbreviated_name
            : integration.name}
        </Text>
      </div>
    </div>
  );
};

export default IntegrationNameAndLogo;
