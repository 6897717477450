import { Badge } from "@merge-api/merge-javascript-shared";
import { AuthType, LinkedAccount } from "../../../../../../models/Entities";
import React from "react";
import { ArrowLeftRight, Link } from "lucide-react";

import SkeletonLoader from "../../../../../shared/SkeletonLoader";
import { LinkedAccountStatuses } from "../../../../../../constants";
import LinkedAccountStatusBadge from "../../../shared/LinkedAccountStatusBadge";

interface TitleContainerProps {
  linkedAccount: LinkedAccount | null;
  isDataWarehouseAccount: boolean;
}

const LinkedAccountTitleContainer: React.FC<TitleContainerProps> = ({
  linkedAccount,
  isDataWarehouseAccount,
}) => {
  return (
    <div className="flex items-center justify-between flex-wrap gap-y-4">
      <div className="flex items-center flex-grow d-flex gap-3 min-w-[250px] text-[28px]">
        {linkedAccount ? (
          <div className="text-truncate-flex flex-shrink-1 flex items-center">
            {linkedAccount?.end_user?.organization_name}
          </div>
        ) : (
          <SkeletonLoader width={160} height={24} borderRadius={4} />
        )}

        {isDataWarehouseAccount ? (
          <Link className="flex-shrink-0" />
        ) : (
          <ArrowLeftRight className="flex-shrink-0" />
        )}

        {linkedAccount ? (
          <>
            <div className="flex-shrink-0">{linkedAccount?.integration?.name}</div>
            {linkedAccount?.auth_type === AuthType.SFTP && (
              <Badge className="ml-1.5 items-center gap-1" color="purple" size="lg">
                SFTP
              </Badge>
            )}
          </>
        ) : (
          <SkeletonLoader width={160} height={24} borderRadius={4} />
        )}
        {linkedAccount && linkedAccount.status === LinkedAccountStatuses.RELINK_NEEDED && (
          <LinkedAccountStatusBadge linkedAccount={linkedAccount} size="lg" />
        )}
      </div>
    </div>
  );
};

export default LinkedAccountTitleContainer;
