import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionContext, Card, Col, Row, useAccordionToggle } from "react-bootstrap";
import styled from "styled-components";
import { APICategory, Badge, Tooltip } from "@merge-api/merge-javascript-shared";
import { Result } from "../../../../api-client/APIClient";
import { palette } from "../../../../styles/theme";
import { ProductRestrictions } from "../../settings/billing/BillingModels";
import CenteredVerticalLineCol from "./CenteredVerticalLineCol";
import {
  getRelatedFieldFromField,
  getRelatedModelFromField,
  SortedFieldsMap,
  SortedModelsMap,
} from "./CommonModelUtils";
import FieldToggle, {
  Disableability,
  Enableability,
  RemoteDataToggle,
  RemoteFieldsToggle,
} from "./FieldToggle";
import AnimatedChevron from "./AnimatedChevron";
import SubobjectFieldsGroup from "./SubobjectFieldsGroup";
import {
  createUpdatedIsDescriptionShowingMap,
  IsDescriptionShowingMap,
} from "./CommonModelToggleTypes";
import { EnabledAction, ScopeOption } from "../../../../models/CommonModel";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import { Lock, UnlockKeyhole } from "lucide-react";
import { replaceBackticksWithCode, getExternalScopeActionName } from "../../../../services";
import { DeprecationStage } from "../../../../constants";
import { getSanitizedHTML } from "../../../../models/Helpers";
import useDeprecatedCommonModel from "../../../../hooks/useDeprecatedCommonModel";
import ViewOnlyModelCapability from "./ViewOnlyModelCapability";
import MultiSwitch from "../../../shared/MultiSwitch";
import ScopeConfirmationDialog from "./ScopeConfirmationDialog";

interface CommonModelToggleProps {
  /**
   * We call this to update the Common Model or Common Model field enabled status on the backend to a new value
   *
   * @param modelName The name of the model
   * @param fieldName The field of the model, or `null` if the model itself is being modified
   * @param isEnabled Whether the model or field should be enabled or not
   */
  updateEnabledStatus: (
    modelName: string,
    fieldName: string | null,
    enabledActions: Array<EnabledAction>,
    optionalActionsToEnable: Array<EnabledAction>,
    optionalActionsToDisable: Array<EnabledAction>,
    isFieldConfigurable?: boolean,
  ) => Promise<Result<void>>;
  models: SortedModelsMap;

  /**
   * The model to show here
   */
  modelName: string;
  /**
   * Product restrictions for the org
   */
  productRestrictions?: ProductRestrictions;
  /**
   * The state maintained for showing the Upsell Modal
   */
  setShowUpsellModal?: (isOpen: boolean) => void;

  category: APICategory;
  isViewing: boolean;
  onSwitch: (selectedViewing: boolean) => void;
  areOptionalScopesEnabled: boolean;
  endUserConfigurableModels: Set<string>;
  canToggleOrgScopesOnly: boolean;
  isDefaultScopesOffFlag?: boolean;
  dependentModelScopesFlag?: boolean;
}

// Has a background on hover
const HoverableRow = styled(Row).attrs({
  className: "flex-nowrap ml-0 mr-0 items-center",
})``;

const MaybeCenteredVerticalLineCol = styled(CenteredVerticalLineCol)<{
  $showLine: boolean;
}>`
  ${({ $showLine }) => ($showLine ? "background-size: 1px 100%" : "background-size: 1px 0%")};

  background-position: center 44px;
`;

const CardBody = styled(Card.Body)`
  padding: 0px;
`;

const IconContainer = styled.div.attrs({
  className: "avatar-sm avatar-title rounded-circle",
})`
  margin: 0px 25px;
  color: ${palette.black};
`;

const Title = styled(DeprecatedH4)<{ $hasDescription: boolean }>`
  font-size: 20px;
  line-height: 28px;
  color: ${palette.black};
  ${({ $hasDescription }) =>
    $hasDescription && `border-bottom: 0.5px dashed ${palette.gray}; cursor: help;`}
`;

const Fields = styled.div`
  background-color: #fcfdff;
`;

const RelationLink = styled.a<{ $shrinkTopMargin: boolean | undefined }>`
  transition: margin-top 0.3s;
  ${({ $shrinkTopMargin }) => $shrinkTopMargin && "margin-top: -6px;"}
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 24px;
`;

interface FieldStatusRowProps {
  isExpanded: boolean;
  fields: SortedFieldsMap;
  isViewing: boolean;
  areOptionalScopesEnabled: boolean;
  endUserConfigurableModels: Set<string>;
}

const FieldStatusStyledRow = styled(Row).attrs({
  className: "font-semibold mr-0 ml-0",
})`
  font-size: 12px;
  line-height: 18px;
`;

interface ToggleProps {
  setIsExpanded: (expanded: boolean) => void;
  children: JSX.Element;
  eventKey: string;
}

const ToggleButton = styled.div`
  border-top: 1px solid ${palette.border};

  cursor: pointer;
  background-color: #f3f5f8;
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: ${palette.border};
  }
  &:active {
    background-color: ${palette.empty};
  }
`;

function ToggleRow({ setIsExpanded, children, eventKey }: ToggleProps) {
  const currentEventKey = useContext(AccordionContext);
  const onClick = useAccordionToggle(eventKey, () => setIsExpanded(currentEventKey !== eventKey));

  return (
    <ToggleButton className="py-2 pl-6 pr-[29px]" onClick={onClick}>
      {children}
    </ToggleButton>
  );
}
const FieldDescriptionCol = styled(Col).attrs({ className: "col-auto" })``;

const FieldStatusRow = ({
  isExpanded,
  fields,
  isViewing,
  areOptionalScopesEnabled,
  endUserConfigurableModels,
}: FieldStatusRowProps) => {
  let enabledCount = 0,
    disabledCount = 0,
    optionalFieldCount = 0;
  Object.values(fields).forEach((field) => {
    if (field.is_enabled) {
      enabledCount += 1;
    } else {
      disabledCount += 1;
    }
    if (field.is_end_user_configurable) {
      optionalFieldCount += 1;
    } else if (field.related_to && endUserConfigurableModels.has(field.related_to)) {
      optionalFieldCount += 1;
    }
  });
  const pluralizeField = (count: number, totalNumber: number) =>
    `${count.toString()}/${totalNumber.toString()} ${totalNumber === 1 ? " field" : " fields"}`;

  const notExpandedText = isViewing ? "View fields " : "Configure fields";

  return (
    <FieldStatusStyledRow>
      <div className="flex items-center">
        <div>{pluralizeField(enabledCount, enabledCount + disabledCount)} enabled</div>
        {areOptionalScopesEnabled && (
          <div className="text-gray-60 text-sm font-semibold ml-6">
            {pluralizeField(optionalFieldCount, enabledCount + disabledCount)} optional
          </div>
        )}
      </div>
      <Col className="d-flex justify-content-end text-gray-90 text-sm px-0">
        <span>{isExpanded ? "Collapse fields" : notExpandedText}</span>
        <AnimatedChevron $isUp={isExpanded} className="ml-[3px]" />
      </Col>
    </FieldStatusStyledRow>
  );
};

export const getCommonModelToggleDisplayInfo = (
  isDisabled: boolean,
  isConfigurable: boolean,
  areOptionalScopesEnabled: boolean,
) => {
  if (isDisabled) {
    return <Lock size={16} className="opacity-35 cursor-help pb-[1px]" />;
  } else if (!isConfigurable || !areOptionalScopesEnabled) {
    return <Lock size={16} className="text-teal-50 cursor-help pb-[1px]" />;
  } else {
    return <UnlockKeyhole size={16} className="text-yellow-50 cursor-help pb-[1px]" />;
  }
};
export const getFieldOverlayText = (
  isEnabled: boolean,
  isConfigurable: boolean,
  areOptionalScopesEnabled: boolean,
) => {
  if (areOptionalScopesEnabled && isConfigurable) {
    return "Optional - your users can choose to toggle this field in Merge Link";
  } else if (isEnabled) {
    return "This field is Enabled";
  } else {
    return "This field is Disabled";
  }
};

export const getScopeOptions = (isField = false) => {
  return [
    {
      text: "Enabled",
      id: "enabled",
      selectedColor: palette.black,
      backgroundColor: "bg-gray-0",
      disableTooltip:
        "Field toggles are only available for customers on our Professional and Enterprise plans",
    },
    {
      text: "Disabled",
      id: "disabled",
      selectedColor: "bg-black",
      backgroundColor: "bg-gray-0",
      disableTooltip:
        "Field toggles are only available for customers on our Professional and Enterprise plans",
    },
    {
      text: "Optional",
      id: "optional",
      selectedColor: palette.black,
      backgroundColor: "bg-gray-0",
      tooltipText: `This ${
        isField ? "field" : "model"
      } will be enabled by default but your customers can choose to disable it in Merge Link`,
    },
  ];
};

/**
 * Creates a card for toggling
 *  Common Model under the configuration pages
 */
const CommonModelToggle = ({
  updateEnabledStatus,
  modelName,
  models,
  productRestrictions,
  setShowUpsellModal,
  category,
  isViewing,
  onSwitch,
  areOptionalScopesEnabled,
  endUserConfigurableModels,
  canToggleOrgScopesOnly,
  isDefaultScopesOffFlag,
  dependentModelScopesFlag,
}: CommonModelToggleProps) => {
  // state
  const [_, setIsSaving] = useState(false);
  const [isDescriptionShowingMap, setIsDescriptionShowingMap] = useState<IsDescriptionShowingMap>(
    {},
  );
  const [isExpanded, setIsExpanded] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState("");
  const [currentCapability, setCurrentCapability] = useState(EnabledAction.READ);

  const handleOptionSelect = (optionId: string, capability: EnabledAction) => {
    if (!isDefaultScopesOffFlag && !canToggleFields) {
      if (setShowUpsellModal) {
        setShowUpsellModal(true);
      }
      return;
    }

    // Store option and open modal
    setCurrentOption(optionId);
    setCurrentCapability(capability);
    setModalOpen(true);
  };

  const confirmAction = () => {
    if (!isDefaultScopesOffFlag && !canToggleFields) {
      if (setShowUpsellModal) {
        setShowUpsellModal(true);
      }
      return;
    }

    const isOptional = currentOption == ScopeOption.OPTIONAL;

    const isEnabling = currentOption == ScopeOption.ENABLED || isOptional;
    const actionsToEnable = isEnabling ? [currentCapability] : [];

    // get other enabled model actions
    if (
      currentCapability == EnabledAction.READ &&
      commonModel.enabled_model_actions.includes(EnabledAction.WRITE as EnabledAction)
    ) {
      actionsToEnable.push(EnabledAction.WRITE);
    }
    if (
      currentCapability == EnabledAction.WRITE &&
      commonModel.enabled_model_actions.includes(EnabledAction.READ)
    ) {
      actionsToEnable.push(EnabledAction.READ);
    }

    // Checking if something is optional
    const optionalActionsToEnable = isOptional ? [currentCapability] : [];
    // Checking if something is enabled but not optional
    const optionalActionsToDisable =
      !isEnabling || (isEnabling && !isOptional) ? [currentCapability] : [];

    changeCommonModelScope(
      modelName,
      null,
      actionsToEnable as Array<EnabledAction>,
      optionalActionsToEnable,
      optionalActionsToDisable,
    );

    // Close the modal
    setModalOpen(false);
  };

  const commonModel = models[modelName];
  const canToggleFields = !!productRestrictions?.are_toggles_enabled;
  const shouldClearIsDescriptionShowingMap =
    !isExpanded || !commonModel.enabled_model_actions.length;

  // hooks
  const { showDeprecatedSunsetItems, deprecationStage, deprecationMessage, fields } =
    useDeprecatedCommonModel({
      category,
      commonModel: modelName,
    });

  // effects
  useEffect(() => setIsDescriptionShowingMap({}), [shouldClearIsDescriptionShowingMap]);

  useEffect(() => {
    const hash = window.location.hash;

    if (hash) {
      const element = document.getElementById(hash.substring(1));

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  // Calls our API with a new value for the Common Model
  const changeCommonModelScope = async (
    modelName: string,
    fieldName: string | null,
    enabledActions: Array<EnabledAction>,
    optionalActionsToEnable: Array<EnabledAction>,
    optionalActionsToDisable: Array<EnabledAction>,
    isOptional?: boolean,
  ) => {
    setIsSaving(true);
    await updateEnabledStatus(
      modelName,
      fieldName,
      enabledActions,
      optionalActionsToEnable,
      optionalActionsToDisable,
      isOptional,
    );

    setIsSaving(false);
  };
  const remoteDataField = commonModel.fields["remote_data"];
  const remoteFieldsField = commonModel.fields["remote_fields"];

  const isCommonModelEnabled = commonModel.enabled_model_actions.length > 0;

  // hide if in sunset stage of deprecation, (unless org override)
  if (deprecationStage === DeprecationStage.SUNSET && !showDeprecatedSunsetItems) {
    return null;
  }
  const scopeOptions = areOptionalScopesEnabled
    ? getScopeOptions(false)
    : getScopeOptions(false).slice(0, 2);

  return (
    <Card className="overflow-hidden" id={commonModel.name}>
      <CardBody className="">
        <Accordion activeKey={isCommonModelEnabled ? "0" : undefined}>
          <HoverableRow
            className={`!py-0 ${
              commonModel.model_capabilities.length > 1
                ? `transition-height duration-500 ${isViewing ? "h-[102px]" : "h-[124px]"}`
                : "h-[88px]"
            }`}
          >
            {/* Icon */}
            <MaybeCenteredVerticalLineCol
              className="col-auto pl-0 pr-0 flex items-center h-100"
              $showLine={isExpanded}
              $verticalLineConfig="all"
            >
              <IconContainer>
                <i className="fe fe-list" />
              </IconContainer>
            </MaybeCenteredVerticalLineCol>
            {/* Title */}
            <Col className="flex items-center flex-1 py-[30px]">
              <Row className="flex-1">
                <Col className="flex pl-0 my-auto items-center">
                  <Tooltip
                    tooltipClassName="max-w-xl text-center"
                    title={
                      deprecationStage && deprecationMessage ? (
                        <div className="[&>code]:p-0 [&>code]:border-none [&>code]:text-white [&>code]:font-bold [&>code]:bg-transparent items-center">
                          {deprecationStage && deprecationMessage}
                        </div>
                      ) : (
                        commonModel?.model_description && (
                          <div
                            className="[&>code]:p-0 [&>code]:border-none [&>code]:text-white [&>code]:font-bold [&>code]:bg-transparent"
                            dangerouslySetInnerHTML={{
                              __html: getSanitizedHTML(
                                replaceBackticksWithCode(commonModel.model_description) as string,
                              ),
                            }}
                          />
                        )
                      )
                    }
                    enterDelay={100}
                  >
                    <Title
                      className="mb-0"
                      $hasDescription={!!commonModel.model_description || !!deprecationStage}
                    >
                      {commonModel.name}
                    </Title>
                  </Tooltip>

                  {/* deprecated + beta tag */}
                  {deprecationStage && (
                    <div className="ml-1.5">
                      <Badge color="yellow">Deprecated</Badge>
                    </div>
                  )}
                </Col>
                <Col className="d-flex col-auto pr-8 my-auto flex-grow-1 justify-content-end">
                  {isViewing ? (
                    <div className="flex flex-col gap-1">
                      {isCommonModelEnabled ? (
                        commonModel.model_capabilities.map((capability: any) => {
                          const isDisabled =
                            !commonModel.enabled_model_actions.includes(capability);
                          const isConfigurable =
                            commonModel.end_user_configurable_actions.includes(capability);
                          // const isConfigurable =
                          return (
                            <ViewOnlyModelCapability
                              icon={getCommonModelToggleDisplayInfo(
                                isDisabled,
                                isConfigurable,
                                areOptionalScopesEnabled,
                              )}
                              overlayText={
                                !commonModel.enabled_model_actions.includes(capability)
                                  ? "Disabled"
                                  : isConfigurable && areOptionalScopesEnabled
                                  ? "Optional - your users can choose to disable this Scope in Merge Link"
                                  : "Enabled"
                              }
                              text={getExternalScopeActionName(capability)}
                              textClassName={`${isDisabled ? "opacity-35" : ""}`}
                              onSwitch={onSwitch}
                            />
                          );
                        })
                      ) : (
                        <>
                          <ViewOnlyModelCapability
                            icon={<Lock size={16} className="opacity-35 cursor-help" />}
                            text="Disabled"
                            textClassName="opacity-35"
                            overlayText="Disabled"
                            onSwitch={onSwitch}
                          />
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-col gap-4">
                      {commonModel.model_capabilities.map((capability) => {
                        const isConfigurable =
                          commonModel.end_user_configurable_actions.includes(capability);
                        const isEnabled = commonModel.enabled_model_actions.includes(capability);
                        return (
                          <div className="flex items-center">
                            <div className="text-sm font-semibold mr-3">
                              {getExternalScopeActionName(capability)}
                            </div>
                            <MultiSwitch
                              options={scopeOptions}
                              textClass="text-sm"
                              buttonClass="pt-[1px] pb-[3px] px-3"
                              isRounded
                              selectedID={
                                isConfigurable && areOptionalScopesEnabled
                                  ? ScopeOption.OPTIONAL
                                  : isEnabled
                                  ? ScopeOption.ENABLED
                                  : ScopeOption.DISABLED
                              }
                              onSelectOption={(option) => {
                                if (dependentModelScopesFlag) {
                                  handleOptionSelect(option.id, capability);
                                } else {
                                  if (!isDefaultScopesOffFlag && !canToggleFields) {
                                    if (setShowUpsellModal) {
                                      setShowUpsellModal(true);
                                    }
                                    return;
                                  }
                                  const isOptional = option.id == ScopeOption.OPTIONAL;

                                  const isEnabling = option.id == ScopeOption.ENABLED || isOptional;
                                  const actionsToEnable = isEnabling ? [capability] : [];

                                  // get other enabled model actions
                                  if (
                                    capability == EnabledAction.READ &&
                                    commonModel.enabled_model_actions.includes(
                                      EnabledAction.WRITE as EnabledAction,
                                    )
                                  ) {
                                    actionsToEnable.push(EnabledAction.WRITE);
                                  }
                                  if (
                                    capability == EnabledAction.WRITE &&
                                    commonModel.enabled_model_actions.includes(EnabledAction.READ)
                                  ) {
                                    actionsToEnable.push(EnabledAction.READ);
                                  }

                                  const optionalActionsToEnable = isOptional ? [capability] : [];
                                  const optionalActionsToDisable =
                                    !isEnabling || (isEnabling && !isOptional) ? [capability] : [];

                                  changeCommonModelScope(
                                    modelName,
                                    null,
                                    actionsToEnable as Array<EnabledAction>,
                                    optionalActionsToEnable,
                                    optionalActionsToDisable,
                                  );
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                      <ScopeConfirmationDialog
                        isOpen={isModalOpen}
                        onClose={() => setModalOpen(false)}
                        onConfirm={confirmAction}
                        capabilities={[currentCapability]}
                        modelName={modelName}
                        optionId={currentOption}
                        category={category}
                        dependentModelScopesFlag={dependentModelScopesFlag}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </HoverableRow>
          <Accordion.Collapse eventKey="0">
            <Accordion>
              <Accordion.Collapse eventKey="0">
                <Fields>
                  {Object.values(commonModel.fields)
                    .filter((field) => field.field_name !== "remote_data")
                    .filter((field) => field.field_name !== "remote_fields")
                    .map((field, index, arr) => {
                      const relatedModelName = getRelatedModelFromField(field);
                      const isNonSelfRelated =
                        relatedModelName && relatedModelName !== commonModel.name;
                      const relatedObject = !relatedModelName
                        ? undefined
                        : models[relatedModelName];
                      const relatedSubobject =
                        relatedObject && !relatedObject.has_endpoints && relatedObject;
                      const isRelatedToEnabledObject = !!(
                        relatedObject && relatedObject.enabled_model_actions.length
                      );
                      const disableability =
                        isNonSelfRelated && !relatedSubobject && !isRelatedToEnabledObject
                          ? Disableability.NOT_DISABLEABLE
                          : canToggleFields
                          ? Disableability.DISABLEABLE
                          : Disableability.DISABLEABLE_WITH_UPGRADE;

                      const relatedFieldName = getRelatedFieldFromField(field);
                      return (
                        <FieldToggle
                          key={field.field_name}
                          isEnabled={field.is_enabled}
                          displayName={field.field_name}
                          type={field.field_type}
                          description={
                            relatedSubobject
                              ? field?.related_to_description
                              : field?.field_description
                          }
                          deprecationMessage={
                            fields ? fields[field.field_name]?.deprecationMessage : undefined
                          }
                          deprecationStage={
                            fields ? fields[field.field_name]?.deprecationStage : undefined
                          }
                          showDeprecatedSunsetItems={showDeprecatedSunsetItems}
                          disableability={disableability}
                          showBorderAboveIcon={index === 0}
                          showBorderAboveContent
                          verticalLineConfig={index === arr.length - 1 ? "top-half" : "all"}
                          showDescription={
                            isDescriptionShowingMap[commonModel.name]?.[field.field_name]
                          }
                          setShowDescription={(showDescription) => {
                            setIsDescriptionShowingMap(
                              createUpdatedIsDescriptionShowingMap(
                                isDescriptionShowingMap,
                                commonModel.name,
                                field.field_name,
                                showDescription,
                              ),
                            );
                          }}
                          changeState={(
                            enabledActions,
                            isOptional,
                            optionalScopes = [],
                            nonOptionalScopes = [],
                          ) => {
                            changeCommonModelScope(
                              relatedSubobject ? relatedSubobject.name : commonModel.name,
                              relatedSubobject ? null : field.field_name,
                              relatedSubobject && enabledActions.length
                                ? relatedSubobject.model_capabilities
                                : enabledActions,
                              relatedSubobject && optionalScopes ? optionalScopes : [],
                              relatedSubobject && nonOptionalScopes ? nonOptionalScopes : [],
                              isOptional,
                            );
                          }}
                          isConfigurable={
                            field.is_end_user_configurable ||
                            relatedObject?.end_user_configurable_actions?.includes(
                              EnabledAction.READ,
                            )
                          }
                          isViewing={isViewing}
                          fieldOverlayText={getFieldOverlayText(
                            field.is_enabled,
                            field.is_end_user_configurable && areOptionalScopesEnabled,
                            areOptionalScopesEnabled,
                          )}
                          areOptionalScopesEnabled={areOptionalScopesEnabled}
                          canToggleOrgScopesOnly={canToggleOrgScopesOnly}
                          isDefaultScopesOffFlag={isDefaultScopesOffFlag}
                        >
                          {isNonSelfRelated &&
                            (relatedSubobject ? (
                              <SubobjectFieldsGroup
                                model={relatedSubobject}
                                canToggleFields={canToggleFields}
                                changeState={changeCommonModelScope}
                                isDescriptionShowingForField={
                                  isDescriptionShowingMap[relatedSubobject.name]
                                }
                                setIsDescriptionShowingForField={(
                                  fieldName,
                                  isDescriptionShowing,
                                ) =>
                                  setIsDescriptionShowingMap(
                                    createUpdatedIsDescriptionShowingMap(
                                      isDescriptionShowingMap,
                                      relatedSubobject.name,
                                      fieldName,
                                      isDescriptionShowing,
                                    ),
                                  )
                                }
                                isViewing={isViewing}
                                areOptionalScopesEnabled={areOptionalScopesEnabled}
                                canToggleOrgScopesOnly={canToggleOrgScopesOnly}
                                isDefaultScopesOffFlag={isDefaultScopesOffFlag}
                              />
                            ) : (
                              (!isRelatedToEnabledObject || relatedFieldName) && (
                                <RelationLink
                                  href={"#" + relatedModelName}
                                  $shrinkTopMargin={
                                    !isDescriptionShowingMap[commonModel.name]?.[field.field_name]
                                  }
                                >
                                  {relatedObject?.enabled_model_actions.length ? (
                                    <>
                                      Toggling this field also toggles the{" "}
                                      <code>{relatedFieldName}</code> field on the{" "}
                                      <code>{relatedModelName}</code> model
                                    </>
                                  ) : (
                                    <>
                                      Enable the <code>{relatedModelName}</code> model to enable
                                      toggling this field
                                    </>
                                  )}
                                </RelationLink>
                              )
                            ))}
                        </FieldToggle>
                      );
                    })}
                  {(remoteFieldsField || remoteDataField) && (
                    <div className="flex flex-col border rounded-lg mb-5 mx-3 border-gray-10 bg-[#f7f8f9]">
                      {remoteFieldsField && (
                        <RemoteFieldsToggle
                          key={remoteFieldsField.field_name}
                          displayName={remoteFieldsField.field_name}
                          isEnabled={models["RemoteFieldClass"].enabled_model_actions.length > 0}
                          // isEnabled
                          type="RemoteFields[]"
                          enableability={
                            productRestrictions?.are_toggles_enabled ||
                            productRestrictions?.are_remote_data_toggles_enabled
                              ? Enableability.ENABLEABLE
                              : Enableability.ENABLEABLE_WITH_UPGRADE
                          }
                          verticalLineConfig="none"
                          changeState={(enabledActions) =>
                            changeCommonModelScope(
                              models?.["RemoteFieldClass"]?.name,
                              null,
                              enabledActions,
                              [],
                              [],
                            )
                          }
                          fieldOverlayText={getFieldOverlayText(
                            remoteFieldsField.is_enabled,
                            remoteFieldsField.is_end_user_configurable,
                            areOptionalScopesEnabled,
                          )}
                          isViewing={isViewing}
                          isRemoteDataToggle
                          areOptionalScopesEnabled
                          canToggleOrgScopesOnly={
                            !(
                              productRestrictions?.are_toggles_enabled ||
                              productRestrictions?.are_remote_data_toggles_enabled
                            )
                          }
                          isDefaultScopesOffFlag={isDefaultScopesOffFlag}
                        />
                      )}
                      {remoteDataField && (
                        <RemoteDataToggle
                          key={remoteDataField.field_name}
                          displayName={remoteDataField.field_name}
                          isEnabled={remoteDataField.is_enabled}
                          type="RemoteData[]"
                          enableability={
                            productRestrictions?.are_toggles_enabled ||
                            productRestrictions?.are_remote_data_toggles_enabled
                              ? Enableability.ENABLEABLE
                              : Enableability.ENABLEABLE_WITH_UPGRADE
                          }
                          verticalLineConfig="none"
                          changeState={(enabledActions) =>
                            changeCommonModelScope(
                              commonModel.name,
                              remoteDataField.field_name,
                              enabledActions,
                              [],
                              [],
                            )
                          }
                          fieldOverlayText={getFieldOverlayText(
                            remoteDataField.is_enabled,
                            remoteDataField.is_end_user_configurable,
                            areOptionalScopesEnabled,
                          )}
                          isViewing={isViewing}
                          isRemoteDataToggle
                          areOptionalScopesEnabled
                          canToggleOrgScopesOnly={
                            !(
                              productRestrictions?.are_toggles_enabled ||
                              productRestrictions?.are_remote_data_toggles_enabled
                            )
                          }
                          isDefaultScopesOffFlag={isDefaultScopesOffFlag}
                        />
                      )}
                    </div>
                  )}
                </Fields>
              </Accordion.Collapse>
              <ToggleRow setIsExpanded={setIsExpanded} eventKey="0">
                <FieldStatusRow
                  isViewing={isViewing}
                  isExpanded={isExpanded}
                  fields={commonModel.fields}
                  areOptionalScopesEnabled={areOptionalScopesEnabled}
                  endUserConfigurableModels={endUserConfigurableModels}
                />
              </ToggleRow>
            </Accordion>
          </Accordion.Collapse>
        </Accordion>
      </CardBody>
    </Card>
  );
};

export default CommonModelToggle;
