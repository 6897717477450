import React from "react";
import { Badge } from "@merge-api/merge-javascript-shared";
import { User } from "../../../../../../../models/Entities";

type TwoFactorAuthCellProps = {
  user: User;
};

const TwoFactorAuthCell = ({ user }: TwoFactorAuthCellProps) => {
  return (
    <td>
      {user.is_active ? (
        user.is_two_factor_enabled ? (
          <Badge color="teal">Enabled</Badge>
        ) : (
          <Badge color="yellow">Disabled</Badge>
        )
      ) : (
        <Badge>Disabled</Badge>
      )}
    </td>
  );
};

export default TwoFactorAuthCell;
