import React, { useEffect, useState } from "react";
import {
  abbreviationForAPICategory,
  colorForAPICategory,
  stringRemoveSpaces,
} from "../../../../../../../../services";
import MaybeDisable from "../../../../../../../shared/MaybeDisable";
import { X } from "lucide-react";
import { APICategory, Badge, Checkbox, Text, Typeahead } from "@merge-api/merge-javascript-shared";
import { CategoryMap } from "../../../hooks/useWebhookOptions";
import startCase from "lodash-es/startCase";
import clsx from "clsx";

const ALL_FIELDS_OPTION = "All fields";
interface Props {
  commonModelName: string;
  selectedEvents: string[];
  selectedFields: string[];
  enabledFieldsForModel?: string[];
  onRemove?: () => void;
  onCheckboxChange: (isChecked: boolean, value: string) => void;
  deletedWebhookEnabled: boolean;
  modelToCategoryMap: CategoryMap;
  setSelectedChangedDataCommonModelsToFields: (
    selectedChangedDataCommonModelsToFields: Record<string, string[]>,
  ) => void;
}
const ChangedDataCommonModelCard = ({
  commonModelName,
  selectedEvents,
  selectedFields: initialSelectedFields,
  enabledFieldsForModel,
  onRemove,
  onCheckboxChange,
  modelToCategoryMap,
  deletedWebhookEnabled,
  setSelectedChangedDataCommonModelsToFields,
}: Props) => {
  const isChecked = (eventName: string) =>
    selectedEvents.includes(stringRemoveSpaces(commonModelName) + eventName);

  const handleCheckboxChange = (eventName: string) => (isChecked: boolean) => {
    onCheckboxChange(isChecked, stringRemoveSpaces(commonModelName) + eventName);
  };

  const [selectedFields, setSelectedFields] = useState<string[]>(
    initialSelectedFields.length > 0 ? initialSelectedFields : [ALL_FIELDS_OPTION],
  );

  // use effect that updates the selected changed data common models to fields
  useEffect(() => {}, [selectedFields]);

  const options = [ALL_FIELDS_OPTION, ...(enabledFieldsForModel || [])];
  const renderOption = (option: string) => {
    const isAllFields = option === ALL_FIELDS_OPTION;
    if (!isAllFields) {
      return (
        <div className="flex justify-between items-center hover:bg-gray-100 w-100">
          <Text variant="md-mono">{option}</Text>
        </div>
      );
    }
    return (
      <div className="flex justify-between items-center hover:bg-gray-100">
        <div>{option}</div>
      </div>
    );
  };

  const handleTypeaheadChange = (event: any, selected: string[] | null) => {
    if (selected) {
      if (selected.includes(ALL_FIELDS_OPTION) && !selectedFields.includes(ALL_FIELDS_OPTION)) {
        setSelectedFields([ALL_FIELDS_OPTION]);
        setSelectedChangedDataCommonModelsToFields({
          [commonModelName]: [ALL_FIELDS_OPTION],
        });
      } else if (
        selected.includes(ALL_FIELDS_OPTION) &&
        selected.length > 1 &&
        selectedFields.includes(ALL_FIELDS_OPTION)
      ) {
        setSelectedFields(selected.filter((item) => item !== ALL_FIELDS_OPTION));
        setSelectedChangedDataCommonModelsToFields({
          [commonModelName]: selected.filter((item) => item !== ALL_FIELDS_OPTION),
        });
      } else {
        setSelectedFields(selected);
        setSelectedChangedDataCommonModelsToFields({
          [commonModelName]: selected,
        });
      }
    }
  };

  return (
    <div className="border border-gray-10 rounded-lg w-full bg-white mb-2">
      <div className="border-b border-gray-10 px-4 py-3 flex lg:flex-row cursor-default flex-col lg:items-center items-start justify-between">
        <div className="flex flex-row justify-center items-center">
          <div className="font-semibold mr-1.5 whitespace-nowrap">{startCase(commonModelName)}</div>
          <Badge
            className="ml-1.5"
            size="sm"
            color={colorForAPICategory(modelToCategoryMap[commonModelName] as APICategory)}
          >
            {abbreviationForAPICategory(modelToCategoryMap[commonModelName] as APICategory)}
          </Badge>
        </div>
        <div className="flex flex-row justify-center items-center lg:mt-0 mt-3">
          <Checkbox
            onChange={handleCheckboxChange(".added")}
            checked={isChecked(".added")}
            className="lg:ml-6"
            label="Created"
          />
          <Checkbox
            onChange={handleCheckboxChange(".changed")}
            checked={isChecked(".changed")}
            className="ml-6"
            label="Updated"
          />
          <MaybeDisable
            className="flex items-center justify-center"
            disable={!deletedWebhookEnabled}
            tooltip={`
                Checking the 'Deleted' option will allow your organization to receive deletion webhooks from Merge if your organization has either the Deletion Detection or the Receiving Webhooks feature enabled. Reach out to us at support@merge.dev to turn on either feature.
                  `}
            inline
            hasPadding={false}
          >
            <Checkbox
              onChange={handleCheckboxChange(".removed")}
              checked={isChecked(".removed")}
              className="ml-6"
              label="Deleted"
              disabled={!deletedWebhookEnabled}
            />
          </MaybeDisable>
          <X
            onClick={onRemove}
            className="cursor-pointer ml-2 mr-4 hover:text-gray-60 transition-colors duration-300"
            size={12}
          />
        </div>
      </div>
      {enabledFieldsForModel && enabledFieldsForModel.length > 0 ? (
        <div className="mt-3 px-4 py-2 w-full">
          <div className="font-semibold mb-2">Fields</div>
          <div>
            {selectedFields.includes(ALL_FIELDS_OPTION) ? (
              <Typeahead
                multiple
                onChange={handleTypeaheadChange}
                options={options}
                value={selectedFields}
                renderOption={renderOption}
                renderBadge={({ tag, dataTagIndex }) => (
                  <Text
                    className={clsx(
                      dataTagIndex === 0 ? "ml-3" : "ml-1",
                      "text-nowrap",
                      "flex",
                      "items-center",
                    )}
                  >
                    {tag}
                  </Text>
                )}
                disableCloseOnSelect
                placeholder=""
                tagsLimit={4}
              />
            ) : (
              <Typeahead
                multiple
                onChange={handleTypeaheadChange}
                options={options}
                value={selectedFields}
                renderOption={renderOption}
                renderBadge={({ tag, onDelete, dataTagIndex }) => (
                  <Badge
                    size="md"
                    onDelete={onDelete}
                    dataTagIndex={dataTagIndex}
                    className="text-nowrap"
                  >
                    <Text variant="md-mono">{tag}</Text>
                  </Badge>
                )}
                disableCloseOnSelect
                placeholder=""
                tagsLimit={4}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="mt-3 px-4 py-2 w-full">
          <div className="text-gray-60 mb-2">Field configuration not available for this model</div>
        </div>
      )}
    </div>
  );
};

export default ChangedDataCommonModelCard;
