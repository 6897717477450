import React from "react";
import clsx from "clsx";
import ConditionPresetInputCombo from "./ConditionPresetInputCombo";
import { APICategory, ConditionPreset, ConditionPresetMeta } from "../../../../models/Entities";
import { Text, Button, Tooltip, Link } from "@merge-api/merge-javascript-shared";
import { getFilteredModelOptions } from "./ConditionPresetInputCombo/helpers";
import { Plus } from "lucide-react";
import { OrganizationCustomization } from "../link/context/CustomizationContext";

type ConditionPresetsListProps = {
  className?: string;
  conditionPresets: Partial<ConditionPreset>[];
  conditionPresetErrors: (string | undefined)[];
  conditionPresetMeta: ConditionPresetMeta;
  onConditionPresetAdd: () => void;
  onConditionPresetDelete: (index: number) => void;
  onConditionPresetChange: (conditionPreset: Partial<ConditionPreset>, index: number) => void;
  showGatedSelectiveSync: boolean;
  organizationCustomization: OrganizationCustomization | null;
  category: APICategory;
};

const ConditionPresetsList = ({
  className,
  conditionPresets,
  conditionPresetErrors,
  conditionPresetMeta,
  onConditionPresetAdd,
  onConditionPresetDelete,
  onConditionPresetChange: propsOnConditionPresetChange,
  showGatedSelectiveSync,
  organizationCustomization,
  category,
}: ConditionPresetsListProps) => {
  // derived state
  const hasConditionPresets = !!conditionPresets.length;
  const canAddFilter = !!getFilteredModelOptions({}, conditionPresetMeta, conditionPresets).length;

  // event handlers
  const onConditionPresetChange =
    (index: number) => (conditionPreset: Partial<ConditionPreset>) => {
      propsOnConditionPresetChange(conditionPreset, index);
    };

  return (
    <div className={clsx("", className)}>
      <Text>Selectively sync data...</Text>

      {conditionPresets.map((conditionPreset, index) => {
        // derive key from common_model + normalized_key_name in the
        // event that it is a new condition preset, which wont have an id
        const conditionPresetKey =
          conditionPreset.id ||
          `${conditionPreset.common_model}-${conditionPreset.normalized_key_name}-${index}`;

        return (
          <ConditionPresetInputCombo
            className="my-6"
            key={conditionPresetKey}
            conditionPreset={conditionPreset}
            errorText={conditionPresetErrors[index]}
            conditionPresetMeta={conditionPresetMeta}
            conditionPresets={conditionPresets}
            onConditionPresetChange={onConditionPresetChange(index)}
            onConditionPresetDelete={() => onConditionPresetDelete(index)}
            isFirst={index === 0}
            showGatedSelectiveSync={showGatedSelectiveSync}
            organizationCustomization={organizationCustomization}
            category={category}
          />
        );
      })}

      <div className={clsx("flex flex-row items-center", !hasConditionPresets && "mt-6")}>
        {hasConditionPresets && <Text className="text-gray-60 w-[57px]">and</Text>}

        <Tooltip
          title={
            showGatedSelectiveSync ? (
              <Text variant="sm">
                <Link href="/billing" className="text-white underline">
                  Upgrade your plan{" "}
                </Link>
                to configure Selective Sync in Link
              </Text>
            ) : (
              !canAddFilter && "No more filters available without creating duplicates"
            )
          }
        >
          <Button
            leftIcon={<Plus size={16} />}
            onClick={onConditionPresetAdd}
            disabled={showGatedSelectiveSync || !canAddFilter}
          >
            Filter
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default ConditionPresetsList;
