import React from "react";
import { Card, Toggle, Text, Link } from "@merge-api/merge-javascript-shared";
import { useHistory } from "react-router-dom";
import MappingOverviewRow from "./MappingOverviewRow";
import {
  navigateToFieldMappingSettings,
  navigateToIndividualCommonModelOverrideTargetPage,
  CONFIGURATION_COMMON_MODELS_PATH,
} from "../../../../../../router/RouterUtils";
import {
  OrganizationFieldMappingValue,
  OverriddenCommonModelTarget,
  FieldMappingTarget,
} from "../../../../../../models/Entities";
import { APICategory } from "../../../../../../models/Entities";
import { patchFieldMappingConfiguration } from "../../utils/FieldMappingUtils";
import FieldMappingTargetBadge from "../../../../../shared/FieldMappingTargetBadge";
type CommonModelMappingInformationCardProps = {
  commonModelName: string;
  mappingInfo: OrganizationFieldMappingValue;
  category: APICategory;
};

const CommonModelMappingInformationCard = ({
  commonModelName,
  mappingInfo,
  category,
}: CommonModelMappingInformationCardProps) => {
  const {
    override_common_model_targets: overrideTargets,
    field_mapping_targets: fieldMappingTargets,
    has_remote_data_enabled: isRemoteDataEnabled,
    allow_user_created_field_mapping_targets: endUserTargetsEnabled,
    model_configuration_id: modelConfigurationId,
  } = mappingInfo;

  const history = useHistory();

  const toggleCustomMappingConfigUserCreatedCustomFields = (
    configID: string,
    new_allow_user_created_field_mapping_targets: string,
  ) => {
    patchFieldMappingConfiguration({
      body: {
        configuration_id: configID,
        allow_user_created_field_mapping_targets:
          new_allow_user_created_field_mapping_targets === "enabled",
      },
      category,
      onResponse: () => {},
      onError: () => {},
    });
  };

  return (
    <div id={`${commonModelName}-mapping-info`}>
      <Card>
        <div className="flex py-4 px-6 items-center justify-between">
          <Text variant="h5">{commonModelName}</Text>
          <div className="flex items-center">
            {!isRemoteDataEnabled || !modelConfigurationId ? (
              <Text variant="sm">
                <Link href={`${CONFIGURATION_COMMON_MODELS_PATH}`}>Enable Remote Data</Link>
                &nbsp; to add fields or mappings for this model
              </Text>
            ) : (
              <>
                <Toggle
                  checked={endUserTargetsEnabled}
                  label="Allow end user-created target fields"
                  onChange={() => {
                    toggleCustomMappingConfigUserCreatedCustomFields(
                      modelConfigurationId,
                      endUserTargetsEnabled ? "disabled" : "enabled",
                    );
                  }}
                />
              </>
            )}
          </div>
        </div>
        {overrideTargets.map((overrideTarget: OverriddenCommonModelTarget) => {
          return (
            <MappingOverviewRow
              key={overrideTarget.id}
              rowHeader={<FieldMappingTargetBadge type="common-model-override-field" />}
              fieldName={overrideTarget.overridden_field}
              description={overrideTarget.overridden_field_definition?.description || ""}
              numberOfMappings={
                overrideTarget.total_int_wide_overrides == null
                  ? 0
                  : overrideTarget.total_int_wide_overrides
              }
              typeOfMapping="common-model-overrides"
              onRowClick={() => {
                navigateToIndividualCommonModelOverrideTargetPage(history, overrideTarget.id);
              }}
            />
          );
        })}
        {fieldMappingTargets.map((fieldMappingTarget: FieldMappingTarget) => {
          return (
            <MappingOverviewRow
              key={fieldMappingTarget.id}
              rowHeader={<FieldMappingTargetBadge type="org-wide-target-field" />}
              fieldName={fieldMappingTarget.field_key}
              description={fieldMappingTarget.field_description}
              numberOfMappings={
                fieldMappingTarget.total_int_wide_field_mappings == null
                  ? 0
                  : fieldMappingTarget.total_int_wide_field_mappings
              }
              typeOfMapping="field-mappings"
              onRowClick={() => {
                navigateToFieldMappingSettings(history, fieldMappingTarget.id);
              }}
            />
          );
        })}
      </Card>
    </div>
  );
};
export default CommonModelMappingInformationCard;
