import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { displayNameForAPICategory } from "../../../../../models/Helpers";

import { DEBIT_OR_CREDIT, MergeInvoice, MergeInvoiceLineItem } from "../BillingModels";

const Table = styled.table`
  && {
    width: 100%;
  }
`;

const TBody = styled.tbody`
  && {
    background: #f9fafb;
    color: #121314;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: right;
  }
`;

const TH = styled.th`
  && {
    padding-bottom: 4px;
    color: #121314;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    text-align: right;
    text-transform: none;
  }
`;

const TDBase = styled.td`
  && {
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 16px;
  }
`;

const RecurrenceText = styled.p`
  width: 100%;
  border-bottom: 1px solid #dce2ea;
  line-height: 0.1em;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  font-weight: 600;
  font-size: 11px;
  color: #939aa5;
`;

const RecurrenceSpan = styled.span`
  background: #fff;
`;

const TDItem = styled(TDBase)`
  && {
    text-align: left;
    margin-bottom: 0px;
    padding-right: 10px;
  }
`;

const ItemName = styled.span`
  color: #121314;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
`;

const ItemDescription = styled.span`
  font-weight: 400;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: right;
`;

const UsageTableContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 30px;
  margin-left: 20px;
  && {
    width: 45%;
  }
`;

const UsageTable = styled.table`
  && {
    background-color: white;
    width: 100%;
  }
`;

const UsageTableTHead = styled.thead`
  && {
    padding-top: 5px;
    background-color: white;
  }
`;

const UsageTableHeaderFirstChild = styled(TH)`
  padding-left: 5px !important;
  background: white !important;
  && {
    color: #8492a6;
  }
`;

const UsageTableHeaderSecondChild = styled(TH)`
  background: white !important;
  && {
    color: #8492a6;
  }
`;

const UsageTableCategoryTd = styled(TDItem)`
  background: white !important;
`;

const UsageTableTDLastRow = styled(TDBase)`
  background: white !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
`;

const UsageTableCategoryName = styled(ItemName)`
  background: white !important;
`;

const UsageTableCategoryValue = styled(ItemDescription)`
  background: white !important;
`;

const ItemTooltip = styled.i`
  position: relative;
  right: -3px;
`;

const TotalCalculations = styled.div`
  border-top: 1px solid #dce2ea !important;
  width: 232px;
  float: right;
  padding-top: 2px;
  margin-bottom: 22px;
  & > * + * {
    margin-top: 12px;
  }
`;

const TotalCalculationsRow = styled.div`
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
`;

const LeaderUL = styled.ul`
  padding-bottom: 14px;
`;

type BottomElementsContainerProps = {
  hasUsageTable: boolean;
};

const BottomElementsContainer = styled.div<BottomElementsContainerProps>`
  justify-content: ${({ hasUsageTable }) => (hasUsageTable ? "space-between" : "flex-end")}};
  display: flex;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
`;

interface Props {
  invoice: MergeInvoice;
}

const InvoiceBreakdown = ({ invoice }: Props) => {
  const recurrencesToItemsMap: { [key: string]: MergeInvoiceLineItem[] } = {};
  invoice.line_items.map((item) => {
    if (!(item.recurrence in recurrencesToItemsMap)) {
      recurrencesToItemsMap[item.recurrence] = [];
    }
    if (item.debit_or_credit == DEBIT_OR_CREDIT.DEBIT) {
      recurrencesToItemsMap[item.recurrence].push(item);
    }
  });

  for (const [key, value] of Object.entries(recurrencesToItemsMap)) {
    recurrencesToItemsMap[key] = [...value].sort((lineItemA, lineItemB) => {
      if (lineItemA.description && lineItemB.description) {
        if (lineItemA.item_name > lineItemB.item_name) {
          return 1;
        } else {
          return -1;
        }
      } else if (lineItemA.description && !lineItemB.description) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  const hasUsageTable =
    Object.keys(recurrencesToItemsMap).includes("MONTHLY") &&
    recurrencesToItemsMap["MONTHLY"].some((lineItem) => lineItem.formatted_quantity_used);

  return (
    <div>
      <Table className="table-borderless">
        <thead>
          <TH className="text-left">Item</TH>
          <TH>Description</TH>
          <TH>Quantity charged</TH>
          <TH>Unit price</TH>
          <TH>Amount</TH>
        </thead>
        {Object.entries(recurrencesToItemsMap).map(([recurrence, lineItems]) => {
          return (
            <>
              <TBody>
                {Object.keys(recurrencesToItemsMap).length > 1 && (
                  <tr>
                    <TDBase colSpan={5}>
                      <RecurrenceText>
                        <RecurrenceSpan>{recurrence}</RecurrenceSpan>
                      </RecurrenceText>
                    </TDBase>
                  </tr>
                )}

                {lineItems.map((lineItem, index) => {
                  return (
                    <tr key={index}>
                      {lineItem.description ? (
                        <TDItem colSpan={2}>
                          <ul className="leaders my-0">
                            <li>
                              <ItemName>{lineItem.item_name}</ItemName>
                              <ItemDescription>{lineItem.description}</ItemDescription>
                            </li>
                          </ul>
                        </TDItem>
                      ) : (
                        <TDItem colSpan={2}>
                          <ItemName>{lineItem.item_name}</ItemName>
                        </TDItem>
                      )}
                      <TDBase>{lineItem.formatted_quantity_charged}</TDBase>
                      <TDBase>{lineItem.formatted_unit_price}</TDBase>
                      <TDBase>{lineItem.formatted_amount}</TDBase>
                    </tr>
                  );
                })}
              </TBody>
            </>
          );
        })}
      </Table>
      <BottomElementsContainer hasUsageTable={hasUsageTable}>
        {hasUsageTable && (
          <UsageTableContainer>
            <UsageTable className="table-borderless">
              <UsageTableTHead>
                <UsageTableHeaderFirstChild className="text-left" colSpan={2}>
                  {new Date(invoice.issue_date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                  })}{" "}
                  Usage
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 0 }}
                    overlay={
                      <Tooltip id="api-requests-include-outbound-tooltip">
                        Average usage over billing period.
                      </Tooltip>
                    }
                  >
                    <ItemTooltip className="text-gray-60 text-right fe fe-info" />
                  </OverlayTrigger>
                </UsageTableHeaderFirstChild>
                <UsageTableHeaderSecondChild>Quantity used</UsageTableHeaderSecondChild>
              </UsageTableTHead>
              <TBody>
                {recurrencesToItemsMap["MONTHLY"].map((lineItem, index) => {
                  return lineItem.formatted_quantity_used ? (
                    <tr key={index}>
                      {index == recurrencesToItemsMap["MONTHLY"].length - 1 ? (
                        <>
                          <UsageTableCategoryTd colSpan={2}>
                            {lineItem.object_category !== null ? (
                              <LeaderUL className="leaders my-0">
                                <li>
                                  <UsageTableCategoryName>
                                    {displayNameForAPICategory(lineItem.object_category)}
                                  </UsageTableCategoryName>
                                  <UsageTableCategoryValue>
                                    {lineItem.item_name}
                                  </UsageTableCategoryValue>
                                </li>
                              </LeaderUL>
                            ) : (
                              <UsageTableCategoryValue>
                                {lineItem.item_name}
                              </UsageTableCategoryValue>
                            )}
                          </UsageTableCategoryTd>
                          <UsageTableTDLastRow>
                            {lineItem.formatted_quantity_used}
                          </UsageTableTDLastRow>
                        </>
                      ) : (
                        <>
                          <UsageTableCategoryTd colSpan={2}>
                            {lineItem.object_category !== null ? (
                              <LeaderUL className="leaders my-0">
                                <li>
                                  <UsageTableCategoryName>
                                    {displayNameForAPICategory(lineItem.object_category)}
                                  </UsageTableCategoryName>
                                  <UsageTableCategoryValue>
                                    {lineItem.item_name}
                                  </UsageTableCategoryValue>
                                </li>
                              </LeaderUL>
                            ) : (
                              <ul>
                                <li>
                                  <UsageTableCategoryValue>
                                    {lineItem.item_name}
                                  </UsageTableCategoryValue>
                                </li>
                              </ul>
                            )}
                          </UsageTableCategoryTd>
                          <UsageTableTDLastRow>
                            {lineItem.formatted_quantity_used}
                          </UsageTableTDLastRow>
                        </>
                      )}
                    </tr>
                  ) : (
                    <></>
                  );
                })}
              </TBody>
            </UsageTable>
          </UsageTableContainer>
        )}
        <tr className="table-borderless">
          <td colSpan={5}>
            <TotalCalculations>
              {invoice.formatted_subtotal && (
                <TotalCalculationsRow>
                  <div>
                    <strong>Subtotal</strong>
                  </div>
                  <div>{invoice.formatted_subtotal}</div>
                </TotalCalculationsRow>
              )}
              {invoice.formatted_credit && (
                <TotalCalculationsRow>
                  <div>
                    <strong>Credits</strong>
                  </div>
                  <div>{invoice.formatted_credit}</div>
                </TotalCalculationsRow>
              )}
              <TotalCalculationsRow>
                <div>
                  <strong>Total</strong>
                </div>
                <div>{invoice.formatted_total}</div>
              </TotalCalculationsRow>
            </TotalCalculations>
          </td>
        </tr>
      </BottomElementsContainer>
    </div>
  );
};

export default InvoiceBreakdown;
