import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { spectrum } from "../../../../styles/theme";
import { useHistory } from "react-router-dom";
import { ButtonVariant, Link, Text } from "@merge-api/merge-javascript-shared";
import { UserType } from "../../../../models/Entities";
import useAppContext from "../../../context/useAppContext";
import EmptyStateWrapper from "../../../shared/EmptyStateWrapper";
import { CardHeaderTitle } from "../../../shared/MergeText";
import { showSuccessToast } from "../../../shared/Toasts";
import { getPaymentMethods, updateDefaultPaymentMethod } from "./BillingAPIClient";
import { BankAccount, BillingPlanTier, CreditCard, PaymentMethod } from "./BillingModels";
import { LinkedAccountMetrics } from "../../../../models/Entities";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import { useLocation } from "react-router-dom";
import FreeToLaunchConfirmModal from "./components/FreeToLaunchConfirmModal";
import { formatSyncFrequncyPlans, getBillingPlanTitle } from "./BillingUtils";
import PaymentMethodRow from "./components/BillingPaymentMethodRow";
import AddBankAccountModal from "./components/AddBankAccountModal";
import DeletePaymentMethodModal from "./components/DeletePaymentMethodModal";
import OldBillingPage from "./OldBillingPage";
import { Plus } from "lucide-react";
import { Button, Tooltip } from "@merge-api/merge-javascript-shared";
import { SectionHeaderWrapper } from "../../../shared/MergeLayouts";
import SkeletonLoader from "../../../shared/SkeletonLoader";
import clsx from "clsx";
import useLoadOrganizationBillingPlan from "./hooks/useLoadOrganizationBillingPlan";
import { navigateToAddPaymentPage } from "../../../../router/RouterUtils";
import LinkedAccountsCountsCard from "./components/LinkedAccountsCountsCard";

const PlanNameText = styled.div`
  background: linear-gradient(85.42deg, #0c57da 9.77%, #669bf9 89.78%), ${spectrum.gray90};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const GreyRect = styled.rect`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 2px;

  width: 100%;
  height: 32px;

  /* Gray/Gray 0 */

  background: ${spectrum.gray0};
  border-radius: 39px;
`;

const SyncPlanGreyRect = styled(GreyRect)`
  justify-content: center;
`;

const BillingPlanText = styled.text`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  /* Gray/Gray 90 */
  color: ${spectrum.gray90};
`;

const SyncPlanText = styled(BillingPlanText)`
  justify-content: space-between;
  align-items: center;
`;

function BillingPage() {
  // state
  const [showingAddPaymentMethodModal, setShowingAddPaymentMethodModal] = useState(false);
  const [paymentMethodDeletionTarget, setPaymentMethodDeletionTarget] = useState<
    PaymentMethod | undefined
  >();
  const [paymentMethods, setPaymentMethods] = useState<null | PaymentMethod[]>(null);
  const [linkedAccountMetrics, setLinkedAccountMetrics] = useState<LinkedAccountMetrics>();
  const [showFreeToLaunchModal, setShowFreeToLaunchModal] = useState(false);
  const [defaultPaymentMethodBeingSet, setDefaultPaymentMethodBeingSet] =
    useState<null | PaymentMethod>(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refetch = queryParams.get("refetch");
  const upgraded = queryParams.get("upgraded");

  // hooks
  const { user } = useAppContext();
  const { organizationBillingPlan, setOrganizationBillingPlan, isLoadingOrganizationBillingPlan } =
    useLoadOrganizationBillingPlan();

  const freeAccountGated = user?.is_free_account_gating_enabled ?? false;

  enum QueryParamOption {
    True = "true",
  }

  const history = useHistory();

  const organizePaymentMethods = (
    bankAccounts: BankAccount[],
    creditCards: CreditCard[],
  ): PaymentMethod[] => {
    const arr: PaymentMethod[] = [...bankAccounts, ...creditCards]
      .sort((a, b) => a.created_at.localeCompare(b.created_at))
      .sort((a, _) => (a.is_default_method ? -1 : 1));
    return arr;
  };

  const fetchPaymentMethods = (forceRefreshFromStripe: boolean) => {
    getPaymentMethods({
      forceRefreshStripe: forceRefreshFromStripe,
      onSuccess: (data: { bank_accounts: BankAccount[]; credit_cards: CreditCard[] }) => {
        const paymentMethods = organizePaymentMethods(data.bank_accounts, data.credit_cards);
        setPaymentMethods(paymentMethods);
      },
    });
  };

  const setDefaultPaymentMethod = (paymentMethod: PaymentMethod) => {
    setDefaultPaymentMethodBeingSet(paymentMethod);
    updateDefaultPaymentMethod({
      paymentMethod: paymentMethod,
      onSuccess: (data: { bank_accounts: BankAccount[]; credit_cards: CreditCard[] }) => {
        const paymentMethods = organizePaymentMethods(data.bank_accounts, data.credit_cards);
        setPaymentMethods(paymentMethods);
        setDefaultPaymentMethodBeingSet(null);
        showSuccessToast("Successfully updated your default payment method!");
      },
    });
  };

  const reformatLinkedAccountMetrics = (data: LinkedAccountMetrics) => {
    return {
      completed_linked_accounts_count: data.completed_linked_accounts_count,
      idle_accounts_count: data.idle_accounts_count,
      incomplete_linked_accounts_count: data.incomplete_linked_accounts_count,
      relink_needed_accounts_count: data.relink_needed_accounts_count,
      plan_limit_reached_accounts_count: data.plan_limit_reached_accounts_count,
      rippling_oauth_accounts_count: data.rippling_oauth_accounts_count,
    };
  };

  // load payment methods
  useEffect(() => {
    if (user.type === UserType.admin_with_billing) {
      //on new payment methods being added to stripe, refetch is set to true to get those new payment methods from stripe. otherwise we don't actively fetch new payment methods
      if (refetch && refetch === QueryParamOption.True) {
        fetchPaymentMethods(true);
      }
      if (upgraded && upgraded === QueryParamOption.True) {
        setShowFreeToLaunchModal(true);
      }
      fetchPaymentMethods(false);
    }
  }, [location, freeAccountGated]);

  useEffect(() => {
    fetchWithAuth({
      path: "/integrations/linked-accounts/detailed-counts",
      method: "GET",
      onResponse: (data: LinkedAccountMetrics) => {
        const reformattedData = reformatLinkedAccountMetrics(data);
        setLinkedAccountMetrics(reformattedData);
      },
    });
  }, []);

  const isFreePlan =
    user.organization.organization_billing_plan?.billing_plan.plan_tier ===
    BillingPlanTier.BILLING_PLAN_TIER_FREE;
  const isLaunchPlan =
    user.organization.organization_billing_plan?.billing_plan.plan_tier ===
    BillingPlanTier.BILLING_PLAN_TIER_LAUNCH;

  const totalPurchasedLinkedAccounts = user.organization.purchased_prod_linked_accounts;
  const syncPlanMap = user.organization.sync_frequency_plans || {};
  const defaultSyncPlan = syncPlanMap["DEFAULT"];

  const isOldBillingPlan =
    user.organization.organization_billing_plan?.billing_plan.plan_tier ===
      BillingPlanTier.BILLING_PLAN_TIER_GROW ||
    user.organization.organization_billing_plan?.billing_plan.plan_tier ===
      BillingPlanTier.BILLING_PLAN_TIER_EXPAND;

  if (isOldBillingPlan || isFreePlan || (isLaunchPlan && !freeAccountGated)) {
    return <OldBillingPage />;
  } else {
    return (
      <>
        {freeAccountGated && (
          <FreeToLaunchConfirmModal
            open={showFreeToLaunchModal}
            onHide={() => setShowFreeToLaunchModal(false)}
          />
        )}
        <AddBankAccountModal
          show={showingAddPaymentMethodModal}
          freeAccountGated={freeAccountGated}
          onHide={() => {
            setShowingAddPaymentMethodModal(false);
            fetchPaymentMethods(true);
          }}
        />
        <DeletePaymentMethodModal
          paymentMethod={paymentMethodDeletionTarget}
          onHide={() => {
            setPaymentMethodDeletionTarget(undefined);
            fetchPaymentMethods(true);
          }}
        />
        <SectionHeaderWrapper title="Billing" />
        <Row className="justify-content-between">
          <Col className="col-12 col-lg-3">
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <div className="font-semibold text-lg mb-3">Current plan</div>
                    <PlanNameText className="font-semibold text-[24px] leading-[32px]">
                      {!isLoadingOrganizationBillingPlan ? (
                        getBillingPlanTitle(organizationBillingPlan)
                      ) : (
                        <SkeletonLoader width={169} height={24} borderRadius={4} />
                      )}
                    </PlanNameText>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <LinkedAccountsCountsCard
              linkedAccountMetrics={linkedAccountMetrics}
              totalPurchasedLinkedAccounts={totalPurchasedLinkedAccounts}
            />
            <Card>
              <Card.Body>
                <div className="align-items-center">
                  <div className="font-semibold text-lg mb-3">Sync plan</div>

                  <SyncPlanGreyRect>
                    <SyncPlanText>{formatSyncFrequncyPlans(defaultSyncPlan)}</SyncPlanText>
                  </SyncPlanGreyRect>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-12 col-lg-9">
            <>
              <Card>
                <Card.Header>
                  <Row className="align-items-center">
                    <Col>
                      <CardHeaderTitle>Payment methods</CardHeaderTitle>
                    </Col>
                    <Col className="col-auto">
                      {user.type === UserType.admin_with_billing ? (
                        <Button
                          leftIcon={<Plus size={12} />}
                          size="sm"
                          variant={ButtonVariant.TertiaryWhite}
                          onClick={() => {
                            if (freeAccountGated) {
                              navigateToAddPaymentPage(history, { fromUpgradeButton: false });
                            } else {
                              setShowingAddPaymentMethodModal(true);
                            }
                          }}
                        >
                          Payment method
                        </Button>
                      ) : (
                        <Tooltip title="You must be an admin to add payment methods">
                          <Button
                            variant={ButtonVariant.TertiaryWhite}
                            size="sm"
                            leftIcon={<Plus size={12} />}
                          >
                            Payment method
                          </Button>
                        </Tooltip>
                      )}
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body className="p-0">
                  <div className="list-group list-group-flush px-6">
                    {!paymentMethods && user.type === UserType.admin_with_billing ? (
                      <div>
                        {Array(5)
                          .fill(null)
                          .map((_, index) => (
                            <div
                              key={index}
                              className={clsx("flex flex-row justify-between items-center py-5", {
                                "border-b border-gray-10": index !== 4,
                              })}
                            >
                              <SkeletonLoader width={238} height={12} borderRadius={2} />
                              <SkeletonLoader width={182} height={12} borderRadius={2} />
                            </div>
                          ))}
                      </div>
                    ) : user.type !== UserType.admin_with_billing ? (
                      <EmptyStateWrapper title="You must be an admin to view payment methods" />
                    ) : paymentMethods && paymentMethods.length > 0 ? (
                      paymentMethods.map((paymentMethod) => (
                        <PaymentMethodRow
                          paymentMethod={paymentMethod}
                          billingPlan={organizationBillingPlan}
                          updateBillingPlan={setOrganizationBillingPlan}
                          onClickSetDefault={() => setDefaultPaymentMethod(paymentMethod)}
                          settingDefault={defaultPaymentMethodBeingSet === paymentMethod}
                          setPaymentMethodDeletionTarget={setPaymentMethodDeletionTarget}
                        />
                      ))
                    ) : (
                      <EmptyStateWrapper title="No payment methods" />
                    )}
                  </div>
                </Card.Body>
              </Card>
            </>
          </Col>
        </Row>

        <div className="flex justify-center my-9">
          <Text className="text-center text-gray-60">
            Tired of painless integrations?{" "}
            <Link href="mailto:hello@merge.dev" className="text-red-60">
              Contact us to cancel your plan
            </Link>
          </Text>
        </div>
      </>
    );
  }
}

export default BillingPage;
