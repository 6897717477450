import React from "react";
import { ChevronRight } from "lucide-react";
import { navigateToLinkedAccountDetailPageByID } from "../../../../../../../router/RouterUtils";
import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { useHistory } from "react-router-dom";
import DottedOutlineTextCard from "../../../../../../shared/DottedOutlineTextCard";

type LinkedAccountIDProps = {
  linkedAccountId: string;
  hasIssue: boolean;
};

const LinkedAccountID = ({ linkedAccountId, hasIssue }: LinkedAccountIDProps) => {
  const history = useHistory();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-wrap justify-between items-center gap-2">
        <Text variant="h6">Linked Account ID</Text>
        {hasIssue && (
          <Button
            onClick={() => navigateToLinkedAccountDetailPageByID(history, linkedAccountId)}
            variant={ButtonVariant.TextBlue}
            size="sm"
            rightIcon={<ChevronRight size={12} />}
            className="px-0"
          >
            View
          </Button>
        )}
      </div>
      <DottedOutlineTextCard text={linkedAccountId} />
    </div>
  );
};

export default LinkedAccountID;
