import React from "react";
import { REDACTED_VALUE_TEXT } from "../../../../../../constants";
import { isValidJSON } from "../../../../../../services";
import MergeCodeBlock, { CodeBlockFileDownloadInfo } from "../../../../../shared/MergeCodeBlock";
import { CollapsableSection } from "./LogSidePanelBody";
import { Text } from "@merge-api/merge-javascript-shared";
import EmptyStateWrapper from "../../../../../shared/EmptyStateWrapper";

type Props = {
  title: string;
  data: string | undefined; // Query Params, headers, and body all string/json data
  isBody?: boolean; // Only true for Request Body
  isRequest: boolean; // true if this a request body otherwise it's a response body
  downloadInfo?: CodeBlockFileDownloadInfo;
  isCollapsedByDefault?: boolean;
  isRedacted?: boolean;
  isRoundedBottom?: boolean;
};

export const LogSidePanelBodySection = ({
  title,
  data,
  isBody = false,
  isRequest,
  downloadInfo,
  isCollapsedByDefault = false,
  isRedacted,
  isRoundedBottom,
}: Props) => {
  let displayText =
    data !== undefined && isValidJSON(data)
      ? JSON.stringify(JSON.parse(data), null, 2)
      : data || "Empty";

  // For large response bodies, truncate to 21 lines
  if (downloadInfo !== undefined) {
    displayText = displayText.split("\n", 21).join("\n");
    displayText += "\n...";
  }

  return (
    <CollapsableSection
      title={title}
      isCollapsedByDefault={isCollapsedByDefault}
      numberOfElements={!isBody && data ? Object.entries(data).length : undefined}
      isRoundedBottom={isRoundedBottom}
      children={
        <div className="w-100 fs-mask flex flex-col gap-y-6">
          {data === undefined && !isRedacted ? (
            <EmptyStateWrapper isSpinner />
          ) : isBody || data === undefined ? (
            <MergeCodeBlock
              codeBlockName={isRequest ? "Request body" : "Response body"}
              language="json"
              textToCopy={data}
              downloadInfo={downloadInfo}
              colorMode={isRequest ? "dark" : "light"}
            >
              {isRedacted && (!data || data == "null") ? `Body is redacted.` : displayText}
            </MergeCodeBlock>
          ) : (
            Object.entries(data).map(([key, value]) => {
              const isRedacted = value === REDACTED_VALUE_TEXT;
              return (
                <div key={key} className="break-all">
                  <Text variant="h6" className="text-gray-60">
                    {key}
                  </Text>
                  <Text className={isRedacted ? "italic" : ""}>{value}</Text>
                </div>
              );
            })
          )}
        </div>
      }
    />
  );
};
