import React, { useState } from "react";
import clsx from "clsx";
import { SelectedWebhookType } from "../../../enums";
import { Badge, Checkbox } from "@merge-api/merge-javascript-shared";
import { Accordion } from "react-bootstrap";

interface WebhookRowProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  onClick?: () => void;
  selected: boolean;
  deprecated?: boolean;
  comingSoon?: boolean;
  selectedWebhookType: Set<SelectedWebhookType>;
  children?: React.ReactNode;
}

const WebhookSelectCard: React.FC<WebhookRowProps> = ({
  onClick,
  comingSoon = false,
  title,
  description,
  icon,
  selected,
  deprecated,
  selectedWebhookType,
  children,
}) => {
  const [mouseHovering, setMouseHovering] = useState<boolean>(false);

  const cardClasses = clsx(
    "bg-white cursor-pointer rounded-lg border flex flex-col px-5 py-3 mb-4",
    {
      "border-blue-40": selected,
      "!cursor-default": selected && children,
      "border-gray-10": !selected,
      "hover:border-blue-40 hover:opacity-100": !selected && !comingSoon,
      "opacity-50": comingSoon,
    },
  );

  return (
    <>
      <div
        className={cardClasses}
        onClick={onClick}
        onMouseEnter={() => setMouseHovering(true)}
        onMouseLeave={() => setMouseHovering(false)}
      >
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <div className={`${mouseHovering || selected ? "hidden" : "mr-5"}`}>{icon}</div>
            <Checkbox
              checked={selected}
              className={`${mouseHovering || selected ? "mr-5" : "hidden"}`}
            />
            <div className="flex flex-col flex-nowrap">
              <div className="font semibold">{title}</div>
              <div className="text-sm">{description}</div>
            </div>
          </div>
          <div className="whitespace-nowrap">
            {deprecated && (
              <Badge size="sm" color="yellow">
                Deprecated
              </Badge>
            )}
            {comingSoon && <Badge size="sm">Coming soon</Badge>}
          </div>
        </div>
        <Accordion.Collapse in={selected} eventKey="0">
          <div>{children}</div>
        </Accordion.Collapse>
      </div>
    </>
  );
};

export default WebhookSelectCard;
