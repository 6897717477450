import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { TEST_LINKED_ACCOUNTS_PATH } from "../../../router/RouterUtils";
import LinkedAccountsPageWrapper from "./LinkedAccountsPageWrapper";
import TestLinkedAccountsPage from "./TestLinkedAccountsPage";
import { LinkedAccount } from "../../../models/Entities";

type Props = {
  isTestAccounts: boolean;
  linkedAccounts: LinkedAccount[] | undefined;
  hasNext: boolean;
  hasPrevious: boolean;
  onNext: () => void;
  onPrevious: () => void;
  onRemove: (index: number) => void;
  onRefetch: (cursor?: any) => void;
  hasInitialized: boolean;
  isLoading: boolean;
  onLinkedAccountsParamsPathChange: (paramsPath: string) => void;
};

const TestLinkedAccountsTab = (props: Props) => {
  return (
    <LinkedAccountsPageWrapper onRefetch={props.onRefetch}>
      <Switch>
        <Route
          exact
          path={TEST_LINKED_ACCOUNTS_PATH}
          children={<TestLinkedAccountsPage {...props} />}
        />

        <Redirect to={TEST_LINKED_ACCOUNTS_PATH} />
      </Switch>
    </LinkedAccountsPageWrapper>
  );
};

export default TestLinkedAccountsTab;
